import React, { useEffect } from "react";
import AlertError from "../Alerts/AlertError";
import Loading from "../Loading/Loading";
import AlertInfo from "../Alerts/AlertInfo";
import TableBox from "../ContentBox/TableBox";
import {iStatsContractors} from "../../api/intrefaces";
import { NavLink } from "react-router-dom";
import LINK from "../../utils/LINK";
import {formatNumInt, formatPrice} from "../../utils/utils";
import {useFilterContractors} from "../../hooks/useFilterContractors";

const WinContractorsList = () => {
  const { contractors, error, loading, setInitFetch } = useFilterContractors()

  useEffect(() => {
    setInitFetch();
  }, [setInitFetch]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <AlertError msg={error} />;
  }
  if (!contractors || contractors.length === 0) {
    return <AlertInfo msg="Žádné zakázky" />;
  }

  return (
    <>
      <TableBox>
        <thead>
          <tr>
            <th>Název dodavatele</th>
            <th>IČO</th>
            <th>Adresa dodavatele</th>
            <th className="text-end">Součet nabídkové ceny bez DPH</th>
            <th className="text-end">Vysoutěžené VZ s nabídkovou cenou bez DPH</th>
            <th className="text-end">Součet smluvní ceny bez DPH</th>
            <th className="text-end">Vysoutěžené VZ se smluvní cenou bez DPH</th>
            <th className="text-end">Součet uhrazené ceny bez DPH</th>
            <th className="text-end">Vysoutěžené VZ s uhrazenou cenou bez DPH</th>
            <th className="text-end">Celkový počet vysoutěžených VZ</th>
          </tr>
        </thead>
        <tbody>
          {contractors.map((contractor: iStatsContractors, index: number) => (
            <tr key={index}>
              <td>
                <NavLink to={`${LINK.contractorDetail}/${contractor.id_company}`}>
                  {contractor.name}
                </NavLink>
              </td>
              <td>{contractor.ic}</td>
              <td>{contractor.address}</td>
              <td className="text-end">{formatPrice(contractor.count_offer_price_wovat)}</td>
              <td className="text-end">{formatNumInt(contractor.total_contracts_with_offer_price_wovat)}</td>
              <td className="text-end">{formatPrice(contractor.count_contract_wovat_value)}</td>
              <td className="text-end">{formatNumInt(contractor.total_contracts_with_wovat_value)}</td>
              <td className="text-end">{formatPrice(contractor.count_wovat_value)}</td>
              <td className="text-end">{formatNumInt(contractor.total_wovat_value)}</td>
              <td className="text-end">{formatNumInt(contractor.count_winner)}</td>
            </tr>
          ))}
        </tbody>
      </TableBox>
    </>
  );
};

export default WinContractorsList;
