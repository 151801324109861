import React, { useEffect } from "react"
import Loading from "../Loading/Loading"
import AlertError from "../Alerts/AlertError"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ContentBox from "../ContentBox/ContentBox"
import { useFilterStats } from "../../hooks/useFilterStats"
import ServerApi from "../../api/ServerApi"
import { iStat, iStatsChart } from "../../api/intrefaces"
import { prepareStatsSort, prepareStatsSum } from "../../utils/prepareStats"
import BarStats from "../ChartsNivo/BarStats"
import ExportPDFButton from "../ExportPDFButton/ExportPDFButton"

const StatContractsStates = () => {
  const { stats, error, loading, setInitFetch } = useFilterStats(
    ServerApi.getStatsStatesByCriteria
  )

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])

  // if (loading) {
  //   return <Loading/>
  // }
  if (error) {
    return <AlertError msg={error} />
  }

  const dataCount: iStatsChart[] = stats.map((stat: iStat) => ({
    id: stat.code,
    label: stat.name + (stat.code.startsWith("M") ? " (minitendr)" : ""),
    value: parseInt(stat.count),
  }))

  const dataPrice: iStatsChart[] = stats.map((stat: iStat) => ({
    id: stat.code,
    label: stat.name + (stat.code.startsWith("M") ? " (minitendr)" : ""),
    value: parseInt(stat.sum_kc),
  }))

  const data = [
    { data: prepareStatsSort(dataCount, 10), sum: prepareStatsSum(dataCount) },
    { data: prepareStatsSort(dataPrice, 10), sum: prepareStatsSum(dataPrice) },
  ]

  const valPrice = data[1].data.map((i) => i.value)
  const maxValPrice = Math.max(...valPrice)
  const numRangeLegend = maxValPrice < 1000000 ? ""
      : maxValPrice >= 1000000 && maxValPrice < 1000000000 ? "mil. " : "mld. "

  return (
    <>
      <div className="sticky-top">{loading && <Loading />}</div>
      <Row>
        <Col>
          <ContentBox title="Analýza stavů zakázek - počty">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("countStateAnalysis")}
                graphName="Analýza stavů zakázek - počty"
                filter=""
                numberOfContracts={`Počet zakázek: ${data[0].sum}`}
              />
              <div id="countStateAnalysis" style={{ height: 450 }}>
                <BarStats
                  data={data[0].data}
                  colorByLabel
                />
              </div>
            </div>

            <div className={"text-end"}>Počet zakázek: {data[0].sum}</div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Analýza stavů zakázek - předpokládané ceny v Kč">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("expectedPriceAnalysis")}
                graphName="Analýza stavů zakázek - předpokládané ceny v Kč"
                filter=""
                numberOfContracts={`Předpokládaná hodnota zakázek: ${data[1].sum} Kč`}
              />
              <div id="expectedPriceAnalysis" style={{ height: 450 }}>
                <BarStats
                  data={data[1].data}
                  legend={`cena zakázek v ${numRangeLegend}Kč`}
                  priceChart={true}
                  colorByLabel
                />
              </div>
            </div>

            <div className={"text-end"}>
              Předpokládaná hodnota zakázek: {data[1].sum} Kč
            </div>
          </ContentBox>
        </Col>
      </Row>
    </>
  )
}

export default StatContractsStates
