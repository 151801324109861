import React from "react"
import FilterSelectReduction from "./FilterSelectReduction"

interface iFilterSelectEnumsStatic {
  title: string
  name: string,
  multiple?: boolean,
  options?: any
}

const FilterSelectEnumsStatic = ({ title, name, multiple, options }: iFilterSelectEnumsStatic) => {

  return (
    <FilterSelectReduction
        title={title}
        name={name}
        options={options}
        multiple={multiple}
    />
  )
}

export default FilterSelectEnumsStatic
