export const USERS_TEST_DATA = [
  {
    id: "1",
    user: "Jan Novák",
    email: "jan@aaa.cz",
    username: "honza",
    role: "Manažer",
    dataWarehouseVz: "MZE",
    organizationalUnit: "UKZUZ",
  },
  {
    id: "2",
    user: "Jan Novák",
    email: "jan@aaa.cz",
    username: "honza",
    role: "Manažer",
    dataWarehouseVz: "MZE",
    organizationalUnit: "UKZUZ",
  },
  {
    id: "3",
    user: "Jan Novák",
    email: "jan@aaa.cz",
    username: "honza",
    role: "Manažer",
    dataWarehouseVz: "MZE",
    organizationalUnit: "UKZUZ",
  },
]
