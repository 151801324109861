import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iContract } from "../../api/intrefaces";
import { RootState } from "../store";
import {PAGE_TYPES} from "../../context/enumPageTypes";

interface iContractData {
  [id: number]: iContract;
  contracts: { [name: string]: number[] };
}

const createAllPageTypesAsDefault = (val: string[]) =>
    Object.values(PAGE_TYPES).reduce((prev, cur) => ({ ...prev, [cur]: val }), {})

const slice = createSlice({
  name: "contracts",
  initialState: {
    error: "",
    loading: false,
    contracts: {} as iContractData,
    contractsList: createAllPageTypesAsDefault([]) as iContractData["contracts"],
    contractDetail: {} as iContractData
  },
  reducers: {
    setLoading(state) {
      state.loading = true;
      state.error = "";
    },
    setContract(state, action: PayloadAction<iContract>) {
      const contract = action.payload;
      state.contractDetail[contract.id_contract] = contract;
    },
    setData(state, action: PayloadAction<{ name: string, contracts: iContract[] }>) {
      const {name, contracts} = action.payload;
      state.contractsList[name] = [];
      contracts.forEach((contract) => {
        state.contractsList[name].push(contract.id_contract);
        state.contracts[contract.id_contract] = contract;
      });
      state.loading = false;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const selectContracts = (name: string) => (state: RootState) => ({
  contractsList: state.contracts.contractsList[name].map(
    (id) => state.contracts.contracts[id]
  ),
  contractDetail: state.contracts.contractDetail,
  contracts: state.contracts.contracts,
  error: state.contracts.error,
  loading: state.contracts.loading,
});

export const {
  setLoading,
  setData,
  setError,
  setContract,
} = slice.actions;
export default slice.reducer;
