import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import ContentBox from "../../components/ContentBox/ContentBox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LINK from "../../utils/LINK";
import NivoBar from "./NivoBar";
import NivoPie from "./NivoPie";
import NivoPieSimple from "./NivoPieSimple";
import NivoLines from "./NivoLines";
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats";


const StatExamplePage = () => {

  return (
    <PageWrap title="Ukázka grafů"
              breadcrumbs_items={[
                { label: "Analýzy", link: LINK.stats },
                { label: "Ukázka stats" },
              ]}>

      <FilterBoxStats selectCompanies/>

      <Row>
        <Col>
          <ContentBox title="Hodnota zakázek dle typu zakázky">

            <div style={{ height: 400 }}>
              <NivoPie/>
            </div>
            <div className={"text-end"}>
              Hodnota zakázek: 26 555 315 155 Kč
            </div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Hodnota zakázek dle druhu zakázky">

            <div style={{ height: 350 }}>
              <NivoPieSimple/>
            </div>
            <div className={"text-end"}>
              Hodnota zakázek: 26 555 315 155 Kč
            </div>
          </ContentBox>
        </Col>
      </Row>

      <Row>
        <Col>
          <ContentBox title="Hodnota zakázek dle druhu zadávacího řízení">

            <div style={{ height: 500 }}>
              <NivoBar/>
            </div>
            <div className={"text-end"}>
              Hodnota zakázek: 26 555 315 155 Kč
            </div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Hodnota zakázek dle způsobu zahájení zadávacího řízení">

            todo graf kolac

            <div style={{ height: 450 }}>
              <NivoLines/>
            </div>
            <div className={"text-end"}>
              Hodnota zakázek: 26 555 315 155 Kč
            </div>
          </ContentBox>
        </Col>
      </Row>

    </PageWrap>
  );
}

export default StatExamplePage;