import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import Form from "react-bootstrap/Form"
import { useDispatch, useSelector } from "react-redux"
import { selectPageLimit, setPage, setLimit } from "../../redux/filters/filtersSlice"
import {selectEvents} from "../../redux/events/eventsSlice";
import {selectStats} from "../../redux/stats/statsSlice";
import {selectLoading} from "../../redux/company/companySlice";
import {useFilterContext} from "../../context/FilterContext";

interface iStyle {
  style?: any
}

const Paginator = ({style}: iStyle) => {
  const dispatch = useDispatch()
  const { page, limit } = useSelector(selectPageLimit)
  const { contextName, totalCount, loading: loadingContracts } = useFilterContext()
  const { loading: loadingEvents } = useSelector(selectEvents)
  const { loading: loadingStats } = useSelector(selectStats)
  const loading = useSelector(selectLoading)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
      let pgNum = window.localStorage.getItem(contextName)
      if (Number(pgNum) !== pageNumber) {
        dispatch(setPage(Number(pgNum)))
      } else {
        dispatch(setPage(pageNumber))
      }
  }, [dispatch, pageNumber, contextName])

  const realCount = Number(totalCount)

  const handlePageClick = (e: any) => {
    const selectPage = e.selected
    dispatch(setPage(selectPage))
    setPageNumber(selectPage)
    window.localStorage.setItem(contextName, JSON.stringify(selectPage))
  }

  const changeItemsPerPage = (num: number) => {
    dispatch(setLimit(num))
  }

  const options = [
    { value: "10", label: "10 záznamů" },
    { value: "25", label: "25 záznamů" },
    { value: "50", label: "50 záznamů" },
    { value: "100", label: "100 záznamů" },
  ]

  const pageCount = Math.ceil(realCount / limit)

  if (loadingContracts || loadingEvents || loadingStats || loading) {
    return null
  }

  return (
    <div style={style}>
      <Form className="float-end text-end">
        <Form.Control
          as="select"
          value={limit}
          onChange={e => changeItemsPerPage(parseInt(e.target.value))}
        >
          {options.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Form.Control>
        <small>Celkem: {realCount}</small>
      </Form>

      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        onPageChange={handlePageClick}
        forcePage={page}
        activeClassName={"active"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        breakClassName={"page-item disabled"}
        breakLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        disabledClassName={"disabled"}
      />
    </div>
  )
}

export default Paginator
