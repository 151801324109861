import { jsPDF } from "jspdf"
import Button from "react-bootstrap/Button"
import html2canvas from "html2canvas"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { useCallback, useEffect, useState } from "react"
import logo from "./logo.png"
import font from "./OpenSans-Medium-normal.js"
import { useFilterContractsStats } from "../../hooks/useFilterContractsStats"
import { useFilterContractsStatsAll } from "../../hooks/useFilterContractsStatsAll"
import { prepareStats } from "../../utils/prepareStats"
import {useFilterContext} from "../../context/FilterContext";
import {selectDepartments} from "../../redux/filters/filtersSlice";
import { iDepartmentEnum } from "../../api/intrefaces"

type Metadata = Record<string, string | number>

const pdfGraphFragment = async ({
  exportedGraph,
  metadata,
}: {
  exportedGraph: HTMLElement | null
  metadata: Metadata
}) => {
  if (!exportedGraph) {
    throw new Error("Invalid element provided as first argument")
  }

  const graphCanvas = await html2canvas(exportedGraph, {
    scale: 4,
  })
  const graph = graphCanvas.toDataURL()

  const data = {
    title: metadata.title,
    contractor: `Zadavatel: ${metadata.contractor}`.replace("\t", ""),
    start_date: `Datum zahájení VZ: Od: ${metadata.start_date} Do: ${metadata.end_date}`,
    department: `Oddělení: ${metadata.department}`,
    order_count: `${metadata.count}${
      metadata.percent !== "-" ? "/" + metadata.percent : ""
    }`,
    generated: `Vygenerováno: ${new Date().toLocaleString("cs-CZ", {
      timeZone: "Europe/Prague",
    })}`,
    source: `${metadata.source}`,
    entity: `Provozovatel: QCM, s.r.o., www.qcm.cz`,
    height: metadata.height,
  }

  return [data, graph] as const
}

const getBlobGraph = async (
  exportedGraph: HTMLElement | null,
  metadata: Metadata
) => {
  if (!exportedGraph) {
    throw new Error("Invalid element provided as first argument")
  }

  const [data, graph] = await pdfGraphFragment({
    exportedGraph,
    metadata,
  })

  const { orientation } = metadata

  const pdfGraph = new jsPDF({
    orientation: orientation === "landscape" ? "l" : "p",
    format: "a4",
    unit: "pt",
  })
  const width = pdfGraph.internal.pageSize.getWidth()
  const height = pdfGraph.internal.pageSize.getHeight()

  // Calculate offset to not overlap when contractor string is on multiple lines
  const getOffset = () => {
    if (data.contractor.length >= 105 && data.contractor.length < 200) {
      return 15
    } else if (data.contractor.length >= 200 && data.contractor.length < 315) {
      return 30
    } else if (data.contractor.length >= 315 && data.contractor.length < 420) {
      return 45
    } else if (data.contractor.length >= 420 && data.contractor.length < 525) {
      return 60
    } else if (data.contractor.length >= 525) {
      return 80
    } else {
      return 0
    }
  }

  const offset = getOffset()

  await pdfGraph.addFileToVFS("OpenSans-Medium-normal.ttf", font)
  await pdfGraph.addFont(
    "OpenSans-Medium-normal.ttf",
    "OpenSans-Medium",
    "normal"
  )
  await pdfGraph.setFont("OpenSans-Medium")
  await pdfGraph.setFontSize(20)
  await pdfGraph.setTextColor(255, 0, 0)
  await pdfGraph.addImage(logo, "PNG", width - 156, 10, 140, 48)
  await pdfGraph.text(data.title as string, 5, 80)
  await pdfGraph.setFontSize(12)
  await pdfGraph.setTextColor(0, 0, 0)

  await pdfGraph.text(pdfGraph.splitTextToSize(data.contractor, width), 5, 100)
  await pdfGraph.text(data.start_date, 5, 120 + offset)
  await pdfGraph.text(data.department, 5, 140 + offset)
  await pdfGraph.text(data.order_count, 5, 160 + offset)
  await pdfGraph.addImage(
    graph,
    "PNG",
    40,
    180 + offset,
    width / 100 * 88,
    exportedGraph?.id === "qualifyingDocumentation" || exportedGraph?.id === "assigningDocumentation" ? height / 100 * 57 : height / 100 * 47
  )
  await pdfGraph.text(data.generated, 5, height - 30)
  await pdfGraph.text(data.source, 5, height - 10)
  await pdfGraph.text(data.entity, 350, height - 10)

  const pdfBlob = new Blob([pdfGraph.output("blob")], {
    type: "application/pdf",
  })
  return pdfBlob
}

const ExportPDFButton = ({
  exportedGraph,
  graphName,
  filter,
  orientation,
  numberOfContracts,
  ...props
}: {
  exportedGraph: HTMLElement | null
  graphName: string
  filter: string
  orientation?: string
  numberOfContracts?: string | number
}) => {
  const [activeDepartment, setActiveDepartment] = useState("")
  const {activeFilters} = useFilterContext()
  const { departments } = useSelector(selectDepartments)
  const location = window.location.origin
  const companiesString = activeFilters.companyIds
  const departmentIdsString = activeFilters.departmentIds
  const startDate = activeFilters.contractStartDateFrom
  const endDate = activeFilters.contractStartDateTo

  const getCompaniesIds = useCallback(() => {
    return companiesString ? companiesString.split(", ") : []
  }, [companiesString])

  const departmentIds = useCallback(() => {
    return departmentIdsString ? departmentIdsString.split(",") : []
  }, [departmentIdsString])

  const companies = useSelector((state: RootState) =>
    state.company.company.filter(object => {
      return getCompaniesIds().includes(object.id_company.toString())
    })
  )

  const filterDepartmentsByIds = (data: iDepartmentEnum[], ids: string[]): string => {
    const parsedIds = ids.map(id => parseInt(id, 10));
    const filteredDepartments = data.filter(department => parsedIds.includes(department.id_department));
    return filteredDepartments.map(department => department.name).join(", ");
  }

  useEffect(() => {
      setActiveDepartment(filterDepartmentsByIds(departments, departmentIds()))
  }, [getCompaniesIds, departmentIds, departments])

  const { stats } = useFilterContractsStats()
  const { data: allStatsData } = useFilterContractsStatsAll()
  const { sum: count, percent } = prepareStats(stats.count, allStatsData.count)
  const countKeys = Object.keys(count)
  const percentKeys = Object.keys(percent)
  const countValues = Object.values(count)
  const percentValues = Object.values(percent)
  const filterIndex = countKeys.indexOf(filter)
  const percentIndex = percentKeys.indexOf(filter)
  const filterValue = countValues[filterIndex]
  const percentValue = percentValues[percentIndex]

  const companiesNames: string[] = []
  companies.forEach(company => companiesNames.push(company.name))
  const companiesNamesString = companiesNames.join(", ")
  const metadata = {
    title: graphName,
    contractor: companies && companies?.length > 0 ? companiesNamesString : "-",
    start_date: startDate
      ? new Date(startDate).toLocaleDateString("cs-CZ", {
          timeZone: "Europe/Prague",
          dateStyle: "medium",
        })
      : "-",
    end_date: endDate
      ? new Date(endDate).toLocaleDateString("cs-CZ", {
          timeZone: "Europe/Prague",
          dateStyle: "medium",
        })
      : "-",
    department: activeDepartment ? activeDepartment : "-",
    count: numberOfContracts
      ? numberOfContracts
      : filterValue?.length > 0
      ? `Počet zakázek: ${filterValue}`
      : "",
    percent: numberOfContracts
      ? "-"
      : percentValue?.length
      ? percentValue + "%"
      : "-",
    orientation: orientation ? orientation : "portrait",
    source: location,
  }

  const download = async (filename: string) => {
    try {
      const blob = await getBlobGraph(exportedGraph, metadata)
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error generating PDF:", error)
    }
  }

  return (
    <Button onClick={() => download(`${graphName}.pdf`)} {...props}>
      {" "}
      Export PDF{" "}
    </Button>
  )
}

export default ExportPDFButton
