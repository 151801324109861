import Container from "react-bootstrap/Container"
import "./footer.scss"
import moment from "moment"
import qcm_logo from "./qcm.png"
import { Link } from "react-router-dom"
import LINK from "../../utils/LINK";
import {ENV} from "../../ENV";
import Loading from "../Loading/Loading";
import AlertError from "../Alerts/AlertError";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useTimestampImportDB} from "../../hooks/useTimestampImportDB";
import {useSelector} from "react-redux";
import {selectIsLoged} from "../../redux/user/selectors";

const Footer = () => {
  const isLoged = useSelector(selectIsLoged)
  const {timestamp, error, loading} = useTimestampImportDB()

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error} />
  }

  return (
    <footer className="footer">
      <Container fluid="md">
        <Row>
          <Col lg={6}>
            &copy; {moment().format("Y")} <a href="/">manazer-zakazek.cz</a> v{ENV.V} |{" "}
            <Link to={LINK.terms}>
              Všeobecné obchodní podmínky
            </Link>
            <p>{!ENV.NEN && isLoged && "Poslední import dat proběhl " + moment(timestamp.import_timestamp).format(`L, LT`)}</p>
          </Col>
          <Col lg={6}>
            <div className="float-end">
              Dodavatel portálového řešení:{" "}
              <a href="https://www.qcm.cz">
                <img alt="QCM logo" src={qcm_logo} width={32} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
