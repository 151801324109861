import {useEffect} from "react";
import ServerApi from "../api/ServerApi";
import {iHistory} from "../api/intrefaces";
import useFetch from "./useFetch";

export const useContractHistory = (id: number | string) => {

  const {data: history, error, loading, fetch} = useFetch<iHistory[]>(() =>
    ServerApi.getContractHistoryStatesById(id)
      .then(data => data.reverse())
  )

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {history, loading, error}
}

