import {useEffect} from "react";
import ServerApi from "../api/ServerApi";
import useFetch from "./useFetch";
import {iContender} from "../api/intrefaces";

export const useContractContenders = (id: number | string) => {
  const {data: contenders, error, loading, fetch} = useFetch<iContender[]>(() =>
      ServerApi.getContractContendersByContractId(id)
          .then(data => data.reverse())
  )

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {contenders, loading, error}
}

