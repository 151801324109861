import React from "react";
import { ResponsiveBar } from "@nivo/bar"
import { colorSchemes } from "@nivo/colors"
import AlertInfo from "../Alerts/AlertInfo";
import { iStatsChart } from "../../api/intrefaces";
import {/*setFilterChart,*/ setFilterCharts} from "../../redux/filters/filtersSlice";
import { useDispatch } from "react-redux";
import {useFilterContext} from "../../context/FilterContext"
import {calcHighestValue} from "../../utils/prepareStats";

interface iBarContracts {
  data: iStatsChart[],
  priceChart?: boolean,
  filterName: string
}

const MAX_BOTTOM_LABEL_LEN = 20;

const BarContracts = ({ data, priceChart,filterName }: iBarContracts) => {
  const dispatch = useDispatch()
  const {contextStatsName} = useFilterContext()

  if (!data || data.length === 0) {
    return <AlertInfo msg="Žádná data"/>
  }

  const legendAndFormat = calcHighestValue(data)

  return (

    <ResponsiveBar
      data={data as any}
      keys={["value"]}
      indexBy="label"

      groupMode={"grouped"}
      margin={{ top: 20, right: 20, bottom: 150, left: 70 }}
      padding={data.length < 4 ? 0.8 : 0.3} //between group bars
      // indexScale={{ type: 'band', round: true }}

      colors={({ id, data, index }) => colorSchemes.accent[index % colorSchemes.accent.length]}

      // borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      // borderWidth={1}

      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -30,
        format: label => label.length > MAX_BOTTOM_LABEL_LEN + 3 ? label.substr(0, MAX_BOTTOM_LABEL_LEN) + '...' : label
        // legend: "Druh ZŘ",
        // legendPosition: "end",
        // legendOffset: 40
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: priceChart ? legendAndFormat.legend : "počet zakázek",
        legendPosition: "middle",
        legendOffset: -60,
        format: val => priceChart ? (val/legendAndFormat.format).toLocaleString() : Number(val).toLocaleString()
      }}

      enableLabel={false}
      // labelFormat={value => priceChart ?`${Number(value).toLocaleString()} Kč` : Number(value).toLocaleString()}
      // labelSkipWidth={12}
      // labelSkipHeight={12}
      // labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}

      onClick={(node, event) => {
        console.log("nodee click", node)
        // dispatch(setFilterChart({ name: filterName, value: node.data.id.toString() }))
        dispatch(setFilterCharts({ name: contextStatsName, filters: {name: filterName, value: node.data.id.toString()} }));
      }}

      tooltip={({ id, value, color, index, indexValue, data, }) => (
        <span>
        <svg width={20} height={20}>
          <circle fill={color} r={5} cx={10} cy={10}/>
        </svg>
        <strong>{indexValue}</strong> - {Number(value).toLocaleString()}&nbsp;{priceChart ? " Kč" : ""}
      </span>
      )}

      // legends={[
      //   {
      //     dataFrom: "indexes",
      //     anchor: "top-right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 120,
      //     translateY: 0,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 20,
      //     itemDirection: "left-to-right",
      //     itemOpacity: 0.85,
      //     symbolSize: 20,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemOpacity: 1
      //         }
      //       }
      //     ]
      //   }
      // ]}
      animate={true}
    />
  )
}

export default BarContracts;