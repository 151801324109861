import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ServerApi from "../api/ServerApi";
import {selectLimitOffset, selectOrder, setTotalCounts} from "../redux/filters/filtersSlice";
import {iWinnerSupValue} from "../api/intrefaces";
import useFetch from "./useFetch";
import {useFilterContext} from "../context/FilterContext";
import store from "../redux/store";

export const useFilterWinnersSupValue = () => {
  const {activeFilters, contextName} = useFilterContext()
  const {limit, offset} = useSelector(selectLimitOffset)
  const order = useSelector(selectOrder)

  const f = {
    ...activeFilters,
    limit: limit,
    offset: offset,
    order: order
  }

  const {data: winners, error, loading, fetch} = useFetch<iWinnerSupValue[]>(() =>
    ServerApi.getStatsWinnersSupValueByCriteria(f)
      .then (res => {
        if (res.totalCount !== undefined) {
          store.dispatch(setTotalCounts({name: contextName || "", value: res.totalCount}))
        }
        return res.data
      })
  )

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)
  useEffect(() => {
    if (init) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, limit, offset, order, init])

  return {winners, loading, error, setInitFetch}
}

