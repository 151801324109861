import {useEffect, useState} from "react";
import ServerApi from "../api/ServerApi";
import useFetch from "./useFetch";
import {useFilterContext} from "../context/FilterContext";

export const useFilterContractsRatings = () => {
  const {activeFilters} = useFilterContext()

  const {data: ratings, error, loading, fetch} = useFetch<any>(() =>
    ServerApi.getContractsRatingsByCriteria(activeFilters)
  )

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)

  useEffect(() => {
    if (init) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, init])

  return {ratings, error, loading, setInitFetch}
}

