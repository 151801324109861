import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse"
import Button from "react-bootstrap/Button"
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

import "./collapsebox.scss"

interface iCollapseBox {
  children: React.ReactNode,
}

const CollapseBox = ({ children }: iCollapseBox) => {
  const [open, setOpen] = useState(true);

  return (

    <div className="collapseWrap">

      <Collapse in={open}>
        <div className="collapseContent" id="example-collapse-text">
          {children}
        </div>
      </Collapse>

      <div className="collapseBtnWrap">
        <Button
          className="collapseBtn"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >

          {open ?
            <FaCaretUp/>
            :
            <FaCaretDown/>
          }
        </Button>
      </div>

    </div>
  );
}

export default CollapseBox;