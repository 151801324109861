import React from "react"
import ContentBox from "../../ContentBox/ContentBox"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FilterDate from "../Date/FilterDate"
import FilterBtns from "../FilterBtns"
import FilterSelectCompanies from "../Select/FilterSelectCompanies"
import FilterSelectDepartments from "../Select/FilterSelectDepartments"
import FilterDateStart from "../Date/FilterDateStart"

const FilterBoxEvents = () => {
  return (
    <ContentBox>
      <Row>
        <Col sm={6}>
          <FilterSelectCompanies />

          <FilterDateStart />
        </Col>
        <Col sm={6}>
          <FilterSelectDepartments />
          <FilterDate
            title="Termín události"
            nameFrom="actionTermFrom"
            nameTo="actionTermTo"
          />
        </Col>
      </Row>

      <FilterBtns />
    </ContentBox>
  )
}

export default FilterBoxEvents
