import React from "react";
import { useParams } from "react-router-dom";
import PageWrap from "../../components/PageWrap/PageWrap";
import LINK from "../../utils/LINK";
import EventBox from "../../components/EventBox/EventBox";

const EventDetailPage = () => {

  const { id } = useParams<{ id: string }>()

  return (
    <PageWrap title="Detail události"
              breadcrumbs_items={[
                { label: "Události zakázek", link: LINK.eventsList },
                { label: "Detail události" }
              ]}>

      <EventBox id={parseInt(id)}/>
    </PageWrap>
  )
}

export default EventDetailPage;