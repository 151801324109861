import React from "react";
import { ResponsivePie } from "@nivo/pie";
import AlertInfo from "../Alerts/AlertInfo";
import { iStatsChart } from "../../api/intrefaces";

import { useDispatch } from "react-redux";
import {/*setFilterChart,*/ setFilterCharts} from "../../redux/filters/filtersSlice";
import { tooltip } from "./tooltip";
import {useFilterContext} from "../../context/FilterContext";

interface iPieContracts {
  data: iStatsChart[];
  priceChart?: boolean;
  filterName: string;
  id?: string;
}

const PieContracts = ({ id, data, priceChart, filterName }: iPieContracts) => {
  const dispatch = useDispatch();
  const {contextStatsName} = useFilterContext()

  if (!data || data.length === 0) {
    return <AlertInfo msg="Žádná data" />;
  }

  return (
    <ResponsivePie
      id={id}
      data={data}
      colors={{ scheme: "accent" }}
      valueFormat={(value) =>
        priceChart
          ? `${Number(value).toLocaleString()} Kč`
          : Number(value).toLocaleString()
      }
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5} //dira
      padAngle={0.7} // mezera mezi ctvrtkam
      cornerRadius={3} //zaobleni ctvrtek
      activeOuterRadiusOffset={10} // hover
      activeInnerRadiusOffset={10} // hover
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
      arcLinkLabel={"label"}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      onClick={(node, event) => {
        console.log("nodee click", node);
        // dispatch(setFilterChart({ name: filterName, value: node.data.id }));
        dispatch(setFilterCharts({ name: contextStatsName, filters: {name: filterName, value: node.data.id.toString()}}));
      }}
      tooltip={tooltip}
      legends={[
        {
          anchor: "bottom-left",
          direction: "column",
          justify: false,
          itemWidth: 100,
          itemHeight: 18,
          symbolShape: "circle",
          translateX: -50,
          translateY: 70,
          itemTextColor: "#999",
          // itemDirection: "left-to-right",
          // itemsSpacing: 2,
          // symbolSize: 18,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 0.8,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieContracts;
