import React from "react";
import { FaStar, FaRegStar } from "react-icons/fa"
import "./stars.scss"

interface iStars {
  num: number
}

const Stars = ({ num }: iStars) => {

  return (
    <>
      {[...Array(5)].map((_, index) =>

        index < num ?

          <FaStar key={index} className="star_active"/>
          :
          <FaRegStar key={index} className="star_none"/>
      )}
    </>
  );
}

export default Stars;