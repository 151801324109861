export const ENUM_ID = {
  type: "type",
  otype: "otype",
  procedure: "procedure",
  state: "state",
  company: "company",
  nutsCodeId: "nutsCodeId",
}

export const OPTIONS = {
  // itype: [ // zpusob zahajeni zadavaciho rizeni
  //   { value: "OZ", label: "oznámením do VVZ" },
  //   { value: "VE", label: "výzvou / uveřejněním" },
  //   { value: "VL", label: "bez výzvy v E-ZAK" },
  //   { value: "VZMR", label: "dle interních předpisů" }
  // ],

  stars: [
    {
      value: "0",
      label: "0 - nekvalifikovaná\xa0\xa0\xa0\xa0", // Easier way to make padding-right
    },
    { value: "1", label: "1 - nekompletní" },
    { value: "2", label: "2 - neodpovídající" },
    { value: "3", label: "3 - přijatelná" },
    { value: "4", label: "4 - odpovídající" },
    { value: "5", label: "5 - kompletní" },
  ],
}
