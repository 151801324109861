export const convertToNumAndRound = (data: any) => {
  let result: any = []
  for (let i = 0; i < data.length; i++) {
    result.push({
      ...data[i],
      "Součet předpokládané ceny": Number(Number(data[i].count_sup_value).toFixed(1)),
      "Součet nabídkové ceny": Number(Number(data[i].count_offer_price_wovat).toFixed(1)),
      "Součet smluvní ceny": Number(Number(data[i].count_contract_wovat_value).toFixed(1)),
      "Součet uhrazené ceny": Number(Number(data[i].count_wovat_value).toFixed(1)),
    });
  }
  return result
}
