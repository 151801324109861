import React from "react";
import Card from "react-bootstrap/Card"
import "./contexbox.scss"
import CollapseBox from "../CollapseBox/CollapseBox";

interface iContetnBox {
  title?: string,
  children: React.ReactNode,
  partHeight?: boolean,
  collapse?: boolean
}

const ContentBox = ({title, children, partHeight, collapse}: iContetnBox) => {

  return (
    <div className={`pb-4 ${partHeight ? " " : "h-100"}`}>
      <Card className=" h-100 border-0 shadow-sm">

        {title &&
        <div className="px-3 py-2">
          <div className="box_title_red">{title}</div>
        </div>
        }

        {collapse ?
          <CollapseBox>
            <Card.Body>
              {children}
            </Card.Body>
          </CollapseBox>
          :
          <Card.Body>
            {children}
          </Card.Body>
        }

      </Card>
    </div>
  );
}

export default ContentBox;