import React from "react";
import Container from "react-bootstrap/Container";
import "./pagewrap.scss"

interface iPageContainerContent {
  children: React.ReactNode,
}

const PageContainerContent = ({ children }: iPageContainerContent) => {

  return (
    <div className="page_container_wrap">
      <Container fluid="md" className="content">
        {children}
      </Container>
    </div>
  );
}

export default PageContainerContent;