import React from "react"
import FilterDate from "./FilterDate"

const FilterDateStart = () => {
  return (
  <FilterDate
      title={"Datum zahájení VZ"}
      nameFrom="contractStartDateFrom"
      nameTo="contractStartDateTo"
    />
  )
}

export default FilterDateStart
