import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App/App";
// import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "./history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment-timezone"
import "moment/locale/cs"
import RouterScrollTop from "./components/RouterScrollTop/RouterScrollTop";

moment.locale("cs", { invalidDate: "-" })
moment.tz.setDefault("UTC");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <RouterScrollTop/>
        <ToastContainer/>
        <App/>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
