import { useEffect } from "react";
import ServerApi from "../api/ServerApi";
import useFetch from "./useFetch";
import {divideStats} from "../utils/prepareStats";
import {useFilterContext} from "../context/FilterContext";

export const usePreContractsDashboard = () => {
  const {activeFilters} = useFilterContext()

  const {data, error, loading, fetch} = useFetch(() =>
    ServerApi.getPreContractsDashboard(activeFilters)
        .then(res => divideStats(res))
  )

  useEffect(() => {
          fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters])

  return { data, error, loading }
}

