import {useEffect, useState} from "react";
import ServerApi from "../api/ServerApi";
import {setContract} from "../redux/contracts/contractsSlice";
import {useDispatch} from "react-redux";
import {useFilterContext} from "../context/FilterContext";

export const useContractDetail = (id: number | string) => {
  const dispatch = useDispatch()
  const { contractDetail } = useFilterContext()
  // @ts-ignore
  const contract = contractDetail[id]

  const [loadingData, setLoading] = useState(false)
  const [errorData, setError] = useState("")

  useEffect(() => {
    if (!contract) {
      setLoading(true)
      ServerApi.getContractById(id)
          .then(data => {
            dispatch(setContract(data[0]))
          })
          .catch(err => {
            setError(err.toString())
          })
          .finally(() => {
            setLoading(false)
          })
    }
  }, [contract, dispatch, id])

  if (!contract) {
    return { contract: null, error: errorData, loading: loadingData }
  } else {
    return { contract, error: errorData, loading: loadingData }
  }
}

