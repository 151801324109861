import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {selectOrder, setOrder} from "../../redux/filters/filtersSlice";

interface iFilterOrder {
  orderOptions: { label: string, value: string }[]
  maximWidth?: number,
  style?: any
}

const FilterOrder = ({ orderOptions, maximWidth, style }: iFilterOrder) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setOrder(orderOptions[0].value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const order = useSelector(selectOrder)

  const setVal = (order: string) => {
    dispatch(setOrder(order))
  }

  return (
    <Form style={style}>
      <Row>
        <Form.Label className="text-end" column>
          Řazení
        </Form.Label>
        <Col style={ maximWidth ? {flexGrow: 10, maxWidth: maximWidth} : {maxWidth: 260} }>
          <Form.Control
            as="select"
            value={order}
            onChange={(e) => setVal(e.target.value)}
            size="sm"
          >
            {orderOptions.map(opt =>
              <option key={opt.value} value={opt.value}>{opt.label}</option>
            )}
          </Form.Control>
        </Col>
      </Row>
    </Form>
  );
}

export default FilterOrder;