import React from "react"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "../filter.scss"
import Help from "../../Help/Help"
import {useFilterContextInput} from "../../../context/FilterContext";

interface iFilterSelectFromTo {
  title: string
  nameFrom: string
  nameTo: string
  options: { label: string; value: string }[]
  popoverText?: string
}

const FilterSelectFromTo = ({ title, nameFrom, nameTo, options, popoverText
}: iFilterSelectFromTo) => {
  const { value, setVal } = useFilterContextInput(nameFrom)
  const { value: valueTo, setVal: setValueTo } = useFilterContextInput(nameTo)

  const fromFilter = value || ""
  const toFilter = valueTo || ""

  const setValFrom = (fromVal: string) => {
    setVal(fromVal)
  }

  const setValTo = (toVal: string) => {
    setValueTo(toVal)
  }

  return (
    <Form.Group
      className="mb-1"
      as={Row}
      controlId={`filterDate${nameFrom}${nameTo}`}
    >
      <Form.Label column sm="4" className="text-end position-relative">
        {title}
        {popoverText && <Help label={title} text={popoverText} />}
      </Form.Label>
      <Col sm="8">
        <Row>
          <Col>
            <Form.Control
              as="select"
              size="sm"
              name="name"
              value={fromFilter}
              onChange={e => setValFrom(e.target.value)}
            >
              <option value="">Od</option>
              {options.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Form.Control>
          </Col>
          -
          <Col>
            <Form.Control
              as="select"
              size="sm"
              name="name"
              value={toFilter}
              onChange={e => setValTo(e.target.value)}
            >
              <option value="">Do</option>
              {options.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Col>
    </Form.Group>
  )
}

export default FilterSelectFromTo
