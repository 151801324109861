const LINK = {
  home: "/",
  contractsList: "/prehled-zakazek",
  contractsListRunning: "/prehled-zakazek/probihajici",
  contractDetail: "/prehled-zakazek/detail",
  eventsList: "/terminy-zakazek",
  eventDetail: "/terminy-zakazek/detail",
  stats: "/analyzy",
  statsContractsCount: "/analyzy/zakazky-za-obdobi",
  statsContractsPrice: "/analyzy/hodnota-za-obdobi",
  statsContractsContenders: "/analyzy/prirazeni-vyrazeni-uchazecu",
  statsContractsDocs: "/analyzy/struktura-dokumentace",
  statsContractsPlaces: "/analyzy/mista-realizace",
  statsContractsStates: "/analyzy/stavy-zakazek",
  statsContractsStartedStates: "/analyzy/zadavaci-rizeni-organizaci",
  statsWinnersStates: "/analyzy/prehled-viteznych-uchazecu-nabidkova-cena",
  statsWinnersStatesSupValue: "/analyzy/prehled-viteznych-uchazecu-predpokladana-cena",
  statsContractors: "/analyzy/dodavatele-soucty-uhrazenych-cen",
  statExample: "/analyzy/example", //TODO
  audit: "/audit",
  qualitativeAnalysis: "/audit/kvalitativni-analyza",
  profil: "/profil-uzivatele",
  globalIndicators: "/globalni-ukazatele",
  userManagement: "/sprava-uzivatelu",
  contractors: "/audit/dodavatele",
  contractorDetail: "/dodavatele/detail",
  legislativa: "/legislativa",
  terms: "/vseobecne-podminky",
  help: "/napoveda",
  newsInfo: "/novinky",
  login: "/login",
  changePass: "/zmena-hesla",
  notFound404: "/404-not-found",
} as const

export default LINK
