import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { iDepartmentEnum } from "../../api/intrefaces"

export type iFilter = string

export type iFilters = { [name: string]: iFilter }

interface interfaceFilters {
  filters: { [name: string]: { [name: string]: string } }
}

const createAllPageTypesAsDefault = (val: any) =>
    Object.values(PAGE_TYPES).reduce((prev, cur) => ({ ...prev, [cur]: val }), {})

const slice = createSlice({
  name: "filters",
  initialState: {
    filters: createAllPageTypesAsDefault({}) as interfaceFilters["filters"],
    filtersCharts: createAllPageTypesAsDefault({}) as interfaceFilters["filters"],
    page: 0,
    limit: 10,
    order: undefined as undefined | string,
    totalCounts: createAllPageTypesAsDefault({}) as interfaceFilters["filters"],
    departments: [] as iDepartmentEnum[],
    errorDepartments: "",
    loadingDepartments: false,
    cpvCodes: [],
    unCheckCpvCodes: false
  },
  reducers: {
    setFilter(
        state,
        action: PayloadAction<{ name: string; filters: any }>
    ) {
      const { name, filters } = action.payload
      state.filters[name] = filters
    },
    setFilterCharts(
        state,
        action: PayloadAction<{ name: string; filters: {name: string, value: string} }>
    ) {
      const { name, filters } = action.payload
      if (filters.value === "") {
        delete state.filtersCharts[name][filters.name]
      } else {
        state.filtersCharts[name][filters.name] = filters.value
      }
    },

    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload
      state.page = 0
    },
    setOrder(state, action: PayloadAction<undefined | string>) {
      state.order = action.payload
    },
    setTotalCounts(state,
                   action: PayloadAction<{ name: string; value: any }>) {
      const { name, value } = action.payload
      state.totalCounts[name] = value
    },
    setDepartments(state,
                   action: PayloadAction<{ id_department: number; name: string }[]>) {
      state.departments = action.payload
    },
    setCpvCodes(state, action: PayloadAction<[]>) {
      state.cpvCodes = action.payload
    },
    setUncheckCpvCodes(state, action: PayloadAction<boolean>) {
      state.unCheckCpvCodes = action.payload
    },
    setLoadingDepartments(state) {
      state.loadingDepartments = true;
      state.errorDepartments = "";
    },
    setErrorDepartments(state, action: PayloadAction<string>) {
      state.errorDepartments = action.payload;
      state.loadingDepartments = false;
    }
  },
})

export const selectFilters = (name: string) => (state: RootState) => state.filters.filters[name]
export const selectFiltersCharts = (name: string) => (state: RootState) => state.filters.filtersCharts[name]
export const selectOrder = (state: RootState) => state.filters.order
export const selectPageLimit = (state: RootState) => ({
  page: state.filters.page,
  limit: state.filters.limit,
  // totalCount: state.filters.totalCount,
})
export const selectLimitOffset = (state: RootState) => ({
  offset: state.filters.page * state.filters.limit,
  limit: state.filters.limit,
})
export const selectTotalCounts = (name: string) => (state: RootState) => state.filters.totalCounts[name]
export const selectDepartments = (state: RootState) => ({
  departments: state.filters.departments,
  error: state.contracts.error,
  loading: state.contracts.loading,
})
export const selectCpvCodes = (state: RootState) => state.filters.cpvCodes
export const selectUncheckCpvCodes = (state: RootState) => state.filters.unCheckCpvCodes

export const selectStartEndLimit = (state: RootState) => ({
  start: state.filters.page * state.filters.limit,
  end: state.filters.page * state.filters.limit + state.filters.limit,
})

export const {
  setFilter,
  setPage,
  setLimit,
  setOrder,
  setFilterCharts,
  setTotalCounts,
  setDepartments,
  setLoadingDepartments,
  setErrorDepartments,
  setCpvCodes,
  setUncheckCpvCodes
} = slice.actions
export default slice.reducer
