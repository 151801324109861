import React, { ReactNode } from "react";
import AlertError from "../Alerts/AlertError";
import LoadingInline from "../Loading/LoadingInline";

interface iLoadingErrorInline {
  loading?: boolean,
  error?: string,
  children: ReactNode
}

const LoadingErrorInline = ({ loading, error, children }: iLoadingErrorInline) => {

  if (loading) {
    return <LoadingInline/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }
  return <>{children}</>;
}

export default LoadingErrorInline;