import { useDispatch, useSelector } from "react-redux";
import {
  selectLimitOffset,
  selectOrder,
} from "../redux/filters/filtersSlice";
import { useEffect, useState } from "react";
import { fetchContracts } from "../redux/contracts/contractsAction";
import {useFilterContext} from "../context/FilterContext";

const dateStart: string = "2018-01-01"

export const useFilterContractsGlobal = () => {
  const dispatch = useDispatch();
  const {activeFilters, contextName, error, loading, contractsList} = useFilterContext()

  const { limit, offset } = useSelector(selectLimitOffset);
  const order = useSelector(selectOrder);
  const contractStartDateFrom = activeFilters.hasOwnProperty("contractStartDateFrom")
    && activeFilters.contractStartDateFrom >= dateStart ? activeFilters.contractStartDateFrom
      : activeFilters.hasOwnProperty("contractStartDateFrom")
      && activeFilters.contractStartDateFrom < dateStart ? dateStart
        : dateStart;

  const [init, setInit] = useState(false);
  const setInitFetch = () => setInit(true);

  useEffect(() => {
    if (init) {
      let f = {
        ...activeFilters,
        limit: limit,
        offset: offset,
        order: order,
        contractStartDateFrom: contractStartDateFrom,
        supValueFrom: 1,
        offerCountFrom: 1
      };
      dispatch(fetchContracts(f, contextName));
    }
  }, [activeFilters, limit, offset, order, contractStartDateFrom, init, dispatch, contextName]);

  return { contractsList, error, loading, setInitFetch };
};
