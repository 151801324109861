import React from "react";
import Modal from "react-bootstrap/Modal"
import {useDispatch, useSelector} from "react-redux";
import {selectModalOpen, setModalOpen} from "../../redux/global/globalSlice";
import Table from "react-bootstrap/Table";
import Stars from "../Stars/Stars";


const ModalInfoQuality = () => {

  const dispatch = useDispatch()
  const open = useSelector(selectModalOpen)
  const handleClose = () => dispatch(setModalOpen(false))

  return (
    <Modal show={open} onHide={handleClose} size="lg">

      <Modal.Header closeButton>
        <Modal.Title>Co vyjadřuje QIndex a jak se vypočítává</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        QIndex reprezentuje množství metadat spojených s veřejnou zakázkou a přenesených do MVZ.
        Vycházíme z toho, že více metadat je lepší (lepší vyhledatelnost zakázky).
        <br/>
        <br/>

        QIndex je vypočítán při importu zakázek do MVZ, a následně uloženo k zakázce. Výsledné hodnocení
        se určuje podle bodového ohodnocení od 0 do 100, kdy 100 je nejlepší skóre.
        <br/>
        <br/>

        <h4>Algoritmus výpočtu QIndexu</h4>


        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>Položka</th>
            <th>Body</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Zákonem požadované položky</td>
            <td>35</td>
          </tr>
          <tr>
            <td>NIPEZ/CPV</td>
            <td>20</td>
          </tr>
          <tr>
            <td>Místo plnění</td>
            <td>15</td>
          </tr>
          <tr>
            <td>Předpokládaná hodnota</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Odkaz na dokumentaci</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Vyplněný popis zakázky</td>
            <td>15</td>
          </tr>
          <tr>
            <td>Lhůta pro podání nabídek</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Celkem</td>
            <td>100</td>
          </tr>
          </tbody>
        </Table>

        <h4>Jednotlivé škály hodnocení</h4>

        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>QIndex</th>
            <th>Bodový rozsah</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><Stars num={5}/></td>
            <td>81-100</td>
          </tr>
          <tr>
            <td><Stars num={4}/></td>
            <td>61-80</td>
          </tr>
          <tr>
            <td><Stars num={3}/></td>
            <td>41-60</td>
          </tr>
          <tr>
            <td><Stars num={2}/></td>
            <td>21-40</td>
          </tr>
          <tr>
            <td><Stars num={1}/></td>
            <td>0-20</td>
          </tr>

          </tbody>
        </Table>


      </Modal.Body>
    </Modal>
  );
}

export default ModalInfoQuality;