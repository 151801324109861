import React, {useEffect, useState} from "react"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Loading from "../../Loading/Loading"
import { Typeahead } from "react-bootstrap-typeahead"
import { FaAngleDown, FaAngleUp } from "react-icons/fa"
import "../filter.scss"
import {useFilterContextInput} from "../../../context/FilterContext";

interface iFilterSelect {
  title?: string
  name: string
  options: { label: string; value: string; group?: string | string[] }[]
  disabled?: boolean
  placeholder?: string
  multiple?: boolean
}

const FilterSelectReduction = ({
  title,
  name,
  options,
  disabled,
  placeholder = "Vyberte",
  multiple = false,
}: iFilterSelect) => {
  const { value, setVal } = useFilterContextInput(name)

  const filterVal = value ?? ""

  if (disabled && filterVal) {
    setVal("")
  }

  useEffect(()=>{
    return ()=>{
      setVal("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const [text, setText] = useState("")
  const sOption = options?.find(o => Array.isArray(o?.group) ?
          o?.group.toString().replaceAll(",", ", ") === filterVal
          : o?.value === filterVal)

  let sOptions: any[] = []
  if (multiple && !disabled) {
    sOptions = options?.filter(o =>
      filterVal
        ?.split(",")
        .map((value: string) => value.trim())
        .includes(String(o?.value))
    )
  }

  return (
    <Form.Group
      className={multiple ? "multiple-select mb-1" : "mb-1"}
      as={Row}
      controlId={`filterSelect${name}`}
    >
      {title && (
        <Form.Label column sm="4" className="text-end">
          {title}
        </Form.Label>
      )}
      <Col
        className={
          multiple && title ? "col-12 col-sm-8" : multiple ? "col-12" : ""
        }
      >
        {options ? (
          // <Form.Control
          //   as="select"
          //   size="sm"
          //   name="name"
          //   value={filterVal}
          //   onChange={e => setVal(e.target.value)}
          //   disabled={disabled}
          // >
          //   <option value="">{placeholder}</option>
          //   {options.map(opt =>
          //     <option key={opt.value} value={opt.value}>{opt.label}</option>
          //   )}
          // </Form.Control>

          <Typeahead
            isInvalid={!sOption && !!text && !sOptions?.length}
            // size="sm"
            id="location"
            multiple={multiple}
            labelKey="label"
            filterBy={["label", "value".toString()]}
            disabled={disabled}
            emptyLabel={"Žádné položky"}
            paginationText={"Více..."}
            // defaultSelected={[...]}
            clearButton={!multiple}
            onChange={(selected: any) => {
              console.log("ON CHANGE", selected)
              if (multiple && selected.length) {
                let value = ""
                selected.forEach(
                  (item: any) =>
                    (value += value ? `, ${item.group}` : `${item.group}`)
                )
                setVal(value)
              } else if (selected[0]) {
                setVal(selected[0].group)
              } else {
                setVal("")
              }
            }}
            onInputChange={(text: string, event: Event) => {
              setText(text)
            }}
            options={disabled ? [] : options}
            selected={multiple ? sOptions : sOption ? [sOption] : []}
            placeholder={placeholder}
            // isLoading={}
          >
            {({ isMenuShown, toggleMenu }: any) => (
              <div
                onClick={e => toggleMenu()}
                className="select_cart_wrap text-primary"
              >
                {isMenuShown ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            )}
          </Typeahead>
        ) : (
          <Loading />
        )}
      </Col>
    </Form.Group>
  )
}

export default FilterSelectReduction
