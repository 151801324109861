import React, {useRef, useEffect, useState} from "react";
import Loading from "../Loading/Loading";
import AlertError from "../Alerts/AlertError";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContentBox from "../ContentBox/ContentBox";
import FilterStat, {STAT_FILTER_TYPE} from "./FilterStat";
import ExportPDFButton from "../ExportPDFButton/ExportPDFButton";
import PieStats from "../ChartsNivo/PieStats";
import {useFilterStats} from "../../hooks/useFilterStats";
import ServerApi from "../../api/ServerApi";
import {transformData, sumValues, sumContractPercentages} from "../../utils/prepareStats";
import BarStats from "../ChartsNivo/BarStats";
import {ButtonGroup, ToggleButton} from "react-bootstrap";

const pie: string = "1"
const bar: string = "2"

const StatStartedContractsCount = () => {
  const { stats: data, error, loading, setInitFetch } = useFilterStats(
      ServerApi.getStatsStartedStatesByCriteria
  )
  const graphRef = useRef(null);
  const [graphIsReady, setGraphIsReady] = useState(false);

  const [radioValue, setRadioValue] = useState('1');
  const radios = [
    {name: 'Koláčový', value: pie},
    {name: 'Sloupcový', value: bar}
  ];

  useEffect(() => {
    setInitFetch();
  }, [setInitFetch]);

  useEffect(() => {
    const checkElement = setInterval(() => {
      if (graphRef.current) {
        setGraphIsReady(true);
        clearInterval(checkElement);
      }
    }, 100); // Zkouší každých 100ms, dokud element není nalezen

    return () => clearInterval(checkElement); // Vyčistí interval při odmountování
  }, []);

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error} />;
  }

  const prepareStats = transformData(data)
  const totalContractCount = sumValues(data)
  const totalContractPercentage = sumContractPercentages(data);

  return (
    <>
      <div className="sticky-top">
        <FilterStat />
        {loading && <Loading />}
      </div>
      <Row>
        <Col>
          <ContentBox title="Zadávací řízení organizací">
            <div style={{height: 450}}>
              {graphIsReady && (
                  <ExportPDFButton
                      exportedGraph={graphRef.current}
                      graphName="Zadávací řízení organizací"
                      filter=""
                      orientation="landscape"
                  />
              )}
              <ButtonGroup style={{float: "right", alignItems: "baseline"}}>
                Graf&nbsp;&nbsp;
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="primary"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                    >
                      {radio.name}
                    </ToggleButton>
                ))}
              </ButtonGroup>
              <div style={{height: 400}} ref={graphRef}>
                {radioValue === `1` ?
                    <PieStats
                        data={prepareStats}
                        marginLeft={450}
                        translateToLeft={-430}
                        translateToTop={0}
                        LegendAnchor={"top-left"}
                        percent={true}
                        filterName={STAT_FILTER_TYPE.organization}
                        onClickActive
                    />
                    :
                    <BarStats
                        data={prepareStats}
                        legend={"počet zakázek v %"}
                        // filterName={STAT_FILTER_TYPE.state}
                    />
                }
              </div>
            </div>

            <div className={"text-end"}>
              Počet zakázek {totalContractCount} /{totalContractPercentage}%
            </div>
          </ContentBox>
        </Col>
      </Row>
    </>
  );
};

export default StatStartedContractsCount;
