import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ServerApi from "../api/ServerApi";
import {selectLimitOffset, selectOrder, setTotalCounts} from "../redux/filters/filtersSlice";
import {iContractors} from "../api/intrefaces";
import useFetch from "./useFetch";
import {useFilterContext} from "../context/FilterContext";
import store from "../redux/store";

export const useFilterContractorsAll = () => {
  const {activeFilters, contextName} = useFilterContext()
  const {limit, offset} = useSelector(selectLimitOffset)
  const order = useSelector(selectOrder)

  const f = {
    ...activeFilters,
    limit: limit,
    offset: offset,
    order: order
  }

  const {data: contractors, error, loading, fetch} = useFetch<{data: iContractors[], totalCount: number }>(() =>
    ServerApi.getContractContractorsByCriteria(f)
      .then (res => {
        if (res.totalCount !== undefined) {
          store.dispatch(setTotalCounts({name: contextName || "", value: res.totalCount}))
        }
        return res
      })
  )

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)

  useEffect(() => {
    if (init) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, limit, offset, order, init])

  return {contractors, loading, error, setInitFetch}
}

