import React from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card"
import "./contexbox.scss"

interface iContetnBoxTable {
  children: React.ReactNode,
}

const TableBoxResponsive = ({ children }: iContetnBoxTable) => {

  return (
    <div className={`pb-1`}>
      <Card className=" border-0 shadow-sm">
        <Table striped bordered hover size="sm" className="mb-0 qa-table-responsive" responsive>
          {children}
        </Table>
      </Card>
    </div>
  );
}

export default TableBoxResponsive;