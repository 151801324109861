export const ENV = {
  V: process.env.REACT_APP_VERSION,
  API_URL: process.env.REACT_APP_SERVER_URL,
  IEN_NAME: process.env.REACT_APP_IEN_NAME,
  IEN_URL: process.env.REACT_APP_IEN_URL,
  REALM: process.env.REACT_APP_KEYCLOAK_REALM,
  CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
  KEYCLOACK_API_URL: process.env.REACT_APP_KEYCLOAK_URL,
  APP_URL: process.env.REACT_APP_URL,
  COLOR: process.env.REACT_APP_COLOR_PRIMARY || "#c73b3b",
  NEN: process.env.REACT_APP_NEN === "true"
}
