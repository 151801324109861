import {ENV} from "../ENV";

interface iCompanyOptions {
  value: string,
  label: string
}

export const getCompanyLabel = (groups: string[], companyOptions: iCompanyOptions[]) => {
  const idOrganizations = groups?.map((group) => group.replace(/\/ORGANIZATIONS\//g, ""))
  if (idOrganizations[0] === "/ORGANIZATIONS" || idOrganizations.length === 0) {
    return ENV.IEN_NAME
  }
  if (idOrganizations) {
    const companyOptionsArr = companyOptions.filter((company) => idOrganizations.includes(company.value))
    // return companyOptionsArr?.map((companyOption) => companyOption.label).join(", ")
    return companyOptionsArr
  }
}