import React from "react"
import Button from "react-bootstrap/Button"
import {useFilterContext} from "../../context/FilterContext";
import {setCpvCodes, setLimit, setUncheckCpvCodes} from "../../redux/filters/filtersSlice";
import {useDispatch} from "react-redux";

interface iFilterBtns {
  titleFilter?: string
}

const FilterBtns = ({ titleFilter }: iFilterBtns) => {
  const dispatch = useDispatch()
  const { isEmpty, applyFilter, clearFilter } = useFilterContext()

  const onFilter = () => {
    applyFilter()
    dispatch(setLimit(10))
  }

  const onReset = () => {
    clearFilter()
    dispatch(setUncheckCpvCodes(true))
    dispatch(setCpvCodes([]))
    dispatch(setLimit(10))
  }

  if (titleFilter === "justReset") {
    return (
      <div className="text-end mt-2">
        <Button variant="secondary" onClick={() => onReset()} disabled={isEmpty}>
          Zrušit filtr
        </Button>
      </div>
    )
  } else {
    return (
      <div className="text-end mt-2">
        <Button className="mx-2" onClick={() => onFilter()} disabled={isEmpty}>
          Filtrovat
        </Button>
        <Button
          variant="secondary"
          disabled={isEmpty}
          onClick={() => onReset()}
        >
          Zrušit filtr
        </Button>
      </div>
    )
  }
}

export default FilterBtns
