import axios from 'axios'
import { ENV } from '../ENV'

export interface iTokens {
    access_token: string
    refresh_token: string
    expires_in: number
    refresh_expires_in: number
    scope: string
}

const HEADER_FORM_URLENCODED = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
}

class KeycloakApi {
    api = axios.create({
        baseURL: ENV.KEYCLOACK_API_URL,
    })

    getToken = (username: string, password: string): Promise<iTokens> =>
        this.api
            .post(
                `realms/${ENV.REALM}/protocol/openid-connect/token`,
                new URLSearchParams({
                    client_id: ENV.CLIENT_ID,
                    username: username,
                    password: password,
                    grant_type: 'password',
                    scope: 'offline_access',
                }),
                HEADER_FORM_URLENCODED,
            )
            .then((res) => res.data)

    refreshToken = (refreshToken: string): Promise<iTokens> =>
        this.api
            .post(
                `realms/${ENV.REALM}/protocol/openid-connect/token`,
                new URLSearchParams({
                    client_id: ENV.CLIENT_ID,
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                }),
                HEADER_FORM_URLENCODED,
            )
            .then((res) => res.data)
}

const KeycloakApiInstance = new KeycloakApi()
export default KeycloakApiInstance
