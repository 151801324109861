import React from "react"
import moment from "moment"
import { DATE_FORMAT_DAY, durationString } from "../../utils/utils"
import AlertError from "../Alerts/AlertError"
import { iHistory } from "../../api/intrefaces"
import Loading from "../Loading/Loading"
import AlertInfo from "../Alerts/AlertInfo"
import { useContractHistory } from "../../hooks/useContractHistory"
import TableBox from "../ContentBox/TableBox"
import { useLoadMore } from "../../hooks/useLoadMore"
import LoadMore from "../LoadMore/LoadMore"

interface iHistoryList {
  id: number | string
}

const HistoryList = ({ id }: iHistoryList) => {
  const { history, loading, error } = useContractHistory(id)
  const { limit, loadMore, hasMore } = useLoadMore(history.length)

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={error} />
  }
  if (!history || history.length === 0) {
    return <AlertInfo msg="Žádná historie" />
  }

  const historyList = history.slice(0, limit)

  return (
    <>
      <TableBox>
        <thead>
          <tr>
            <th>Vznik stavu</th>
            <th>Název stavu</th>
            <th>Trvání přechodu mezi stavy</th>
            <th>Stav změnil</th>
          </tr>
        </thead>
        <tbody>
          {historyList.map((h: iHistory, index: number) => (
            <tr key={index}>
              <td>{moment(h.start_event).format(DATE_FORMAT_DAY)}</td>
              <td>{h.name_state}</td>
              <td>
                {h.duration_transition && durationString(h.duration_transition)}
              </td>
              <td>
                {h.firstname} {h.surname}
              </td>
            </tr>
          ))}
        </tbody>
      </TableBox>
      <LoadMore
        limit={limit}
        totalCount={history.length}
        hasMore={hasMore}
        loadMore={loadMore}
      />
    </>
  )
}

export default HistoryList
