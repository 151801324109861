import React from "react";
import "./pagewrap.scss"
import PageWrapHeadline from "./PageWrapHeadline";
import PageWrapContent from "./PageWrapContent";

interface iPageWrap {
  title?: string,
  breadcrumbs_items: { link?: string, label: string }[]
  children: React.ReactNode,
  noContainer?: boolean
}

const PageWrap = ({ title, breadcrumbs_items, children, noContainer }: iPageWrap) => {

  return (
    <>
      <PageWrapHeadline title={title} breadcrumbs_items={breadcrumbs_items}/>
      <PageWrapContent noContainer={noContainer}>
        {children}
      </PageWrapContent>
    </>
  );
}

export default PageWrap;