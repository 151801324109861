import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ServerApi from "../api/ServerApi";
import {selectLimitOffset, selectOrder, setTotalCounts} from "../redux/filters/filtersSlice";
import {iStatsContractors} from "../api/intrefaces";
import useFetch from "./useFetch";
import {useFilterContext} from "../context/FilterContext";
import store from "../redux/store";

// a) Hook načítání do výpisu dodavatelů
export const useFilterContractors = () => {
  const {activeFilters, contextName} = useFilterContext()
  const {limit, offset} = useSelector(selectLimitOffset)
  const order = useSelector(selectOrder)

  const f = {
    ...activeFilters,
    limit: limit,
    offset: offset,
    order: order
  }

  const {data: contractors, error, loading, fetch} = useFetch<iStatsContractors[]>(() =>
    ServerApi.getStatsContractorsByCriteria(f)
      .then (res => {
        if (res.totalCount !== undefined) {
          store.dispatch(setTotalCounts({name: contextName || "", value: res.totalCount}))
        }
        return res.data
      })
  )

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)
  useEffect(() => {
    if (init) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, limit, offset, order, init])
  return {contractors, loading, error, setInitFetch}
}

// b) Hook načítání do grafu TOP 10 dodavatelů
export const useGraphFilterContractors = () => {
  const {activeFilters, contextName} = useFilterContext()

  const f = {
    ...activeFilters,
    limit: 10,
    offset: 0,
    order: "count_wovat_value DESC"
  }

  const {data: contractors, error, loading, fetch} = useFetch<iStatsContractors[]>(() =>
      ServerApi.getStatsContractorsByCriteria(f)
          .then (res => {
            if (res.totalCount !== undefined) {
              store.dispatch(setTotalCounts({name: contextName || "", value: res.totalCount}))
            }
            return res.data
          })
  )

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)
  useEffect(() => {
    if (init) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, init])
  return {contractors, loading, error, setInitFetch}
}