import { FaRegQuestionCircle } from "react-icons/fa"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { measureStringWidth } from "../../utils/utils"
import useBreakpoint from "../../hooks/useBreakpoint"

interface iHelp {
  text?: string
  label?: string
}

const Help = ({ text, label }: iHelp) => {
  const labelWidth = measureStringWidth(label)
  const size = useBreakpoint()

  const style: any = {
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
    position: "absolute",
    cursor: "pointer",
    width: "fit-content",
    height: "fit-content",
  }

  if (size === "xs") {
    style.right = labelWidth
    style.transform = "translate(-16px, -4px)"
  } else {
    style.right = 0
    style.transform = "translate(4px, -4px)"
  }

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      key={"help"}
      placement={"auto"}
      delay={{ show: 200, hide: 400 }}
      overlay={
        <Popover body id="popover-help">
          {text}
        </Popover>
      }
    >
      <button style={style}>
        <FaRegQuestionCircle size={12} color="black" />
      </button>
    </OverlayTrigger>
  )
}

export default Help
