import {iEvent} from "../api/intrefaces";
import {useEffect} from "react";
import ServerApi from "../api/ServerApi";
import useFetch from "./useFetch";


export const useEvent = (id: number) => {

  const {data: event, error, loading, fetch} = useFetch<iEvent>(() =>
    ServerApi.getContractActionById(id)
      .then(data => data[0])
  )

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {event, loading, error}
}

