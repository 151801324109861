import React from "react"

import { useOptionsCompany } from "../../../hooks/useOptionsCompany"
import LoadingErrorWrap from "../../LoadingErrorWrap/LoadingErrorWrap"
import FilterSelect from "./FilterSelect"

const FilterSelectCompanyHome = () => {
  const { companyOptions, loading, error } = useOptionsCompany()
  
  return (
    <LoadingErrorWrap loading={loading} error={error}>
      <FilterSelect
        name="companyIds"
        placeholder="Vše"
        options={companyOptions}
        multiple
      />
    </LoadingErrorWrap>
  )
}

export default FilterSelectCompanyHome