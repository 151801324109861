import {useEffect} from "react";
import ServerApi from "../api/ServerApi";
import useFetch from "./useFetch";
import {useSelector} from "react-redux";
import {selectIsLoged} from "../redux/user/selectors";
import {ENV} from "../ENV";

export const useTimestampImportDB = () => {
  const isLoged = useSelector(selectIsLoged)
  const {data: timestamp, error, loading, fetch} = useFetch(() =>
      ServerApi.getTimestampImportDB()
          .then(data => data[0])
  )

  useEffect(() => {
    !ENV.NEN && isLoged && fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {timestamp, loading, error}
}

