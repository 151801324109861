import {useEffect} from "react";
import ServerApi from "../api/ServerApi";
import useFetch from "./useFetch";
import {iCompanyCpv} from "../api/intrefaces";


export const useCompanyCpv = (id: number | string) => {

  const {data: cpvCodes, error, loading, fetch} = useFetch<iCompanyCpv[]>(() =>
    ServerApi.getCpvCodesByCompanyId(id)
  )

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {cpvCodes, loading, error}
}

