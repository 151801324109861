import React from "react"
// import { useState } from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ContentBox from "../../components/ContentBox/ContentBox"
// import { Link } from "react-router-dom"
// import LINK from "../../utils/LINK"
import { useSelector } from "react-redux"
import { selectUserData } from "../../redux/user/selectors"
// import Form from "react-bootstrap/Form"
// import Button from "react-bootstrap/Button"
// import { toast } from "react-toastify"
import {useOptionsCompany} from "../../hooks/useOptionsCompany";
import LoadingErrorInline from "../../components/LoadingErrorWrap/LoadingErrorInline"
import AlertError from "../../components/Alerts/AlertError"
import { getCompanyLabel } from "../../utils/prepareUserProfil"
import { ENV } from '../../ENV'
import Button from "react-bootstrap/Button";

// interface iNastaveni {
//   [block: string]: { [item: string]: boolean }
// }

const UserProfilPage = () => {
  const { companyOptions, loading, error } = useOptionsCompany()
  const user = useSelector(selectUserData)
  if (!user) return <AlertError msg={"Error"}></AlertError>
  const { groups, email, name, preferred_username } = user
  const companyLabel = getCompanyLabel(groups || [], companyOptions)
  // todo link na zmenit heslo
  // todo akce ulozeni
  // todo nacist nastaveni

  // const [data, setData] = useState<iNastaveni>({
  //   notif: {
  //     termsStart: false,
  //     termsEnd: true,
  //     termsRand: true,
  //     event4: false,
  //     event5: true,
  //   },
  //   dashboard: {
  //     navigator: true,
  //     quality: false,
  //     stats: true,
  //     calendar: false,
  //   },
  //   stats: {
  //     terms: true,
  //     price: false,
  //     value: true,
  //     users: false,
  //     winers: false,
  //     stat: true,
  //     priceWin: true,
  //     priceReal: true,
  //   },
  // })

  // const cahngeData = (block: string, item: string, value: boolean) => {
  //   setData(data => ({
  //     ...data,
  //     [block]: {
  //       ...data[block],
  //       [item]: value,
  //     },
  //   }))
  // }

  // const saveBlock = (block: string) => {
  //   console.log("TODO save", block, data[block])
  //   toast.success("Sekce uložena")
  // }

  // const Line = ({
  //   title,
  //   children,
  // }: {
  //   title: string
  //   children: React.ReactNode
  // }) => (
  //   <Row>
  //     <Col sm={4} className="text-end">
  //       <strong>{title}:</strong>
  //     </Col>
  //     <Col>{children}</Col>
  //   </Row>
  // )

  // const SwitchLine = ({ label, name }: { label: string; name: string }) => {
  //   const [block, item] = name.split(".")
  //   const val = data[block][item]
  //   return (
  //     <Row>
  //       <Col sm={10}>{label}</Col>
  //       <Col>
  //         <Form.Check
  //           type="switch"
  //           onChange={e => cahngeData(block, item, e.target.checked)}
  //           id={"ch" + name}
  //           checked={val}
  //         />
  //       </Col>
  //     </Row>
  //   )
  // }

  return (
    <PageWrap
      title="Profil"
      breadcrumbs_items={[{ label: "Profil uživatele" }]}
    >
      <ContentBox title="Uživatel">
        <Row>
          <Col>
            <p className="mb-0"><strong>Uživatel: </strong>{name}</p>
            <p><strong>E-mail: </strong>{email}</p>
          </Col>
          <Col>
            <p className="mb-0"><strong>Přihlašovací jméno: </strong>{preferred_username}</p>
            <p><strong>Organizace: </strong>
              <LoadingErrorInline
                  loading={loading}
                  error={error}
                >
                {Array.isArray(companyLabel) ?
                  companyLabel.map((item: { value: string, label: string }, index: number) => (
                    <span key={item.value}>
                      {item.label}{index < companyLabel.length - 1 && (
                        <>
                          ,<br />
                        </>
                    )}
                    </span>
                  ))
                : companyLabel}
              </LoadingErrorInline>
            </p>
            <Button
                className="btn-reset-pass"
                variant="outline-danger"
                size="sm"
                type="submit"
                // target="_blank"
                title="Změnit heslo"
                href={
                  `${ENV.KEYCLOACK_API_URL}/realms/${ENV.REALM}/protocol/openid-connect/auth?client_id=${ENV.CLIENT_ID}&redirect_uri=${ENV.APP_URL}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`
                }
            >
              Změnit heslo
            </Button>
          </Col>
          {/*<Col>*/}
          {/*  <Line title={"Oddělení"}>{}</Line>*/}
          {/*  <Line title={"Přihlašovací heslo"}>*/}
          {/*    <Link to={LINK.changePass}>Změnit</Link>*/}
          {/*  </Line>*/}
          {/*</Col>*/}
        </Row>
      </ContentBox>

      {/* <Row>
        <Col>
          <ContentBox title="Požadované notifikace">
            <SwitchLine
              name="notif.termsStart"
              label="Oznamovat termíny vypsání zakázek:"
            />
            <SwitchLine
              name="notif.termsEnt"
              label="Oznamovat termíny ukončení podávání nabídek u zakázek:"
            />
            <SwitchLine
              name="notif.termsRand"
              label="Oznamovat libovolné propadlé termíny u zakázek:"
            />
            <SwitchLine
              name="notif.event4"
              label="Zasílání notifikací o události č.4:"
            />
            <SwitchLine
              name="notif.event5"
              label="Zasílání notifikací o události č.5:"
            />
            <div className="text-end mt-3">
              <Button onClick={() => saveBlock("notif")}>
                Uložit nastavení
              </Button>
            </div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Požadované komponenty rozcestníku">
            <SwitchLine
              name="dashboard.navigator"
              label="Rozbalený navigator:"
            />
            <SwitchLine
              name="dashboard.quality"
              label="Přehled kvality zakázek:"
            />
            <SwitchLine name="dashboard.stats" label="Souhrnné analýzy:" />
            <SwitchLine name="dashboard.calendar" label="Kalendář:" />

            <div className="text-end mt-3 ">
              <Button onClick={() => saveBlock("dashboard")}>
                Uložit nastavení
              </Button>
            </div>
          </ContentBox>
        </Col>
      </Row> */}

      {/* <ContentBox title="Oblíbené analýzy">
        <Row>
          <Col>
            <SwitchLine name="stats.terms" label="Zakázky za dané období:" />
            <SwitchLine
              name="stats.price"
              label="Předpokládaná hodnota a nabídkové ceny:"
            />
            <SwitchLine
              name="stats.value"
              label="Hodnota zakázek za dané období:"
            />
            <SwitchLine name="stats.users" label="Seznamy účastníků:" />
            <SwitchLine name="stats.winers" label="Seznamy výherců:" />
          </Col>

          <Col>
            <SwitchLine name="stats.stat" label="Kritéria hodnocení:" />
            <SwitchLine name="stats.priceWin" label="Vysoutěžené ceny:" />
            <SwitchLine
              name="stats.priceReal"
              label="Skutečně uhrazené ceny:"
            />
          </Col>
        </Row>

        <div className="text-end mt-3">
          <Button onClick={() => saveBlock("stats")}>Uložit nastavení</Button>
        </div>
      </ContentBox> */}
    </PageWrap>
  )
}

export default UserProfilPage
