import React from "react";
import { ResponsivePie } from "@nivo/pie"


const data = [
  {
    "id": "ruby",
    "label": "ruby",
    "value": 176,
    "color": "hsl(170, 70%, 50%)"
  },
  {
    "id": "stylus",
    "label": "stylus",
    "value": 462,
    "color": "hsl(247, 70%, 50%)"
  },
  {
    "id": "php",
    "label": "php",
    "value": 555,
    "color": "hsl(81, 70%, 50%)"
  },
  {
    "id": "haskell",
    "label": "haskell",
    "value": 579,
    "color": "hsl(165, 70%, 50%)"
  },
  {
    "id": "sass",
    "label": "sass",
    "value": 314,
    "color": "hsl(59, 70%, 50%)"
  }
]


// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const NivoPie = () => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: "color" }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }
    ]}
    fill={[
      {
        match: {
          id: "ruby"
        },
        id: "dots"
      },
      {
        match: {
          id: "c"
        },
        id: "dots"
      },
      {
        match: {
          id: "go"
        },
        id: "dots"
      },
      {
        match: {
          id: "python"
        },
        id: "dots"
      },
      {
        match: {
          id: "scala"
        },
        id: "lines"
      },
      {
        match: {
          id: "lisp"
        },
        id: "lines"
      },
      {
        match: {
          id: "elixir"
        },
        id: "lines"
      },
      {
        match: {
          id: "javascript"
        },
        id: "lines"
      }
    ]}
    legends={[
      {
        anchor: "bottom",
        direction: "row",
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: "#999",
        itemDirection: "left-to-right",
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: "circle",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000"
            }
          }
        ]
      }
    ]}
  />
)

export default NivoPie;