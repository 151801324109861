import React from "react"
import { Chrono } from "react-chrono";
import {useEventsTimeline} from "../../hooks/useEventsTimeline";
import Loading from "../Loading/Loading";
import AlertError from "../Alerts/AlertError";
import {preparedTimelineEvents} from "../../utils/prepareTimelineEvents";
import ContentBox from "../ContentBox/ContentBox";
import AlertInfo from "../Alerts/AlertInfo";
import "./timelineHorizontal.scss";
import { ENV } from "../../ENV";

const TimelineHorizontal = () => {
  const {data, error, loading} = useEventsTimeline()

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }

  return (
    <ContentBox title="Časová osa událostí">
      {data.length > 0 ?
        <Chrono items={preparedTimelineEvents(data)}
                mode="HORIZONTAL"
                cardWidth={400}
                cardHeight={100}
                activeItemIndex={4}
                useReadMore={false}
                showAllCardsHorizontal
                theme={{
                  primary: ENV.COLOR,
                  secondary: ENV.COLOR,
                  titleColor: '#fff',
                }}
        >
        </Chrono>
        :
        <AlertInfo msg={"Nejsou dostupná žádná data."}/>
      }
    </ContentBox>
  )
}

export default TimelineHorizontal;