import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import LINK from "../../utils/LINK"
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats"
import StatContractsContenders from "../../components/Stats/StatContractsContenders"
import StatContracts from "../../components/Stats/StatContracts"
import {PAGE_TYPES} from "../../context/enumPageTypes";
import {FilterContextProvider} from "../../context/FilterContext";

const StatsContractsContendersPage = () => {

  return (
    <PageWrap
      title="Důvody přiřazení nebo vyřazení uchazečů"
      breadcrumbs_items={[
        { label: "Analýzy", link: LINK.stats },
        { label: "Důvody přiřazení nebo vyřazení uchazečů" },
      ]}
    >
      <FilterContextProvider name={PAGE_TYPES.statsContenders}>
        <FilterBoxStats selectCompanies/>
        <StatContractsContenders/>
        <StatContracts/>
      </FilterContextProvider>
    </PageWrap>
  )
}

export default StatsContractsContendersPage
