import { combineReducers } from "redux";

import userReducer from "./user/userSlice";
import globalReducer from "./global/globalSlice";
import zakazkyReducer from "./contracts/contractsSlice";
import filtersReducer from "./filters/filtersSlice";
import enumReducer from "./enums/enumsSlice";
import eventsReducer from "./events/eventsSlice";
import companyReducer from "./company/companySlice";
import statsReducer from "./stats/statsSlice";
import settingsReducer from "./settings/settingsSlice"

const reducer = combineReducers({
  user: userReducer,
  global: globalReducer,
  contracts: zakazkyReducer,
  filters: filtersReducer,
  enums: enumReducer,
  events: eventsReducer,
  company: companyReducer,
  stats: statsReducer,
  settings: settingsReducer
});

export default reducer;
