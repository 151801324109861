import React from 'react';
import Button from "react-bootstrap/Button";

interface iLoadMore {
  limit: number
  totalCount: number
  hasMore: boolean
  loadMore: () => void
}

const LoadMore = ({limit, totalCount, hasMore, loadMore}: iLoadMore) => {

  return (
    <>
      Načteno: {limit} z {totalCount}
      {' '}
      {hasMore &&
      <Button size="sm" onClick={loadMore}>Více</Button>
      }
    </>
  );
}

export default LoadMore;