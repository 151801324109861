import React, { useEffect } from "react"
import Loading from "../Loading/Loading"
import AlertError from "../Alerts/AlertError"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ContentBox from "../ContentBox/ContentBox"
import { useFilterStats } from "../../hooks/useFilterStats"
import ServerApi from "../../api/ServerApi"
import PieStats from "../ChartsNivo/PieStats"
import { iStat, iStatsChart } from "../../api/intrefaces"
import { prepareStatsSum } from "../../utils/prepareStats"
import ExportPDFButton from "../ExportPDFButton/ExportPDFButton"

const StatContractsDocs = () => {
  const { stats, error, loading, setInitFetch } = useFilterStats(
    ServerApi.getStatsDocsByCriteria
  )

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])

  // if (loading) {
  //   return <Loading/>
  // }
  if (error) {
    return <AlertError msg={error} />
  }

  const data = [1, 2].map(graph_type => {
    const dataRaw: iStatsChart[] = stats
      .filter((stat: iStat) => parseInt(stat.graph_type) === graph_type)
      .map((stat: iStat) => ({
        id: stat.code,
        label: stat.name,
        value: parseInt(stat.count),
      }))

    return { data: dataRaw, sum: prepareStatsSum(dataRaw) }
  })

  return (
    <>
      <div className="sticky-top">{loading && <Loading />}</div>
      <Row>
        <Col>
          <ContentBox title="Způsob poskytování kvalifikační dokumentace">
            <div style={{ height: 550 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById(
                  "qualifyingDocumentation"
                )}
                graphName="Způsob poskytování kvalifikační dokumentace"
                filter=""
                numberOfContracts={`Počet zakázek: ${data[0].sum}`}
              />
              <div id="qualifyingDocumentation" style={{ height: 550 }}>
                <PieStats data={data[0].data} />
              </div>
            </div>

            <div className={"text-end"}>Počet zakázek: {data[0].sum}</div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Způsob poskytování zadávací dokumentace">
            <div style={{ height: 550 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById(
                  "assigningDocumentation"
                )}
                graphName="Způsob poskytování kvalifikační dokumentace"
                filter=""
                numberOfContracts={`Počet zakázek: ${data[1].sum}`}
              />
              <div id="assigningDocumentation" style={{ height: 550 }}>
                <PieStats data={data[1].data} />
              </div>
            </div>

            <div className={"text-end"}>Počet zakázek: {data[1].sum}</div>
          </ContentBox>
        </Col>
      </Row>
    </>
  )
}

export default StatContractsDocs
