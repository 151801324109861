import React from "react";
import { ResponsivePie } from "@nivo/pie"


// const legends = [
//   {
//     anchor: "bottom",
//     direction: "row",
//     justify: false,
//     translateX: 0,
//     translateY: 56,
//     itemsSpacing: 0,
//     itemWidth: 100,
//     itemHeight: 18,
//     itemTextColor: "#999",
//     itemDirection: "left-to-right",
//     itemOpacity: 1,
//     symbolSize: 18,
//     symbolShape: "circle",
//     effects: [
//       {
//         on: "hover",
//         style: {
//           itemTextColor: "#000"
//         }
//       }
//     ]
//   }
  // {
  //   anchor: "bottom",
  //   direction: "row",
  //   translateY: 56,
  //   itemWidth: 100,
  //   itemHeight: 18,
  //   itemTextColor: "#999",
  //   symbolSize: 18,
  //   symbolShape: "circle",
  //   effects: [
  //     {
  //       on: "hover",
  //       style: {
  //         itemTextColor: "#000",
  //       },
  //     },
  //   ],
  // },
// ]

const commonProperties = {
  // width: 900,
  // height: 500,
  margin: { top: 80, right: 120, bottom: 80, left: 120 },
  data: ["test1", "test2", "test3", 'test4', 'test5', 'test6'].map((label) => ({
    id: label,
    label: label,
    value: Math.floor(Math.random() * 100000)
  })),
  animate: true,
  activeOuterRadiusOffset: 8,
}


const NivoPieSimple = () => (
  <ResponsivePie {...commonProperties}
                 legends={[{
                   anchor: "bottom",
                   direction: "row",
                   justify: false,
                   translateX: 0,
                   translateY: 56,
                   itemsSpacing: 0,
                   itemWidth: 100,
                   itemHeight: 18,
                   itemTextColor: "#999",
                   itemDirection: "left-to-right",
                   itemOpacity: 1,
                   symbolSize: 18,
                   symbolShape: "circle",
                   effects: [
                     {
                       on: "hover",
                       style: {
                         itemTextColor: "#000"
                       }
                     }
                   ]
                 }]}/>
)

export default NivoPieSimple;