import { useState, useEffect } from "react"

export const SIZE_XS: string = "xs"
export const SIZE_SM: string = "sm"
export const SIZE_MD: string = "md"
export const SIZE_LG: string = "lg"
export const SIZE_XL: string = "xl"
export const SIZE_XXL: string = "xxl"

const resolveBreakpoint = (width: number) => {
  if (width < 576) {
    return SIZE_XS
  } else if (width >= 576 && width < 768) {
    return SIZE_SM
  } else if (width >= 768 && width < 992) {
    return SIZE_MD
  } else if (width >= 992 && width < 1200) {
    return SIZE_LG
  } else if (width >= 1200 && width < 1000) {
    return SIZE_XL
  } else if (width >= 1400) {
    return SIZE_XXL
  }
}

/** Get Media Query Breakpoints in React */
const useBreakpoint = () => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = function () {
      setSize(resolveBreakpoint(window.innerWidth))
    }
    window.addEventListener("resize", calcInnerWidth)
    return () => window.removeEventListener("resize", calcInnerWidth)
  }, [])

  return size
}

export default useBreakpoint
