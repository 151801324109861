import React from "react"
import AlertError from "../Alerts/AlertError"
import Loading from "../Loading/Loading"
import TableBox from "../ContentBox/TableBox"
import LINK from "../../utils/LINK"
import { NavLink } from "react-router-dom"
import { ENV } from '../../ENV'
import {useContractContenders} from "../../hooks/useContractContenders";
import { iContender } from "../../api/intrefaces"
import {formatNumInt} from "../../utils/utils";
import ContentBox from "../../components/ContentBox/ContentBox";

interface iContractBox {
  id: number | string
}

const ContendersList = ({ id }: iContractBox) => {
  const { contenders, loading, error } = useContractContenders(id)

  const formatAddress: any = (address: string) => {
    return address?.replace(/\n/g, ', ')
  }

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={error} />
  }
  if (!contenders.length) {
    return null
  }

  return (
    <ContentBox title="Uchazeči" collapse>
      <TableBox>
        <thead>
          <tr>
            <th>Název uchazeče</th>
            <th>IČO</th>
            <th>Adresa</th>
            <th className={"text-end"}>Nabídková cena v Kč bez DPH</th>
            <th>Vyloučen</th>
          </tr>
        </thead>
        <tbody id="table-body-contractors-list-all">
          {contenders.map((contender: iContender) => (contender.is_winner &&
            <tr className="winner" key={contender.id_company}>
              <td>
                {!ENV.NEN ?
                    <NavLink
                        to={`${LINK.contractorDetail}/${contender.id_company}`}
                    >
                      {contender.name}
                    </NavLink>
                    : contender.name}
              </td>
              <td>{contender.ic}</td>
              <td>{formatAddress(contender.company_adress)}</td>
              <td className={"text-end"}>{contender.offer_price_wovat && formatNumInt(contender.offer_price_wovat)}</td>
              <td>{contender.excluded && "Ano"}</td>
            </tr>
          ))}
          {contenders.map((contender: iContender) => (!contender.is_winner &&
            <tr key={contender.id_company}>
              <td>
                {!ENV.NEN ?
                    <NavLink
                        to={`${LINK.contractorDetail}/${contender.id_company}`}
                    >
                      {contender.name}
                    </NavLink>
                    : contender.name}
              </td>
              <td>{contender.ic}</td>
              <td>{formatAddress(contender.company_adress)}</td>
              <td className={"text-end"}>{contender.offer_price_wovat && formatNumInt(contender.offer_price_wovat)}</td>
              <td>{contender.excluded && "Ano"}</td>
            </tr>
          ))}
        </tbody>
      </TableBox>
    </ContentBox>
  )
}

export default ContendersList
