import React from "react"
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import {useSelector} from "react-redux";
import {selectShowOrHideColumnsSetting} from "../../redux/settings/settingsSlice";
import { iTableLegendQAItem } from "../../api/intrefaces"

interface iTableCell {
  data: string | number | null | undefined;
  check?: boolean;
  width?: string;
  alignRight?: string;
  children?: React.ReactNode;
  tableColumnsLegendId?: string
}

const TableCellContractsAuditList = ({data, check, width, alignRight, tableColumnsLegendId, children}: iTableCell) => {
  const tableColumnsLegend = useSelector(selectShowOrHideColumnsSetting) as iTableLegendQAItem[]
  const isColumnVisible = tableColumnsLegend.find(item => item.id === tableColumnsLegendId)?.checked

  return (
    <>
      {isColumnVisible &&
        <td className={(!data || data === "-" ? "table-cell-empty" : width) || (alignRight && alignRight)}>
          {data && data !== "-" ?
            check ?
              <FaCheckCircle className="svg-medium" color={"#198754"}/>
              :
              children ? children : data
            :
            <FaTimesCircle className="svg-medium" color={"#c73b3b"}/>
          }
        </td>
      }
    </>
  );
}

export default TableCellContractsAuditList;