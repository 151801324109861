import React, { useEffect } from "react"
import { iContract } from "../../api/intrefaces"
import ContractBox from "../ContractBox/ContractBox"
import AlertError from "../Alerts/AlertError"
import Loading from "../Loading/Loading"
import { useFilterContractsAll } from "../../hooks/useFilterContractsAll"
import AlertInfo from "../Alerts/AlertInfo"
import {iFilters} from "../../redux/filters/filtersSlice"

interface iContractsList {
  filter?: iFilters | any
  order?: string
  charts?: boolean
  isContractPlaces?: boolean
}

const ContractsList = ({ filter, order, isContractPlaces, charts }: iContractsList) => {
  const { contractsList, error, loading, setInitFetch } =
    useFilterContractsAll(filter, order, isContractPlaces)

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={error} />
  }

  return (
    <>
      {contractsList.length > 0 ?
        <div id="contract-list-id">
          {contractsList.map((contract: iContract, index: number) => (
            <ContractBox
                key={contract.id_contract + "-" + index}
                id={contract.id_contract}
                contract={contract}
                onList
            />
          ))}
        </div>
        :
        <AlertInfo msg="Žádná data"/>
      }
    </>
  )
}

export default ContractsList
