import { AppThunk } from "../store";
import ServerApi from "../../api/ServerApi";
import { setDepartments, setLoadingDepartments, setErrorDepartments } from "./filtersSlice";

export const fetchDepartments = (companyId: number | string): AppThunk => async dispatch => {
  dispatch(setLoadingDepartments());
  try {
    const response = await ServerApi.getDepartmentsByCompanyId(companyId);
    dispatch(setDepartments(response));
  } catch (err) {
    console.error("ERR FETCH oddeleni", err);
    const errMsg = err.response?.data?.error?.description || err.toString()
    dispatch(setErrorDepartments(errMsg));
  }
};