import { setLoading, setData, setError } from "./contractsSlice"
import { AppThunk } from "../store";
import ServerApi from "../../api/ServerApi";
import { iCriteriaContracts } from "../../api/intrefaces";
import { setTotalCounts } from "../filters/filtersSlice";

export const fetchContracts = (filters: iCriteriaContracts, contextName: string): AppThunk => async dispatch => {
  dispatch(setLoading());
  try {
    const response = await ServerApi.getContractsByCriteria(filters);
    dispatch(setData({name: contextName, contracts: response.data}));
    if (response.totalCount !== undefined) {
      dispatch(setTotalCounts({name: contextName, value: response.totalCount}))
    }
  } catch (err) {
    console.error("ERR FETCH zakazky", err);
    const errMsg = err.response?.data?.error?.description || err.toString()
    dispatch(setError(errMsg));
  }
};

export const fetchContractsAudit = (filters: iCriteriaContracts, contextName?: string): AppThunk => async dispatch => {
  dispatch(setLoading());
  try {
    const response = await ServerApi.getContractsAuditByCriteria(filters);
    dispatch(setData({name: contextName || "", contracts: response.data}));
    if (response.totalCount !== undefined) {
      dispatch(setTotalCounts({name: contextName || "", value: response.totalCount}))
    }
  } catch (err) {
    console.error("ERR FETCH zakazky audit", err);
    const errMsg = err.response?.data?.error?.description || err.toString()
    dispatch(setError(errMsg));
  }
};