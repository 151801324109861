import React from "react";
import {Link} from "react-router-dom"
import LINK from "../../utils/LINK";
import Button from "react-bootstrap/Button";

interface iContractBoxMenu {
  id: number,
}

const ContractBoxMenu = ({id}: iContractBoxMenu) => {

  return (
    <Link to={`${LINK.contractDetail}/${id}`} className="mx-2">
      <Button>
        Detail
      </Button>
    </Link>
  )
}

export default ContractBoxMenu;