import { setLoading, setData, setError } from "./companySlice"
import { AppThunk } from "../store";
import ServerApi from "../../api/ServerApi";


export const fetchCompany = (): AppThunk => async dispatch => {
  dispatch(setLoading());
  try {
    const response = await ServerApi.getContractCompanies();
    dispatch(setData(response));
  } catch (err) {
    console.error("ERR FETCH company", err);
    const errMsg = err.response?.data?.error?.description || err.toString()
    dispatch(setError(errMsg));
  }
};