import React from "react";
import FilterOrder from "../FilterOrder";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useFilterContext} from "../../../context/FilterContext";

const FilterBoxShowAndOrder = () => {
  const {openFilter, setOpenFilter} = useFilterContext()

  return (
      <Row className="mb-2 justify-content-end">
        <Col>
          <Button
              style={{ float: "left" }}
              variant="secondary"
              size="sm"
              onClick={() => setOpenFilter(!openFilter)}
          >
            {openFilter ? "Zavřít filtr" : "Filtrovat"}
          </Button>
        </Col>
        <Col className="justify-content-end">
          <FilterOrder maximWidth={300}
              orderOptions={[
                { label: "Datum zahájení - sestupně", value: "date_start DESC" },
                { label: "Datum zahájení - vzestupně", value: "date_start ASC" },
                { label: "Předpokládaná hodnota - vzestupně", value: "sup_value ASC" },
                { label: "Předpokládaná hodnota - sestupně", value: "sup_value DESC" },
                { label: "Číslo VZ - vzestupně", value: "system_number ASC" },
                { label: "Číslo VZ - sestupně", value: "system_number DESC" },
                { label: "Smluvní cena - vzestupně", value: "wovat_value ASC" },
                { label: "Smluvní cena - sestupně", value: "wovat_value DESC" },
              ]}
          />
        </Col>
      </Row>
  )
}

export default FilterBoxShowAndOrder;