import React from 'react';
import Alert from "react-bootstrap/Alert";

interface iAlertError {
  msg: string
}

const AlertError = ({ msg }: iAlertError) => {
  return (
    <Alert variant="danger">
      {msg}
    </Alert>
  );
}

export default AlertError;