import {iCriteriaGlobalContracts, iGlobalStatsByCriteria, iGlobalTestStatsChart} from "../api/intrefaces";
import moment from "moment";

const total: string = "Total"

interface iFilters extends iCriteriaGlobalContracts {
  procedureCodes?: string
}
// Funkce pro zaokrouhlení průměrné nabídky na jedno desetinné místo
const roundAvgOffers = (data: iGlobalStatsByCriteria[]) => {
  let result: any = []
  for (let i = 0; i < data.length; i++) {
    result.push(data[i].avg_offers = Number(data[i].avg_offers.toFixed(1)))
  }
  return result
}

// Funkce pro filtrování dat podle typu zakázky, roku a seřazení od roku 2018
const filterOTypeAndYearAndSort = (data: iGlobalStatsByCriteria[], typ: string) => {
  roundAvgOffers(data)
  return (
      data.filter((otype) => otype.otype === typ)
          .filter((year) => parseInt(year.offer_year) >= 2018)
          .sort(function (a, b) {
            return parseInt(a.offer_year) - parseInt(b.offer_year)
          })
  )
}

// Funkce pro filtrování dat podle druhu ZŘ
// Na základě vstupních dat z vybrané volby v selectu "Druh ZŘ" se vyfiltrují kódy ZŘ
// Nejdříve se rozdělí string s kódy ZŘ na pole, vytvoří se nové pole, které obsahuje vyfiltrované kódy ZŘ
// Potom se ověřuje, jestli je pole s kódy ZŘ prázdné
// Když je pole prázdné, tak se vyfiltrují všechny kódy ZŘ
// Když pole není prázdné, tak se vyfiltrují kódy ZŘ, které obsahují kód ZŘ z pole
const iterationProcedureCode = (data: iGlobalTestStatsChart[], filters: iFilters, typ: string) => {
  const replaceStringFilters = filters.procedureCodes
  const filtersSplit = replaceStringFilters ? replaceStringFilters.split(", ") : []
  const arrFiltersSplit = [...filtersSplit]

  const typeData = data.filter((otype) => otype.otype === typ)

  if (arrFiltersSplit.length === 0) {
    return typeData.filter((code) => code.procedureCode === total)
  } else {
    return typeData.filter((code) =>
        code.procedureCode.split(", ").some(procedureCode =>
            arrFiltersSplit.includes(procedureCode))
    )
  }
}

// Funkce pro spojení statických globálních dat a dynamických dat z API
// Nejdříve se vyfiltrují data od roku 2018 do aktuálního roku
// Potom se sloučí data do pole s objekty, které obsahují data z API a statická data
const mergeData = (data: iGlobalStatsByCriteria[], globalData: iGlobalTestStatsChart[]) => {
  let dataJoined: iGlobalTestStatsChart[] = []
  const yearActual = moment().format("YYYY")
  for (let i = 2018; i < Number(yearActual); i++) {
    // let objJoined = {}
    const objByYearJoin = data.filter((year) =>
        parseInt(year.offer_year) === i)
    const objGlobalByYearJoin = globalData.filter((year) =>
        parseInt(year.offer_year) === i)
    // console.log(objByYearJoin[0], "objByYearJoin")
    const objJoined = {...objByYearJoin[0], ...objGlobalByYearJoin[0]}
    // console.log(objJoined, "objJoined")
    dataJoined.push(objJoined)
  }
  return dataJoined
  // console.log(dataJoined, "dataJoined")
}

// Funkce pro vyfiltrovaní objektů, které nemají prázdnou hodnotu průměrné nabídky
const resetAvgOffers = (data: iGlobalTestStatsChart[]) => {
  return (
      data.filter((obj) =>
          obj.avg_offers !== null && obj.avg_offers !== 0 && obj.avg_offers !== undefined
      )
  )
}

// Funkce pro přejmenování klíčů v objektu
const renameKeys = (data: iGlobalTestStatsChart[]) => {
  return data.map((item) => ({
    Globalni_prumer_nabidek: item.global_avg_offers,
    Prumer_nabidek: item.avg_offers,
    ...item
  }))
}

// Funkce pro přípravu dat pro graf, volající všechny funkce
export const preparedData = (data: iGlobalStatsByCriteria[], dataGlobal: iGlobalTestStatsChart[], filters: iCriteriaGlobalContracts, typ: string) => {
  const filterContracts = filterOTypeAndYearAndSort(data, typ)
  // console.log(filterContracts, "filterContracts")
  const globalDataAllFiltered = iterationProcedureCode(dataGlobal, filters, typ)
  // console.log(globalDataAllFiltered, "globalDataAllFiltered")
  const dataAllMerged = mergeData(filterContracts, globalDataAllFiltered)
  // console.log(dataAllMerged, "dataAllMerged")
  const dataReseted = resetAvgOffers(dataAllMerged)
  return renameKeys(dataReseted)
}