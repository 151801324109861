import React from "react";
import moment from "moment";
import { DATE_FORMAT_DAY } from "../../utils/utils";
import AlertError from "../Alerts/AlertError";
import { iEvent } from "../../api/intrefaces";
import { NavLink } from "react-router-dom";
import LINK from "../../utils/LINK";
import { useContractEvents } from "../../hooks/useContractEvents";
import Loading from "../Loading/Loading";
import AlertInfo from "../Alerts/AlertInfo";
import TableBox from "../ContentBox/TableBox";
import {useLoadMore} from "../../hooks/useLoadMore";
import LoadMore from "../LoadMore/LoadMore";

interface iTerminyList {
  id: number | string
}

const EventsListContract = ({ id }: iTerminyList) => {

  const { events, loading, error } = useContractEvents(id)
  const {limit, loadMore, hasMore} = useLoadMore(events.length)

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }
  if (!events || events.length === 0) {
    return <AlertInfo msg="Žádné události"/>
  }

  const eventsList = events.slice(0, limit)

  return (
    <>
      <TableBox>
          <thead>
          <tr>
            <th>Typ události</th>
            <th>Název události</th>
            <th>Stav události</th>
            <th>Vznik události</th>
            <th>Termín události</th>
          </tr>
          </thead>
          <tbody>
          {eventsList.map((event: iEvent) =>
            <tr key={event.id_timeline_action}>
              <td>
                {/*{getOptionLabel(OPTIONS.event_type, event.type)}*/}
                {event.type}
              </td>
              <td>
                <NavLink to={`${LINK.eventDetail}/${event.id_timeline_action}`}>
                  {event.name}
                </NavLink>
              </td>
              <td>
                {event.state === "UNKNOWN" ? '-' : event.state}
              </td>
              <td>
                {moment(event.date).format(DATE_FORMAT_DAY)}
              </td>
              <td>
                {moment(event.term).format(DATE_FORMAT_DAY)}
              </td>
            </tr>
          )}
          </tbody>
      </TableBox>
      <LoadMore
        limit={limit}
        totalCount={events.length}
        hasMore={hasMore}
        loadMore={loadMore}/>
    </>
  );
}

export default EventsListContract;