export const PAGE_TYPES = {
  home: 'home',
  qindex: 'qindex',
  contractors: 'contractors',
  contractorDetail: 'contractorDetail',
  contratsList: 'contratsList',
  contratsListRunning: 'contratsListRunning',
  contractDetail: "contractDetail",
  eventsList: 'eventsList',
  globalIndicators: 'globalIndicators',
  newsInfo: 'newsInfo',
  statsContenders: 'statsContenders',
  statsCount: 'statsCount',
  statsDocs: 'statsDocs',
  statsPlaces: 'statsPlaces',
  statsPrice: 'statsPrice',
  statsStates: 'statsStates',
  statsStartedStates: 'statsStartedStates',
  statsWinners: 'statsWinners',
  statsWinnersSupValue: 'statsWinnersSupValue',
  statsContractors: 'statsContractors'
}
