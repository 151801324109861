import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import LINK from "../../utils/LINK";
import PageWrapContent from "../../components/PageWrap/PageWrapContent";


const UserChangePassPage = () => {

  const [login, setLogin] = useState("")

  const onSubmit = () => {
    //todo test zda je vyplneno
    //todo zmena hesla
    // todo notifikace po zmene - ok/err

  }
  return (
    <PageWrapContent>
      <div className="w-100 align-items-center justify-content-center d-flex mb-5">
        <div style={{ maxWidth: 400, fontSize: 14 }}>

          <Card className={"p-3 shadow-sm"}>
            <Card.Body>

              <h4 className="mb-4 text-center">Žádost o změnu hesla</h4>

              <Form
                onSubmit={onSubmit}>

                <Form.Text className="mb-3">
                  Zadejte přihlašovací jméno a my Vám zašleme pokyny k vytvoření nového hesla.
                </Form.Text>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Přihlašovací jméno</Form.Label>
                  <Form.Control
                    autoFocus
                    required type="text"
                    value={login}
                    onChange={e => setLogin(e.target.value)}
                    style={{ borderWidth: 2.3 }}
                  />
                </Form.Group>


                <Form.Group className="mt-4">
                  <Button type="submit" className={"btn-block"} style={{ padding: "12px 10px" }}>
                    Odeslat
                  </Button>
                </Form.Group>
              </Form>

              <div className="mt-4 text-center">
                <NavLink to={LINK.login}>
                  Přihlásit se
                </NavLink>
              </div>

            </Card.Body>
          </Card>
        </div>
      </div>
    </PageWrapContent>

  )
}

export default UserChangePassPage;