import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLimitOffset } from "../redux/filters/filtersSlice";
import { fetchEvents } from "../redux/events/eventsAction";
import { selectEvents } from "../redux/events/eventsSlice";
import {useFilterContext} from "../context/FilterContext";

export const useFilterEventsAll = () => {
  const dispatch = useDispatch()
  const {activeFilters, contextName} = useFilterContext()
  const { limit, offset } = useSelector(selectLimitOffset)

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)

  useEffect(() => {
    if (init) {
      let f = {
        ...activeFilters,
        limit: limit,
        offset: offset
      }
      dispatch(fetchEvents(f, contextName))
    }
  }, [activeFilters, limit, offset, init, dispatch, contextName])

  const { eventsList, error, loading } = useSelector(selectEvents)

  return { eventsList, error, loading, setInitFetch }
}