// React components
import TableModal from "../../../components/TableModal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

// Zod
import { z } from "zod"

// React Hook Form
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"

type ChangeUserPasswordModalProps = {
  handleClose: () => void
}

const ChangeUserPasswordModal = ({
  handleClose,
}: ChangeUserPasswordModalProps) => {
  const requiredField = "Toto pole je povinné"

  const formSchema = z.object({
    oldPassword: z.string().min(1, requiredField),
    newPassword: z.string().min(1, requiredField),
    newPasswordConfirmation: z.string().min(1, requiredField),
  })

  type FormSchemaType = z.infer<typeof formSchema>

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormSchemaType> = data => {
    console.log("DATA", data)
  }

  return (
    <TableModal handleClose={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
        <h3 className="mb-3">Změna hesla</h3>

        <Form.Group className="mb-2" controlId="oldPassword">
          <Form.Label>Původní heslo</Form.Label>
          <Form.Control
            type="password"
            style={{ borderWidth: 2.3 }}
            {...register("oldPassword")}
          />
          <Form.Text className="text-danger">
            {errors.oldPassword && errors.oldPassword?.message}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-2" controlId="newPassword">
          <Form.Label>Nové heslo</Form.Label>
          <Form.Control
            type="password"
            style={{ borderWidth: 2.3 }}
            {...register("newPassword")}
          />
          <Form.Text className="text-danger">
            {errors.newPassword && errors.newPassword?.message}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-2" controlId="newPasswordConfirmation">
          <Form.Label>Nové heslo (potvrzení)</Form.Label>
          <Form.Control
            type="password"
            style={{ borderWidth: 2.3 }}
            {...register("newPasswordConfirmation")}
          />
          <Form.Text className="text-danger">
            {errors.newPasswordConfirmation &&
              errors.newPasswordConfirmation?.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mt-4">
          <Button
            type="submit"
            className={"btn-block"}
            style={{ padding: "12px 10px" }}
          >
            Změnit
          </Button>
        </Form.Group>
      </Form>
    </TableModal>
  )
}

export default ChangeUserPasswordModal
