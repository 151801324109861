import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import LINK from "../../utils/LINK"
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats"
import StatContractsPrice from "../../components/Stats/StatContractsPrice"
import StatContracts from "../../components/Stats/StatContracts"
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { FilterContextProvider } from "../../context/FilterContext"

const StatsContractsPricePage = () => {

  return (
    <PageWrap
      title="Hodnota zakázky za dané období"
      breadcrumbs_items={[
        { label: "Analýzy", link: LINK.stats },
        { label: "Hodnota zakázek za dané období" },
      ]}
    >
      <FilterContextProvider name={PAGE_TYPES.statsPrice}>
        <FilterBoxStats selectCompanies/>
        <StatContractsPrice/>
        <StatContracts/>
      </FilterContextProvider>
    </PageWrap>
  )
}

export default StatsContractsPricePage
