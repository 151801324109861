import React from "react";
import moment from "moment";
import { DATE_FORMAT_DAY, humanFileSize } from "../../utils/utils";
import AlertError from "../Alerts/AlertError";
import { iDocument } from "../../api/intrefaces";
import { useContractDocuments } from "../../hooks/useContractDocuments";
import Loading from "../Loading/Loading";
import AlertInfo from "../Alerts/AlertInfo";
import TableBox from "../ContentBox/TableBox";
import {useLoadMore} from "../../hooks/useLoadMore";
import LoadMore from "../LoadMore/LoadMore";
import { ENV } from "../../ENV";

interface iDocsList {
  id: number | string
}

const DocumentsList = ({ id }: iDocsList) => {

  const { documents, loading, error } = useContractDocuments(id)
  const {limit, loadMore, hasMore} = useLoadMore(documents.length)

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }
  if (!documents || documents.length === 0) {
    return <AlertInfo msg="Žádné veřejné dokumenty"/>
  }

  const documentsList = documents.slice(0, limit)

  return (
    <>
      <TableBox>
        <thead>
        <tr>
          <th>Název dokumentu</th>
          <th>Název souboru</th>
          <th>Datum uveřejnění</th>
          <th>Velikost souboru</th>
        </tr>
        </thead>
        <tbody>
        {documentsList.map((doc: iDocument, index: number) =>
          doc.category !== null &&
            <tr key={index}>
              <td>
                {doc.document_name}
              </td>
              <td>
                {/*<a href={`${IEN_URL}${doc.url_part}`} target="_blank" rel="noreferrer">*/}
                {!ENV.NEN ?
                    <a href={`${ENV.IEN_URL}${doc.download_file_url}`} target="_blank" rel="noreferrer">
                      {doc.filename}
                    </a>
                    : doc.filename}
              </td>
              <td>
                {/*{moment(doc.dms_date_created).format(DATE_FORMAT_DAY)}*/}
                {moment(doc.date_publish).format(DATE_FORMAT_DAY)}
              </td>
              <td>
                {humanFileSize(doc.size)}
              </td>
            </tr>
        )}
        </tbody>
      </TableBox>
      <LoadMore
        limit={limit}
        totalCount={documents.length}
        hasMore={hasMore}
        loadMore={loadMore}/>
    </>
  );
}

export default DocumentsList;