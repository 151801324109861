import React, { useRef, useEffect } from "react";
import {iHomeStat} from "../../api/intrefaces";
import "./homestat.scss"

const draw = (ctx: any, frameCount: number, count: number, color: string, round: number) => {
  let posX = ctx.canvas.width/2
  let posY = ctx.canvas.height - 10
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
  ctx.beginPath()
  ctx.arc( posX, posY, 60, Math.PI, 2 * Math.PI )
  ctx.strokeStyle = '#b1b1b1'
  ctx.lineWidth = '15'
  ctx.stroke()
  ctx.lineCap = 'round'

  ctx.beginPath()
  ctx.arc( posX, posY, 60, Math.PI, (Math.PI/180) * (180 + frameCount) )
  ctx.strokeStyle = color
  ctx.lineWidth = '15'
  ctx.stroke()

  ctx.font = "30px Montserrat"
  ctx.textAlign = "center"
  ctx.textBaseline = "alphabetic"
  ctx.fillText(count === 0 ? 0 : count.toFixed(round), ctx.canvas.width/2, ctx.canvas.height)
}

const HomeStatNew = ({ num, numSecondary, text, color, tooltipText, round }: iHomeStat) => {
  const canvasRef = useRef(null)
  const preparedFps = (num: number) => {
    if (num < 100) {
      return 40
    } else if (num < 1000) {
      return 60
    } else if (num < 10000) {
      return 80
    } else {
      return 100
    }
  }

  useEffect(() => {
  const canvas: any = canvasRef.current
  const context = canvas.getContext('2d')
    let frameCount = 0
    let count = 0
    let animationFrameId: any
    let fps = preparedFps(num)
    // let fps = (num / 100) * 3

    const render = () => {
      frameCount += 180 / fps
      if (frameCount >= 180) {
        frameCount = 180
      }
      count += num / fps
      if (count >= num) {
        count = num
      }
      draw(context, frameCount, count, color, round || 0)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [num, color, round])

  return (
    <div className={tooltipText ? "canvas-wrap easy-tooltip" : "canvas-wrap"}>
      <canvas id="canvas" ref={canvasRef} width="140" height="80"></canvas>
      { numSecondary ?
        <p className="stat-text">{text} <br/> z počtu {numSecondary} ZŘ</p>
          :
        <div className="stat-text">{text}</div>
      }
      <span className="tooltip-text">{tooltipText}</span>
    </div>
  );
}

export default HomeStatNew;