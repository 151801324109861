import React, { useState } from "react"
// import { useState } from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ContentBox from "../../components/ContentBox/ContentBox"
// import { Link } from "react-router-dom"
// import LINK from "../../utils/LINK"
import { useSelector } from "react-redux"
import { selectUserData } from "../../redux/user/selectors"
import TableBox from "../../components/ContentBox/TableBox"
import { Button } from "react-bootstrap"
// import Form from "react-bootstrap/Form"
// import Button from "react-bootstrap/Button"
// import { toast } from "react-toastify"
import { iUserData } from "../../api/intrefaces";

// interface iNastaveni {
//   [block: string]: { [item: string]: boolean }
// }

// Styles
import styles from "./UserManagementPage.module.scss"

// Test data
import { USERS_TEST_DATA } from "./UserManagementPage.constants"
import NewUserModal from "./NewUserModal"
import RemoveUserModal from "./RemoveUserModal"
import EditUserModal from "./EditUserModal"
import ChangeUserPasswordModal from "./ChangeUserPasswordModal/ChangeUserPasswordModal"

const UserManagementPage = () => {
  const [newUserModalOpen, setNewUserModalOpen] = useState(false)
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false)
  const [editUserModalOpen, setEditUserModalOpen] = useState(false)
  const [userPasswordChangeModalOpen, setUserPasswordChangeModaleOpen] =
    useState(false)
  // todo link na zmenit heslo
  // todo akce ulozeni
  // todo nacist nastaveni

  // const [data, setData] = useState<iNastaveni>({
  //   notif: {
  //     termsStart: false,
  //     termsEnd: true,
  //     termsRand: true,
  //     event4: false,
  //     event5: true,
  //   },
  //   dashboard: {
  //     navigator: true,
  //     quality: false,
  //     stats: true,
  //     calendar: false,
  //   },
  //   stats: {
  //     terms: true,
  //     price: false,
  //     value: true,
  //     users: false,
  //     winers: false,
  //     stat: true,
  //     priceWin: true,
  //     priceReal: true,
  //   },
  // })

  // const cahngeData = (block: string, item: string, value: boolean) => {
  //   setData(data => ({
  //     ...data,
  //     [block]: {
  //       ...data[block],
  //       [item]: value,
  //     },
  //   }))
  // }

  // const saveBlock = (block: string) => {
  //   console.log("TODO save", block, data[block])
  //   toast.success("Sekce uložena")
  // }

  const Line = ({
    title,
    children,
  }: {
    title: string
    children: React.ReactNode
  }) => (
    <Row>
      <Col sm={6} className="text-end">
        <strong>{title}:</strong>
      </Col>
      <Col>{children}</Col>
    </Row>
  )

  // const SwitchLine = ({ label, name }: { label: string; name: string }) => {
  //   const [block, item] = name.split(".")
  //   const val = data[block][item]
  //   return (
  //     <Row>
  //       <Col sm={10}>{label}</Col>
  //       <Col>
  //         <Form.Check
  //           type="switch"
  //           onChange={e => cahngeData(block, item, e.target.checked)}
  //           id={"ch" + name}
  //           checked={val}
  //         />
  //       </Col>
  //     </Row>
  //   )
  // }

  const userData: iUserData | null = useSelector(selectUserData)
  const {groups, email, name, preferred_username} = userData || {}
  const idOrganizations = groups?.join(", ").replace(/\/ORGANIZATIONS\//g, "")

  return (
    <PageWrap
      title="Správa uživatelů"
      breadcrumbs_items={[{ label: "Správa uživatelů" }]}
    >
      <ContentBox title="Uživatel">
        <Row>
          <Col>
            <Line title={"Uživatel"}>
              {name}
            </Line>
            <Line title={"E-mail"}>{email}</Line>
          </Col>
          <Col>
            <Line title={"ID Organizace"}>{idOrganizations}</Line>
            <Line title={"Přihlašovací jméno"}>{preferred_username}</Line>
          </Col>
          <Col>
            {/*<Line title={"Oddělení"}>{}</Line>*/}
            {/*<Line title={"Přihlašovací heslo"}>*/}
            {/*  <Link to={LINK.changePass}>Změnit</Link>*/}
            {/*</Line>*/}
          </Col>
        </Row>
      </ContentBox>

      <TableBox>
        <thead>
          <tr>
            <th>Uživatel</th>
            <th>E-mail</th>
            <th>Přihlašovací jméno</th>
            <th>Role</th>
            <th>Datový sklad VZ</th>
            <th>Organizační jednotka</th>
            <th colSpan={3}>Akce</th>
          </tr>
        </thead>
        <tbody>
          {USERS_TEST_DATA.map(user => {
            return (
              <tr key={user.id}>
                <td>{user.user}</td>
                <td>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>{user.dataWarehouseVz}</td>
                <td>{user.organizationalUnit}</td>
                <td>
                  <span
                    onClick={() => {
                      if (editUserModalOpen === false) {
                        setEditUserModalOpen(true)
                      }
                    }}
                    className={styles["button-text"]}
                  >
                    Upravit
                  </span>
                </td>
                <td>
                  <span
                    onClick={() => {
                      if (userPasswordChangeModalOpen === false) {
                        setUserPasswordChangeModaleOpen(true)
                      }
                    }}
                    className={styles["button-text"]}
                  >
                    Změnit heslo
                  </span>
                </td>
                <td>
                  <span
                    onClick={() => {
                      if (removeUserModalOpen === false) {
                        setRemoveUserModalOpen(true)
                      }
                    }}
                    className={styles["button-text"]}
                  >
                    Odstranit
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </TableBox>

      <div className={styles["add-button-wrapper"]}>
        <Button
          onClick={() => {
            if (newUserModalOpen === false) {
              setNewUserModalOpen(true)
            }
          }}
        >
          Nový +
        </Button>
      </div>

      {removeUserModalOpen && (
        <RemoveUserModal
          handleClose={() => {
            setRemoveUserModalOpen(false)
          }}
        />
      )}

      {newUserModalOpen && (
        <NewUserModal
          handleClose={() => {
            setNewUserModalOpen(false)
          }}
        />
      )}

      {editUserModalOpen && (
        <EditUserModal
          handleClose={() => {
            setEditUserModalOpen(false)
          }}
        />
      )}

      {userPasswordChangeModalOpen && (
        <ChangeUserPasswordModal
          handleClose={() => {
            setUserPasswordChangeModaleOpen(false)
          }}
        />
      )}

      {/* <Row>
        <Col>
          <ContentBox title="Požadované notifikace">
            <SwitchLine
              name="notif.termsStart"
              label="Oznamovat termíny vypsání zakázek:"
            />
            <SwitchLine
              name="notif.termsEnt"
              label="Oznamovat termíny ukončení podávání nabídek u zakázek:"
            />
            <SwitchLine
              name="notif.termsRand"
              label="Oznamovat libovolné propadlé termíny u zakázek:"
            />
            <SwitchLine
              name="notif.event4"
              label="Zasílání notifikací o události č.4:"
            />
            <SwitchLine
              name="notif.event5"
              label="Zasílání notifikací o události č.5:"
            />
            <div className="text-end mt-3">
              <Button onClick={() => saveBlock("notif")}>
                Uložit nastavení
              </Button>
            </div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Požadované komponenty rozcestníku">
            <SwitchLine
              name="dashboard.navigator"
              label="Rozbalený navigator:"
            />
            <SwitchLine
              name="dashboard.quality"
              label="Přehled kvality zakázek:"
            />
            <SwitchLine name="dashboard.stats" label="Souhrnné analýzy:" />
            <SwitchLine name="dashboard.calendar" label="Kalendář:" />

            <div className="text-end mt-3 ">
              <Button onClick={() => saveBlock("dashboard")}>
                Uložit nastavení
              </Button>
            </div>
          </ContentBox>
        </Col>
      </Row> */}

      {/* <ContentBox title="Oblíbené analýzy">
        <Row>
          <Col>
            <SwitchLine name="stats.terms" label="Zakázky za dané období:" />
            <SwitchLine
              name="stats.price"
              label="Předpokládaná hodnota a nabídkové ceny:"
            />
            <SwitchLine
              name="stats.value"
              label="Hodnota zakázek za dané období:"
            />
            <SwitchLine name="stats.users" label="Seznamy účastníků:" />
            <SwitchLine name="stats.winers" label="Seznamy výherců:" />
          </Col>

          <Col>
            <SwitchLine name="stats.stat" label="Kritéria hodnocení:" />
            <SwitchLine name="stats.priceWin" label="Vysoutěžené ceny:" />
            <SwitchLine
              name="stats.priceReal"
              label="Skutečně uhrazené ceny:"
            />
          </Col>
        </Row>

        <div className="text-end mt-3">
          <Button onClick={() => saveBlock("stats")}>Uložit nastavení</Button>
        </div>
      </ContentBox> */}
    </PageWrap>
  )
}

export default UserManagementPage
