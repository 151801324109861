import {useEffect, useState} from "react";

const PAGE_ITEMS = 10

export const useLoadMore = (totalCount: number) => {

  const [limit, setLimit] = useState(0)

  useEffect(() => {
    setLimit(Math.min(totalCount, PAGE_ITEMS))
  }, [totalCount])

  const loadMore = () => {
    const newLimit = Math.min(totalCount, limit + PAGE_ITEMS)
    setLimit(newLimit)
  }

  const hasMore = limit < totalCount

  return {limit, loadMore, hasMore}
}