import React, { useState } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { NavLink } from "react-router-dom"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import { FaUser, FaSignOutAlt } from "react-icons/fa"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectIsLoged, selectUserData } from "../../redux/user/selectors"
import { logoutUser } from "../../redux/user/userAction"
import "./header.scss"
import logo from "./logo.png"
import ezak_logo from "./ezak_logo.png"
import LINK from "../../utils/LINK"
import { ENV } from '../../ENV'

const Header = () => {
  const isLoged = useSelector(selectIsLoged)
  const user = useSelector(selectUserData)
  // const isAdmin = user && user.preferred_username === "admin"
  const dispatch = useDispatch()

  const onLogout = () => {
    dispatch(logoutUser("Uživatel odhlášen"))
  }

  const [openMenu, setOpenMenu] = useState(false)

  return (
    <div className="header">
      <Navbar expand="lg" className="py-0">
        <Container fluid="md">
          <div className="nav_top_line ms-auto">
            <Navbar.Text className="me-4">
              Organizace: <strong>{ENV.IEN_NAME}</strong>
            </Navbar.Text>
            <Navbar.Text>
              Připojené nástroje:{" "}
              {ENV.NEN ?
                <strong>{"NEN"}</strong>
                :
                <a
                  href={ENV.IEN_URL}
                  target="_blank"
                  rel="noreferrer"
                  title={"E-ZAK " + ENV.IEN_NAME}
                >
                  <img alt="EZAK logo" src={ezak_logo} width={40}/>
                </a>
              }
            </Navbar.Text>
          </div>
        </Container>
      </Navbar>

      <Navbar expand="lg" className="py-0">
        <Container fluid="md" className="mb-3">
          <Navbar.Brand className="logo_title" href="/">
            <div>
              <img className="logo" alt="MVZ logo" src={logo} width={213} />
            </div>
          </Navbar.Brand>

          {isLoged && (
            <div className="nav_menu_user ms-auto">
              <Link
                // to={isAdmin ? "/sprava-uzivatelu" : "/profil-uzivatele"}
                to={"/profil-uzivatele"}
                className="me-4"
              >
                <FaUser className="mx-2" />
                {user?.name} {user?.surname}
              </Link>
              <Button
                className="btn-logout"
                title="Odhlásit se"
                onClick={onLogout}
              >
                <FaSignOutAlt className="mx-2" />
                Odhlásit
              </Button>

              <Navbar.Toggle
                className="ms-4"
                aria-controls="basic-navbar-nav"
                onClick={() => setOpenMenu(!openMenu)}
              />
            </div>
          )}
        </Container>
      </Navbar>

      <Navbar expanded={openMenu} expand="lg" className="py-0">
        <Container fluid="md">
          {isLoged && (
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <NavLink className="nav-link" exact to={"/"}>
                  Dashboard
                </NavLink>
                <NavLink className="nav-link" to={LINK.contractsList}>
                  Přehled zakázek
                </NavLink>
                {!ENV.NEN && <NavLink className="nav-link" to={LINK.eventsList}>
                  Události zakázek
                </NavLink>}
                <NavLink className="nav-link" to={LINK.stats}>
                  Analýzy
                </NavLink>
                <NavLink className="nav-link" to={LINK.audit}>
                  Audit
                </NavLink>
                <NavLink className="nav-link" to={LINK.globalIndicators}>
                  Globální ukazatele
                </NavLink>
                {/*{isAdmin ? (*/}
                {/*  <NavLink className="nav-link" to={LINK.userManagement}>*/}
                {/*    Správa uživatelů*/}
                {/*  </NavLink>*/}
                {/*) : (*/}
                {/*  <NavLink className="nav-link" to={LINK.profil}>*/}
                {/*    Profil uživatele*/}
                {/*  </NavLink>*/}
                {/*)}*/}
                <NavLink className="nav-link" to={LINK.help}>
                  Nápověda
                </NavLink>
                <NavLink className="nav-link" to={LINK.newsInfo}>
                  Novinky
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
    </div>
  )
}

export default Header
