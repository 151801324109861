import React, { CSSProperties, ReactNode, ChangeEvent } from "react";
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import {useDispatch} from "react-redux";
import {setShowOrHideColumnsSetting} from "../../../redux/settings/settingsSlice";
import { iTableLegendQAItem } from "../../../api/intrefaces"

interface CheckboxMenuProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  "aria-labelledby"?: string;
  onSelectAll: () => void;
  onSelectNone: () => void;
}

const CheckboxMenu = React.forwardRef<HTMLDivElement, CheckboxMenuProps>(
    (
        {
          children,
          style,
          className,
          "aria-labelledby": labeledBy,
          onSelectAll,
          onSelectNone
        },
        ref
    ) => {
      return (
          <div
              ref={ref}
              style={style}
              className={`${className} CheckboxMenu`}
              aria-labelledby={labeledBy}
          >
            <div
                className="d-flex flex-column"
                style={{ maxHeight: "calc(70vh)", overflow: "none" }}
            >
              <ul
                  className="list-unstyled flex-shrink mb-0"
                  style={{ overflow: "auto" }}
              >
                {children}
              </ul>
              <div className="dropdown-item border-top pt-2 pb-0">
                <ButtonGroup size="sm">
                  <Button variant="link" onClick={onSelectAll}>
                    Vybrat vše
                  </Button>
                  <Button variant="link" onClick={onSelectNone}>
                    Odebrat vše
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
      );
    }
);

interface CheckDropdownItemProps {
  children: ReactNode;
  id: string;
  checked: boolean;
  onChange?: (id: string, event: ChangeEvent<HTMLInputElement>) => void;
}

const CheckDropdownItem = React.forwardRef<HTMLDivElement, CheckDropdownItemProps>(
    ({ children, id, checked, onChange }, ref) => {
      return (
          <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
            <Form.Check
                type="checkbox"
                label={children}
                checked={checked}
                onChange={onChange && ((event) => onChange(id, event))}
            />
          </Form.Group>
      );
    }
);

interface CheckboxDropdownProps {
  items: iTableLegendQAItem[];
}

const CheckboxDropdown: React.FC<CheckboxDropdownProps> = ({ items }) => {
  const dispatch = useDispatch()

  const handleChecked = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    const changeItem = items.map(item =>
        item.id === key ? { ...item, checked: event.target.checked } : item
    )
    dispatch(setShowOrHideColumnsSetting(changeItem))
    localStorage.setItem("tableLegendQualitativeAnalyse", JSON.stringify(changeItem))
  };

  const handleSelectAll = () => {
    const checkedAll = items.map(item => ({ ...item, checked: true }))
    dispatch(setShowOrHideColumnsSetting(checkedAll))
    localStorage.setItem("tableLegendQualitativeAnalyse", JSON.stringify(checkedAll))
  };

  const handleSelectNone = () => {
    const checkedNone = items.map(item => ({ ...item, checked: false }))
    dispatch(setShowOrHideColumnsSetting(checkedNone))
    localStorage.setItem("tableLegendQualitativeAnalyse", JSON.stringify(checkedNone))
  };

  return (
      <Dropdown style={{paddingLeft: 12, paddingRight: 12}}>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          Zobrazení sloupců
        </Dropdown.Toggle>

        <Dropdown.Menu
            as={CheckboxMenu}
            onSelectAll={handleSelectAll}
            onSelectNone={handleSelectNone}
        >
          {items.map(i => (
              <Dropdown.Item
                  key={i.id}
                  as={CheckDropdownItem}
                  id={i.id}
                  checked={i.checked}
                  // @ts-ignore
                  onChange={handleChecked}
              >
                {i.label}
              </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
  );
};

export default CheckboxDropdown;