import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iStatsTypes } from "../../api/intrefaces";
import { RootState } from "../store";


interface iStatsData {
  count: iStatsTypes,
  sum_kc: iStatsTypes
}

const slice = createSlice({
  name: "stats",
  initialState: {
    error: "",
    loading: false,
    stats: { count: {}, sum_kc: {} } as iStatsData,
  },
  reducers: {
    setLoading(state) {
      state.loading = true;
      state.error = "";
    },
    setData(state, action: PayloadAction<iStatsData>) {
      state.stats = action.payload;
      state.loading = false;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const selectStats = (state: RootState) => ({
  stats: state.stats.stats,
  error: state.stats.error,
  loading: state.stats.loading
})


export const { setLoading, setData, setError } = slice.actions;
export default slice.reducer;
