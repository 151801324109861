import {iContract} from "../api/intrefaces";

export const reformattingPrices = (data: iContract[]) => {
  return data.map((contract) => {
    const {code_part,
      department_name,
      dns,
      pact,
      offer_count,
      code_otype,
      code_type,
      date_start,
      code_procedure,
      code_state, ...rest} = contract
    return {
      ...rest,
      date_canceled: contract.name_state !== "Zrušeno" && contract.date_canceled === null ? "N/A" : contract.date_canceled,
      sup_value: contract.sup_value && new Intl.NumberFormat().format(parseInt(contract.sup_value.toString())),
      // sup_value: typeof contract.sup_value === "string" && parseFloat(contract.sup_value).toLocaleString(),
      wovat_value: contract.wovat_value && new Intl.NumberFormat().format(parseInt(contract.wovat_value.toString())),
      vat_value: contract.vat_value && new Intl.NumberFormat().format(parseInt(contract.vat_value.toString())),
      sum_wovat_value: contract.sum_wovat_value && new Intl.NumberFormat().format(parseInt(contract.sum_wovat_value.toString())),
      sum_vat_value: contract.sum_vat_value && new Intl.NumberFormat().format(parseInt(contract.sum_vat_value.toString()))
    }
  })
}

export const preparedContractsList = (data: iContract[]) => {
  return reformattingPrices(data).map((contract) => {
    const sum_empty_value = contract.contractors_offer_price === null
      ?
      Object.values(contract).reduce((acc: number, value) => {
        return value === "" || value === null ? acc + 1 : acc;
      }, 0) + 1
      :
      Object.values(contract).reduce((acc: number, value) => {
        return value === "" || value === null ? acc + 1 : acc;
      }, 0)
    return {...contract, sum_empty_value};
  });
}