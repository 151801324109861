import reducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

const store = configureStore({
    reducer: reducer
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof reducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>

export default store;
