import React, { useEffect } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import LINK from "../../utils/LINK"
import { DATE_FORMAT_DAY } from "../../utils/utils"
import { iContract } from "../../api/intrefaces"
import AlertError from "../Alerts/AlertError"
import Loading from "../Loading/Loading"
import AlertInfo from "../Alerts/AlertInfo"
import TableBox from "../ContentBox/TableBox"
import { useFilterContractsGlobal } from "../../hooks/useFilterContractsGlobal";

const ContractsListFiltered = () => {
  const { contractsList, error, loading, setInitFetch } =
      useFilterContractsGlobal()

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])
  if (error) {
    return <AlertError msg={error} />
  }
  if (loading) {
    return <Loading />
  }
  if (!contractsList || contractsList.length === 0) {
    return <AlertInfo msg="Žádné zakázky" />
  }
  return (
    <>
      <TableBox>
        <thead>
          <tr>
            <th>Datum zahájení VZ</th>
            <th>Číslo profilu</th>
            <th>Název VZ</th>
            <th>Zadavatel</th>
            <th>Druh VZ</th>
            <th>Druh ZŘ</th>
            <th>Počet nabídek</th>
          </tr>
        </thead>
        <tbody>
          {contractsList.map((contract: iContract, index: number) => (
            <tr key={index}>
              <td>{moment(contract.date_start).format(DATE_FORMAT_DAY)}</td>
              <td>{contract.system_number}</td>
              <td>
                <Link to={`${LINK.contractDetail}/${contract.id_contract}`}>
                  {contract.contract_name}
                </Link>
              </td>
              <td>{contract.company_name}</td>
              <td>{contract.code_otype}</td>
              <td>{contract.name_procedure}</td>
              <td>{contract.offer_count}</td>
            </tr>
          ))}
        </tbody>
      </TableBox>
    </>
  )
}

export default ContractsListFiltered
