import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import ContentBox from "../../components/ContentBox/ContentBox";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

const LegislativaPage = () => {

  return (
    <PageWrap title="Legislativa"
              breadcrumbs_items={[
                {label: "Legislativa"},
              ]}>

      <ContentBox>
        <h2 className="text-primary">
          Fáze zadávacího řízení
        </h2>

        <p>
          Každá veřejná zakázka prochází v rámci zadávacího řízení různými stavy –fázemi zadávacího řízení. V závislosti
          na
          těchto fázích a druhu zadávacího řízení jsou možné v systémech QCM různé akce a úkony. Jejich přehledy pohledu
          zadavatele/administrátora VZ poskytuje následující tabulka. Sled a přítomnost jednotlivých fází závisí na
          druhu
          zadávacího řízení. Při založení nové VZ je jí nastavena fáze "Příprava". O aktuální fázi zadávacího řízení a
          možných akcích informuje v detailu VZ.
        </p>

        <Card className="border-0 shadow-sm my-4">
          <Table striped bordered size="sm" className="mb-0">
            <thead>
            <tr>
              <th>Název fáze [druh zadávacího řízení]</th>
              <th>Význam fáze</th>
              <th>Možné akce v rámci E-ZAK (zadavatel/admin)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                Příprava
                <br/>
                [VŠECHNY]
              </td>
              <td>
                přípravné stádium veřejné zakázky, veškeré údaje jsou dostupné pouze pověřeným osobám a lze je všechny
                editovat
              </td>
              <td>
                v této fázi je možné měnit všechny parametry VZ (kromě rozdělení na části) a lze ji také ze systému
                vymazat
                <br/>
                dle druhu zadávacího řízení lze odeslat výzvu k podání nabídek, resp. žádostí o účast, nebo oznámení
                (uveřejňovací formulář do VVZ)
                <br/>
                přechod do další fáze je automatický –odesláním výzvy, oznámení nebo při manuálním zahájení po nastavení
                oprávnění na VZ
              </td>
            </tr>

            <tr>
              <td>
                Zahájena
                <br/>
                [OŘ-otevřené řízení, UŘ-uzší řízení, JŘSU-O –jednací řízení s uveřejněním zahájené oznámením, SD
                –soutěžní
                dialog, SON –soutěž o návrh, ZR –zjednodušený režim, KŘ-koncesní řízení]
              </td>
              <td>
                do této fáze se posune zakázka zahajovaná oznámením do VVZ, VZ setrvává v této fázi do uveřejnění
                oznámení
                <br/>
                pokud nedojde k automatickému posunu, zadavatel si sám musí pohlídat ruční posunutí do další fáze a
                uveřejnění VZ
              </td>
              <td>
                v případě odeslání formuláře do VVZ je nutné zadat získané evidenční číslo VZ a opravný kód pro
                formulář,
                pokud to systém neprovede automaticky
                <br/>
                přechod do fáze Prokazování kvalifikace, resp. Příjem nabídek (dle druhu zadávacího řízení) je v případě
                odeslání výzvy automatický, u odeslání formuláře je nutné zadat získané evidenční číslo VZ a opravný kód
                pro
                formulář a následně formulář vystavit na profilu zadavatele, pokud to systém neprovede automaticky
              </td>
            </tr>

            <tr>
              <td>
                Prokazování kvalifikace
                <br/>
                [UŘ, JŘSU-O, SD, USON –užší soutěž o návrh, KŘ-koncesní řízení]
              </td>
              <td>
                VZ je vystavena na profilu zadavatele
                <br/>
                dodavatelé podávají žádosti o účast v listinné podobě na kontaktní adresu nebo elektronicky přes E-ZAK,
                prokazují svou kvalifikaci
                <br/>
                fáze je časově limitována datem pro podání žádostí o účast
              </td>
              <td>
                odpovídat na vysvětlení, doplnění, změna dokumentace
                <br/>
                uveřejnit vysvětlení, doplnění, změna dokumentace
                <br/>
                odpovídat na žádosti o poskytnutí dokumentace, pokud je na vyžádání
                <br/>
                přiřazení dodavatelů žádajících o účast
                <br/>
                přechod do fáze Hodnocení kvalifikace je automatický a závislý na uplynutí lhůty pro podání žádostí o
                účast
              </td>
            </tr>

            <tr>
              <td>
                Hodnocení kvalifikace
                <br/>
                [UŘ, JŘSU-O, SD, USON, KŘ]
              </td>
              <td>
                zadavatel hodnotí žádosti o účast, posuzuje kvalifikaci zájemců
              </td>
              <td>
                nastavit lhůtu k podání nabídek resp. datum prvního jednání
                <br/>
                přiřazení a vyloučení dodavatelů
                <br/>
                nastavení zadávací dokumentace
                <br/>
                odeslat výzvu k podání nabídek resp. k účasti v řízení
                <br/>
                přechod do fáze Příjem nabídek je automatický a závislý na akci odeslání výzvy
                <br/>
                možnost elektronické komunikace s dodavateli
              </td>
            </tr>


            <tr>
              <td>
                Příjem nabídek
                <br/>
                [VŠECHNY]
                <br/>
                Příjem předběžných nabídek [JŘSU, KŘ]
              </td>
              <td>
                VZ je vystavena na profilu zadavatele
                <br/>
                dodavatelé podávají nabídky elektronicky přes E-ZAK (výjimečně v listinné podobě)
              </td>
              <td>
                odpovídat na žádosti o vysvětlení, doplnění, změna dokumentace
                <br/>
                uveřejnit vysvětlení, doplnění, změna dokumentace
                <br/>
                odpovídat na žádosti o poskytnutí zadávací dokumentace, pokud je na vyžádání
                <br/>
                přiřazení dodavatelů
                <br/>
                přechod do fáze V jednání, resp. Hodnocení (dle druhu zadávacího řízení) je automatický a závislý na uplynutí lhůty pro podání nabídek resp. prvního jednání
              </td>
            </tr>


            <tr>
              <td>
                V jednání
                <br/>
                [JŘBU, JŘSU, SD, ZR_PO –zjednodušený režim s předběžným oznámením, KŘ]
              </td>
              <td>
                zadavatel jedná s účastníky
              </td>
              <td>
                manuální přechod do další fáze
                <br/>
                možnost elektronické komunikace s dodavateli
              </td>
            </tr>


            <tr>
              <td>
                Projevení předběžného zájmu
                <br/>
                [JŘSU_PO, UŘ_PO , ZR_PO]
              </td>
              <td>
                zadavatel vyzývá k projevení předběžného zájmu účastníky, které pak vyzve k podání žádosti o účast/jednání
              </td>
              <td>
                nastavit lhůtu pro žádosti o účast
                <br/>
                přiřazení a vyloučení dodavatelů
                <br/>
                možnost el. komunikace
                <br/>
                přechod do fáze Výzva k podání žádosti o účast/ V jednání
              </td>
            </tr>


            <tr>
              <td>
                Výzva k podání žádostí o účast
                <br/>
                [JŘSU_PO, UŘ_PO]
              </td>
              <td>
                zadavatel připravuje výzvu k podání žádostí o účast dodavatelům, kteří projevili předběžný zájem.
              </td>
              <td>
                možnost elektronické komunikace s dodavateli
                <br/>
                přechod do fáze Prokazování kvalifikace
                <br/>
                přiřazení a vyloučení dodavatelů
              </td>
            </tr>


            <tr>
              <td>
                Příjem návrhů
                <br/>
                [USON, OSON –otevřená soutěž o návrh]
              </td>
              <td>
                dodavatelé mohou podávat návrhy (dle typu soutěže všichni, nebo pouze vyzvaní)
              </td>
              <td>
                přechod do fáze Vyhodnocování
                <br/>
                uveřejnit zadávací dokumentaci –soutěžní podmínky
                <br/>
                odpovídat na žádosti o vysvětlení zadávací dokumentace
                <br/>
                přiřazení dodavatelů
                <br/>
                možnost elektronické komunikace s dodavateli
              </td>
            </tr>


            <tr>
              <td>
                Hodnocení
                <br/>
                [VŠECHNY]
              </td>
              <td>
                zadavatel hodnotí, případně posuzuje nabídky
              </td>
              <td>
                přiřazení a vyloučení dodavatelů
                <br/>
                stanovení pořadí jednotlivých účastníků, resp. posouzení a hodnocení nabídek
                <br/>
                případně elektronická aukce
                <br/>
                manuální přechod do další fáze se současným potvrzením finálního
                <br/>
                pořadí hodnocených nabídek (po uplynutí lhůty pro námitky)
                <br/>
                možnost elektronické komunikace s dodavateli

              </td>
            </tr>


            <tr>
              <td>
                Vyhodnoceno
                <br/>
                [VŠECHNY]
              </td>
              <td>
                zadavatel jedná o uzavření smlouvy
              </td>
              <td>
                formulář Oznámení o zadání zakázky
                <br/>
                možnost vyloučení dodavatelů
                <br/>
                manuální přechod do další fáze se současným označením dodavatele, s nímž byla uzavřena smlouva
                <br/>
                možnost elektronické komunikace s dodavateli
              </td>
            </tr>


            <tr>
              <td>
                Zadáno
                <br/>
                [VŠECHNY]
              </td>
              <td>
                veřejná zakázka byla zadána
              </td>
              <td>
                formulář Oznámení o zadání zakázky, je-li zákonem vyžadován
                <br/>
                možnost elektronické komunikace s dodavateli
              </td>
            </tr>


            <tr>
              <td>
                Zrušeno
                <br/>
                [VŠECHNY]
              </td>
              <td>
                veřejná zakázka byla zrušena
              </td>
              <td>
                formulář Oznámení o zrušení zakázky, je-li zákonem vyžadován
                <br/>
                možnost elektronické komunikace s dodavateli
              </td>
            </tr>


            </tbody>
          </Table>
        </Card>

        <p>
          V každé fázi lze k zadávacímu řízení připojit související dokumenty ať již interní nebo veřejné povahy.Při
          automatickém přechodu mezi fázemi není nabízena akce pro posun do další fáze. Toto chování lze změnit
          nastavením
          oprávnění „měnit stav veřejné zakázky manuálně“ -poté bude akce pro přechod do následující fáze nabídnuta v
          rámečku fáze zadávacího řízení. Doporučujeme však toto oprávnění využívat v co možná nejmenší míře. Toto
          oprávnění
          je z bezpečnostních důvodů při každé změně fáze resetováno.
        </p>

      </ContentBox>
    </PageWrap>
  );
}

export default LegislativaPage;