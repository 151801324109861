import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap";
import LINK from "../../utils/LINK";
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats";
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { FilterContextProvider } from "../../context/FilterContext";
import FilterOrder from "../../components/Filters/FilterOrder";
import Row from "react-bootstrap/Row";
import Paginator from "../../components/Paginator/Paginator";
import WinContractorsList from "../../components/WinnersList/WinContractorsList";
import BarContractors from "../../components/ChartsNivo/BarContractors";
import {STAT_FILTER_TYPE} from "../../components/Stats/FilterStat";

const StatsContractorsPage = () => {

  return (
    <PageWrap
        title="Dodavatelé - součty uhrazených cen"
        breadcrumbs_items={[
          {label: "Analýzy", link: LINK.stats},
          {label: "Dodavatelé - součty uhrazených cen"},
        ]}>
      <FilterContextProvider
        name={PAGE_TYPES.statsContractors}
      >
        <FilterBoxStats selectCompanies/>

        <BarContractors filterName={STAT_FILTER_TYPE.contractor} priceChart/>

        <Row className="mb-2 justify-content-end flex-order">
          <FilterOrder maximWidth={420}
             orderOptions={[
               {label: "Název dodavatele (A-Z)", value: "name ASC"},
               {label: "Název dodavatele (Z-A)", value: "name DESC"},
               {label: "IČO - vzestupně", value: "ic ASC"},
               {label: "IČO - sestupně", value: "ic DESC"},
               {label: "Vysoutěžené VZ s nabídkovou cenou bez DPH - vzestupně", value: "count_offer_price_wovat ASC"},
               {label: "Vysoutěžené VZ s nabídkovou cenou bez DPH - sestupně", value: "count_offer_price_wovat DESC"},
               {label: "Celkový počet zakázek s nabídkovou cenou bez DPH - vzestupně", value: "total_contracts_with_offer_price_wovat ASC"},
               {label: "Celkový počet zakázek s nabídkovou cenou bez DPH - sestupně", value: "total_contracts_with_offer_price_wovat DESC"},
               {label: "Součet smluvní ceny v Kč bez DPH - vzestupně", value: "count_contract_wovat_value ASC"},
               {label: "Součet smluvní ceny v Kč bez DPH - sestupně", value: "count_contract_wovat_value DESC"},
               {label: "Vysoutěžené VZ se smluvní cenou bez DPH - vzestupně", value: "total_contracts_with_wovat_value ASC"},
               {label: "Vysoutěžené VZ se smluvní cenou bez DPH - sestupně", value: "total_contracts_with_wovat_value DESC"},
               {label: "Součet uhrazené ceny v Kč bez DPH - vzestupně", value: "count_wovat_value ASC"},
               {label: "Součet uhrazené ceny v Kč bez DPH - sestupně", value: "count_wovat_value DESC"},
               {label: "Vysoutěžené VZ s uhrazenou cenou bez DPH - vzestupně", value: "total_wovat_value ASC"},
               {label: "Vysoutěžené VZ s uhrazenou cenou bez DPH - sestupně", value: "total_wovat_value DESC"},
               {label: "Celkový počet vysoutěžených VZ - vzestupně", value: "count_winner ASC"},
               {label: "Celkový počet vysoutěžených VZ - sestupně", value: "count_winner DESC"},
             ]}
          />
        </Row>

        <WinContractorsList />

        <Paginator />
      </FilterContextProvider>
    </PageWrap>
  );
}

export default StatsContractorsPage;