import React from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { setModalGlobalIndicatorsOpen } from "../../redux/global/globalSlice";
import { useDispatch } from "react-redux";

const GlobalIndicatorsQuestionMark = () => {
  const dispatch = useDispatch()
  const handleShow = () => dispatch(setModalGlobalIndicatorsOpen(true))

  return (
    <>
      <FaRegQuestionCircle size={12} color="black" onClick={handleShow} style={{ cursor: "pointer", marginLeft: 5 }}/>
    </>
  );
}

export default GlobalIndicatorsQuestionMark;