import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal"
import { useDispatch, useSelector } from "react-redux";
import { selectModalCpvCodesOpen, setModalCpvCodesOpen } from "../../redux/global/globalSlice";
import Treelist from "../Treelist/Treelist";
import PublicApi from "../../api/PublicApi";
import {Button} from "react-bootstrap";
import {selectCpvCodes, setCpvCodes, setUncheckCpvCodes} from "../../redux/filters/filtersSlice";

const ModalCpvCodes = () => {

  const dispatch = useDispatch()
  const open = useSelector(selectModalCpvCodesOpen)
  const [data, setData] = useState(undefined);
  const selectCpv = useSelector(selectCpvCodes)

  useEffect(() => {
    if (open && !data) {
      PublicApi.getCpvStruct().then((data) => {
        setData(data as any);
      });
    }
  }, [open, data]);

  const handleClose = () => {
    dispatch(setModalCpvCodesOpen(false))
  }

  const handleReset = () => {
    dispatch(setUncheckCpvCodes(true))
    dispatch(setCpvCodes([]))
  }

  return (
    <Modal show={open} onHide={handleClose} size="lg" scrollable={true}>

      <Modal.Header closeButton>
        <Modal.Title>CPV kódy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data && <Treelist nodes={data}/>}
        {selectCpv.length > 0 &&
          <>
            <hr/>
            <h5>Vybrané CPV kódy</h5>
          </>
        }
        <p>{selectCpv.map((cpv, index, selectCpv) =>
          <span key={index}>
            {cpv}
            {index < selectCpv.length - 1 ? ', ' : '.'}
          </span>
        )}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary"
                onClick={() => handleReset()}
        >
          Odebrat vše
        </Button>
        <Button
          onClick={() => dispatch(setModalCpvCodesOpen(false))}
        >
          Vybrat
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCpvCodes;