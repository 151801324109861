import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContentBox from "../../components/ContentBox/ContentBox";
import {Link} from "react-router-dom";
import LINK from "../../utils/LINK";
import {FaTable} from "react-icons/fa";

const AuditPage = () => {

  return (
    <PageWrap title="Audit"
              breadcrumbs_items={[
                { label: "Audit" },
              ]}>
      <Row>
        <Col sm={4}>
          <Link to={LINK.qualitativeAnalysis}>
            <ContentBox>
              <FaTable size={35} className="me-2 mb-2"/>
              QIndex - kvalitativní analýza dat VZ
            </ContentBox>
          </Link>
        </Col>

        <Col sm={4}>
          <Link to={LINK.contractors}>
            <ContentBox>
              <FaTable size={35} className="me-2 mb-2"/>
              Dodavatelé - účast a počet vysoutěžených VZ
            </ContentBox>
          </Link>
        </Col>
      </Row>
    </PageWrap>
  );
}

export default AuditPage;