import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const slice = createSlice({
  name: "global",
  initialState: {
    modalOpen: false,
    modalGlobalIndicatorsOpen: false,
    modalCpvCodesOpen: false
  },
  reducers: {
    setModalOpen(state, action: PayloadAction<boolean>) {
      state.modalOpen = action.payload
    },
    setModalGlobalIndicatorsOpen(state, action: PayloadAction<boolean>) {
      state.modalGlobalIndicatorsOpen = action.payload
    },
    setModalCpvCodesOpen(state, action: PayloadAction<boolean>) {
      state.modalCpvCodesOpen = action.payload
    }
  }
});


export const selectModalOpen = (state: RootState) => state.global.modalOpen
export const selectModalGlobalIndicatorsOpen = (state: RootState) => state.global.modalGlobalIndicatorsOpen
export const selectModalCpvCodesOpen = (state: RootState) => state.global.modalCpvCodesOpen

export const { setModalOpen, setModalGlobalIndicatorsOpen, setModalCpvCodesOpen } = slice.actions;
export default slice.reducer;
