import React from "react";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
import { ResponsiveLine } from '@nivo/line'

const data = [
  {
    "id": "japan",
    "color": "hsl(125, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 16
      },
      {
        "x": "helicopter",
        "y": 156
      },
      {
        "x": "boat",
        "y": 63
      },
      {
        "x": "train",
        "y": 63
      },
      {
        "x": "subway",
        "y": 138
      },
      {
        "x": "bus",
        "y": 113
      },
      {
        "x": "car",
        "y": 28
      },
      {
        "x": "moto",
        "y": 78
      },
      {
        "x": "bicycle",
        "y": 103
      },
      {
        "x": "horse",
        "y": 249
      },
      {
        "x": "skateboard",
        "y": 243
      },
      {
        "x": "others",
        "y": 113
      }
    ]
  },
  {
    "id": "france",
    "color": "hsl(238, 70%, 50%)",
    "data": [
      {
        "x": "plane",
        "y": 178
      },
      {
        "x": "helicopter",
        "y": 127
      },
      {
        "x": "boat",
        "y": 200
      },
      {
        "x": "train",
        "y": 144
      },
      {
        "x": "subway",
        "y": 143
      },
      {
        "x": "bus",
        "y": 216
      },
      {
        "x": "car",
        "y": 25
      },
      {
        "x": "moto",
        "y": 151
      },
      {
        "x": "bicycle",
        "y": 130
      },
      {
        "x": "horse",
        "y": 42
      },
      {
        "x": "skateboard",
        "y": 10
      },
      {
        "x": "others",
        "y": 8
      }
    ]
  },
  // {
  //   "id": "us",
  //   "color": "hsl(260, 70%, 50%)",
  //   "data": [
  //     {
  //       "x": "plane",
  //       "y": 255
  //     },
  //     {
  //       "x": "helicopter",
  //       "y": 251
  //     },
  //     {
  //       "x": "boat",
  //       "y": 73
  //     },
  //     {
  //       "x": "train",
  //       "y": 281
  //     },
  //     {
  //       "x": "subway",
  //       "y": 18
  //     },
  //     {
  //       "x": "bus",
  //       "y": 272
  //     },
  //     {
  //       "x": "car",
  //       "y": 207
  //     },
  //     {
  //       "x": "moto",
  //       "y": 271
  //     },
  //     {
  //       "x": "bicycle",
  //       "y": 204
  //     },
  //     {
  //       "x": "horse",
  //       "y": 272
  //     },
  //     {
  //       "x": "skateboard",
  //       "y": 53
  //     },
  //     {
  //       "x": "others",
  //       "y": 167
  //     }
  //   ]
  // },
  // {
  //   "id": "germany",
  //   "color": "hsl(299, 70%, 50%)",
  //   "data": [
  //     {
  //       "x": "plane",
  //       "y": 50
  //     },
  //     {
  //       "x": "helicopter",
  //       "y": 162
  //     },
  //     {
  //       "x": "boat",
  //       "y": 58
  //     },
  //     {
  //       "x": "train",
  //       "y": 111
  //     },
  //     {
  //       "x": "subway",
  //       "y": 61
  //     },
  //     {
  //       "x": "bus",
  //       "y": 72
  //     },
  //     {
  //       "x": "car",
  //       "y": 199
  //     },
  //     {
  //       "x": "moto",
  //       "y": 230
  //     },
  //     {
  //       "x": "bicycle",
  //       "y": 42
  //     },
  //     {
  //       "x": "horse",
  //       "y": 158
  //     },
  //     {
  //       "x": "skateboard",
  //       "y": 13
  //     },
  //     {
  //       "x": "others",
  //       "y": 81
  //     }
  //   ]
  // },
  // {
  //   "id": "norway",
  //   "color": "hsl(320, 70%, 50%)",
  //   "data": [
  //     {
  //       "x": "plane",
  //       "y": 94
  //     },
  //     {
  //       "x": "helicopter",
  //       "y": 110
  //     },
  //     {
  //       "x": "boat",
  //       "y": 33
  //     },
  //     {
  //       "x": "train",
  //       "y": 162
  //     },
  //     {
  //       "x": "subway",
  //       "y": 283
  //     },
  //     {
  //       "x": "bus",
  //       "y": 47
  //     },
  //     {
  //       "x": "car",
  //       "y": 256
  //     },
  //     {
  //       "x": "moto",
  //       "y": 182
  //     },
  //     {
  //       "x": "bicycle",
  //       "y": 168
  //     },
  //     {
  //       "x": "horse",
  //       "y": 130
  //     },
  //     {
  //       "x": "skateboard",
  //       "y": 111
  //     },
  //     {
  //       "x": "others",
  //       "y": 122
  //     }
  //   ]
  // }
]

const NivoLines = () => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    // axisBottom={{
    //   orient: 'bottom',
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'transportation',
    //   legendOffset: 36,
    //   legendPosition: 'middle'
    // }}
    // axisLeft={{
    //   orient: 'left',
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'count',
    //   legendOffset: -40,
    //   legendPosition: 'middle'
    // }}
    colors={{ scheme: 'category10' }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
)

export default NivoLines;