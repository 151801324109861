// React components
import TableModal from "../../../components/TableModal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

// Zod
import { z } from "zod"

// React Hook Form
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"

type EditUserModalProps = {
  handleClose: () => void
}

const EditUserModal = ({ handleClose }: EditUserModalProps) => {
  const requiredField = "Toto pole je povinné"
  const invalidEmailFormat = "Nesprávný formát e-mailu"

  const formSchema = z.object({
    user: z.string().min(1, requiredField),
    email: z.string().email(invalidEmailFormat).min(1, requiredField),
    username: z.string().min(1, requiredField),
    role: z.string().min(1, requiredField),
    dataWarehouse: z.string().min(1, requiredField),
    organizationalUnit: z.string().min(1, requiredField),
  })

  type FormSchemaType = z.infer<typeof formSchema>

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    values: {
      user: "Jan Novák",
      email: "jan@aaa.cz",
      username: "honza",
      role: "Manažer",
      dataWarehouse: "MZE",
      organizationalUnit: "UKZUZ",
    },
  })

  const onSubmit: SubmitHandler<FormSchemaType> = data => {
    console.log("DATA", data)
  }

  return (
    <TableModal handleClose={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
        <h3 className="mb-3">Editace uživatele</h3>

        <Form.Group className="mb-2" controlId="user">
          <Form.Label>Uživatel</Form.Label>
          <Form.Control
            type="text"
            style={{ borderWidth: 2.3 }}
            {...register("user")}
          />
          <Form.Text className="text-danger">
            {errors.user && errors.user?.message}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-2" controlId="email">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            type="text"
            style={{ borderWidth: 2.3 }}
            {...register("email")}
          />
          <Form.Text className="text-danger">
            {errors.email && errors.email?.message}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-2" controlId="username">
          <Form.Label>Přihlašovací jméno</Form.Label>
          <Form.Control
            type="text"
            style={{ borderWidth: 2.3 }}
            {...register("username")}
          />
          <Form.Text className="text-danger">
            {errors.username && errors.username?.message}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-2" controlId="role">
          <Form.Label>Role</Form.Label>
          <Form.Control
            type="text"
            style={{ borderWidth: 2.3 }}
            {...register("role")}
          />
          <Form.Text className="text-danger">
            {errors.role && errors.role?.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-2" controlId="dataWarehouse">
          <Form.Label>Datový sklad VZ</Form.Label>
          <Form.Control
            type="text"
            style={{ borderWidth: 2.3 }}
            {...register("dataWarehouse")}
          />
          <Form.Text className="text-danger">
            {errors.dataWarehouse && errors.dataWarehouse?.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-2" controlId="organizationalUnit">
          <Form.Label>Organizační jednotka</Form.Label>
          <Form.Control
            type="text"
            style={{ borderWidth: 2.3 }}
            {...register("organizationalUnit")}
          />
          <Form.Text className="text-danger">
            {errors.organizationalUnit && errors.organizationalUnit?.message}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mt-4">
          <Button
            type="submit"
            className={"btn-block"}
            style={{ padding: "12px 10px" }}
          >
            Upravit
          </Button>
        </Form.Group>
      </Form>
    </TableModal>
  )
}

export default EditUserModal
