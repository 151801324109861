import React, { useEffect, useState } from "react";
import { iContract } from "../../api/intrefaces";
import ContractBox from "../ContractBox/ContractBox";
import AlertError from "../Alerts/AlertError";
import Loading from "../Loading/Loading";
import AlertInfo from "../Alerts/AlertInfo";
import { useFilterContractsAll } from "../../hooks/useFilterContractsAll";

const ContractsListRunning = () => {
  const [lastHeight, setLastHeight] = useState(3970);
  const { contractsList, error, loading, setInitFetch } =
    useFilterContractsAll();

  useEffect(() => {
    setInitFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function _updateLastHeightDynamically() {
    const tBody = document.getElementById("contract-list-id");
    if (tBody && tBody.offsetHeight !== lastHeight) {
      return setLastHeight(tBody.offsetHeight);
    }
  }

  function _updateLastHeight(newHeight: number) {
    if (lastHeight !== newHeight) {
      window.scrollTo(1, 1);
      setLastHeight(newHeight);
    }
  }

  if (loading) {
    return <Loading height={lastHeight} />;
  }
  if (error) {
    return <AlertError msg={error} />;
  }

  if (!contractsList.length) {
    _updateLastHeight(55);
    return <AlertInfo msg="Žádná data" />;
  } else {
    _updateLastHeightDynamically();
  }

  return (
    <div id="contract-list-id">
      {contractsList.map((contract: iContract, index: number) => (
        <ContractBox
          key={contract.id_contract + "-" + index}
          id={contract.id_contract}
          onList
        />
      ))}
    </div>
  );
};

export default ContractsListRunning;
