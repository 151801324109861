import * as React from "react"
import { useEffect, useState } from "react"
import { Redirect, RouteComponentProps } from "react-router-dom"
import type { RouteProps } from "react-router-dom"
import { Route } from "react-router-dom"
import Loading from "../Loading/Loading"
import LINK from "../../utils/LINK"
import { useSelector } from "react-redux"
import { selectIsLoged } from "../../redux/user/selectors"

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
  redirect?: string
}

export function PrivateRoute({
  component: Component,
  redirect,
  ...rest
}: PrivateRouteParams) {
  const [loading, setLoading] = useState(true)

  const isLoged = useSelector(selectIsLoged)

  // todo login from store
  // useEffect(() => {
  //   const storetokens = TokenStore.getTokens()
  //   if (initialized) {
  //     if (storetokens && !keycloak.authenticated) {
  //       keycloak.login()
  //     } else {
  //       setLoading(false)
  //     }
  //   }
  // }, [keycloak, initialized])

  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoged) {
          if (redirect) {
            return (
              <Redirect
                to={{
                  pathname: redirect,
                  state: { from: props.location },
                }}
              />
            )
          } else {
            return <Component {...props} />
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: LINK.login,
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}
