import { setLoadingStart, setData, setError } from "./eventsSlice"
import { AppThunk } from "../store";
import ServerApi from "../../api/ServerApi";
import {setTotalCounts} from "../filters/filtersSlice";

export const fetchEvents = (filters: any, contextName: string): AppThunk => async dispatch => {
  dispatch(setLoadingStart());
  try {
    const response = await ServerApi.getContractActionsByCriteria(filters);
    dispatch(setData(response.data));
    if (response.totalCount !== undefined) {
      dispatch(setTotalCounts({ name: contextName || "", value: response.totalCount }))
    }
  } catch (err) {
    console.error("ERR FETCH events", err);
    const errMsg = err.response?.data?.error?.description || err.toString()
    dispatch(setError(errMsg));
  }
};