import React from "react"
import ContentBox from "../../ContentBox/ContentBox"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { ENUM_ID, OPTIONS } from "../../../utils/OPTIONS"
import FilterBtns from "../FilterBtns"
import FilterSelectFromTo from "../Select/FilterSelectFromTo"
import FilterSelectDepartments from "../Select/FilterSelectDepartments"
import FilterSelectEnums from "../Select/FilterSelectEnums"
import FilterSelectEnumsStatic from "../Select/FilterSelectEnumsStatic";
import FilterDateStart from "../Date/FilterDateStart"
import FilterSelectCompanies from "../Select/FilterSelectCompanies"
import FilterSearchContracts from "../Search/FilterSearchContracts"
import { FormCheck, FormLabel } from "react-bootstrap"
import contractCodeProceduresGrouped from "./contractProceduresGlobalIndicatorsGrouped.json";
import contractCodeStatesGrouped from "./contractStatesGlobalIndicatorsGrouped.json";
import {useFilterContext, useFilterContextInput} from "../../../context/FilterContext";
import { ENV } from '../../../ENV'
import FilterSearchCpv from "../Search/FilterSearchCpv";

const popoverText =
  "QIndex vyhodnocuje kvalitu VZ na základě metadat."

const FilterBoxContractsList = () => {
  const {openFilter} = useFilterContext()

  const { value, setVal } = useFilterContextInput("atLeastOneWord")
  const { value: val, setVal: setValue } = useFilterContextInput("contractsWithoutStartDate")

  function toggleOnlyWon(e: any) {
    const { checked } = e.target
    setVal(checked)
  }

  function toggleWithoutStartDate(e: any) {
    const { checked } = e.target
    setValue(checked)
  }

  if (!openFilter) {
    return null
  }

  return (
    <ContentBox>
      <Row>
        <Col sm={6}>
          <FilterSelectCompanies />

          <FilterSelectEnums
              title="Místo plnění"
              name="nutsCodeIds"
              enumId={ENUM_ID.nutsCodeId}
          />

          <FilterSelectEnums
            title="Režim VZ"
            name="typeCodes"
            enumId={ENUM_ID.type}
          />

          {ENV.NEN ? <FilterSelectEnums
            title="Fáze ZŘ"
            name="stateCodes"
            enumId={ENUM_ID.state}
          />
          :
          <FilterSelectEnumsStatic
            title="Fáze ZŘ"
            name="stateCodes"
            multiple={true}
            options={contractCodeStatesGrouped}
          />}

          {!ENV.NEN && <FilterSelectFromTo
              title={"QIndex"}
              nameFrom="ratingFrom"
              nameTo="ratingTo"
              options={OPTIONS.stars}
              popoverText={popoverText}
          />}
        </Col>
        <Col sm={6}>
          <FilterSelectDepartments />

          <FilterSelectEnums
            title="Druh VZ"
            name="otypeCodes"
            enumId={ENUM_ID.otype}
          />

          {ENV.NEN ? <FilterSelectEnums
            title="Druh ZŘ"
            name="procedureCodes"
            enumId={ENUM_ID.procedure}
          />
          :
          <FilterSelectEnumsStatic
            title="Druh ZŘ"
            name="procedureCodes"
            multiple={true}
            options={contractCodeProceduresGrouped}
          />}
          <FilterSearchCpv />
          <FilterDateStart />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2" sm={{ span: 2, offset: 2 }}>
          <FilterSearchContracts />
        </Col>
        <Col
          className="justify-content-end d-flex align-items-center my-2 my-sm-0"
          sm={{ span: 2 }}
        >
          <FormLabel className="mb-0 mx-2" htmlFor="onlyWonCheck">
            Alespoň jedno slovo
          </FormLabel>
          <FormCheck
            onChange={toggleOnlyWon}
            type="checkbox"
            checked={value}
            id="onlyWonCheck"
            size={10}
          />
        </Col>
        <Col
            className="justify-content-end d-flex align-items-center my-2 my-sm-0"
            sm={{ span: 6 }}
        >
          <FormLabel className="mb-0 mx-2" htmlFor="alsoWithoutStartDate">
            Zahrnout VZ bez data zahájení
          </FormLabel>
          <FormCheck
              onChange={toggleWithoutStartDate}
              type="checkbox"
              checked={val}
              id="alsoWithoutStartDate"
              size={10}
          />
        </Col>
      </Row>
      <Row>
        <Col
            className="justify-content-end d-flex align-items-center my-2 my-sm-0"
            sm={{ span: 6 }}
        >
        </Col>
        <Col>
          <FilterBtns />
        </Col>
      </Row>
    </ContentBox>
  )
}

export default FilterBoxContractsList
