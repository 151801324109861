import { useEffect } from "react";
import ServerApi from "../api/ServerApi";
import useFetch from "./useFetch";
import moment from "moment";
import {useFilterContext} from "../context/FilterContext";

export const useEventsTimeline = () => {

  const dateAktual: string = moment().format("YYYY-MM-DD")
  const {activeFilters} = useFilterContext()

  const {data, error, loading, fetch} = useFetch(() =>
    ServerApi.getContractActionsTimeline({
      ...activeFilters,
      count: 10,
      date: dateAktual
    })
  )

  useEffect(() => {
          fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters])

  return { data, error, loading }
}

