import React, { useEffect } from "react"
import { useFilterContractsStats } from "../../hooks/useFilterContractsStats"
import Loading from "../Loading/Loading"
import AlertError from "../Alerts/AlertError"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ContentBox from "../ContentBox/ContentBox"
import PieContracts from "../ChartsNivo/PieContracts"
import BarContracts from "../ChartsNivo/BarContracts"
import { prepareStats } from "../../utils/prepareStats"
import FilterStat, { STAT_FILTER_TYPE } from "./FilterStat"
import { useFilterContractsStatsAll } from "../../hooks/useFilterContractsStatsAll"
import ExportPDFButton from "../ExportPDFButton/ExportPDFButton"

const StatContractsPrice = () => {
  const { stats, error, loading, setInitFetch } = useFilterContractsStats()

  const { data: allStatsData } = useFilterContractsStatsAll()

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])

  // if (loading) {
  //   return <Loading/>
  // }
  if (error) {
    return <AlertError msg={error} />
  }

  const {
    data,
    sum: sumPrice,
    percent,
  } = prepareStats(stats.sum_kc, allStatsData.sum_kc)

  return (
    <>
      <div className="sticky-top">
        <FilterStat />
        {loading && <Loading />}
      </div>
      <Row>
        <Col>
          <ContentBox title="Hodnota zakázek dle typu zakázky">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("contractsPricePerType")}
                graphName="Hodnota zakázek dle typu zakázky"
                filter="type"
                numberOfContracts={`Hodnota zakázek: ${sumPrice.type} Kč /${percent.type}%`}
              />
              <div id="contractsPricePerType" style={{ height: 450 }}>
                <PieContracts
                  data={data.type}
                  filterName={STAT_FILTER_TYPE.type}
                  priceChart
                />
              </div>
            </div>
            <div className={"text-end"}>
              Hodnota zakázek: {sumPrice.type} Kč /{percent.type}%
            </div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Hodnota zakázek dle druhu zakázky">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("contractsPricePerKind")}
                graphName="Hodnota zakázek dle druhu zakázky"
                filter="otype"
                numberOfContracts={`Hodnota zakázek: ${sumPrice.otype} Kč /${percent.otype}%`}
              />
              <div id="contractsPricePerKind" style={{ height: 450 }}>
                <PieContracts
                  data={data.otype}
                  filterName={STAT_FILTER_TYPE.otype}
                  priceChart
                />
              </div>
            </div>

            <div className={"text-end"}>
              Hodnota zakázek: {sumPrice.otype} Kč /{percent.otype}%
            </div>
          </ContentBox>
        </Col>
      </Row>

      <Row>
        <Col>
          <ContentBox title="Hodnota zakázek dle druhu zadávacího řízení (Top 10)">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById(
                  "top10ContractsPricePerKind"
                )}
                graphName="Hodnota zakázek dle druhu zadávacího řízení (Top 10)"
                filter="procedure"
                numberOfContracts={`Hodnota zakázek: ${sumPrice.procedure} Kč /${percent.procedure}%`}
              />
              <div id="top10ContractsPricePerKind" style={{ height: 450 }}>
                <BarContracts
                  data={data.procedure}
                  filterName={STAT_FILTER_TYPE.procedure}
                  priceChart
                />
              </div>
            </div>
            <div className={"text-end"}>
              Hodnota zakázek: {sumPrice.procedure} Kč /{percent.procedure}%
            </div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Hodnota zakázek dle způsobu zahájení zadávacího řízení">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("contractsPerStart")}
                graphName="Hodnota zakázek dle způsobu zahájení zadávacího řízení"
                filter="itype"
                numberOfContracts={`Hodnota zakázek: ${sumPrice.itype} Kč /${percent.itype}%`}
              />
              <div id="contractsPerStart" style={{ height: 450 }}>
                <PieContracts
                  data={data.itype}
                  filterName={STAT_FILTER_TYPE.itype}
                  priceChart
                />
              </div>
            </div>

            <div className={"text-end"}>
              Hodnota zakázek: {sumPrice.itype} Kč /{percent.itype}%
            </div>
          </ContentBox>
        </Col>
      </Row>
    </>
  )
}

export default StatContractsPrice
