import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {iUserData} from "../../api/intrefaces";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: null as iUserData | null,
    init: false,
    error: "",
    loading: false
  },
  reducers: {
    setUserData(state, action: PayloadAction<iUserData>) {
      state.userData = action.payload
      state.loading = false;
    },
    setUserInit: (state) => {
      state.init = true
    },
    removeUser(state) {
      state.userData = null
    },
    setLoading(state,  action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  setUserData,
  setUserInit,
  removeUser,
  setLoading,
  setError
} = userSlice.actions;
export default userSlice.reducer;
