import React from "react"
import ContentBox from "../../ContentBox/ContentBox"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FilterBtns from "../FilterBtns"
import FilterSelectCompanies from "../Select/FilterSelectCompanies"
import FilterSelectDepartments from "../Select/FilterSelectDepartments"
import FilterDateStart from "../Date/FilterDateStart"
import {ENUM_ID} from "../../../utils/OPTIONS";
import FilterSelectEnums from "../Select/FilterSelectEnums";
import FilterSearch from "../Search/FilterSearch";
import FilterSearchCpv from "../Search/FilterSearchCpv";

const FilterBoxAudit = () => {
  return (
    <ContentBox>
      <Row>
        <Col sm={6}>
          <FilterSelectCompanies />
          <FilterSelectEnums
              title="Režim VZ"
              name="typeCodes"
              enumId={ENUM_ID.type}
          />
          <FilterDateStart />
        </Col>
        <Col sm={6}>
          <FilterSelectDepartments />
          <FilterSelectEnums
              title="Druh VZ"
              name="otypeCodes"
              enumId={ENUM_ID.otype}
          />
          <FilterSearchCpv />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2" sm={{ span: 4, offset: 2 }}>
          <FilterSearch placeholder="Vyhledat odpovědnou osobu" name="responsiblePersonSearch" />
        </Col>
        <Col>
          <FilterBtns />
        </Col>
      </Row>
    </ContentBox>
  )
}

export default FilterBoxAudit
