import React, { useEffect } from "react";
import AlertError from "../Alerts/AlertError";
import Loading from "../Loading/Loading";
import AlertInfo from "../Alerts/AlertInfo";
import TableBox from "../ContentBox/TableBox";
import {iWinnerOfferPrice} from "../../api/intrefaces";
import { NavLink } from "react-router-dom";
import LINK from "../../utils/LINK";
import {formatNumInt, formatPrice} from "../../utils/utils";
import {useFilterWinnersOfferPrice} from "../../hooks/useFilterWinnersOfferPrice";

const WinnersListOfferPrice = () => {
  const { winners, error, loading, setInitFetch } = useFilterWinnersOfferPrice()

  useEffect(() => {
    setInitFetch();
  }, [setInitFetch]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <AlertError msg={error} />;
  }
  if (!winners || winners.length === 0) {
    return <AlertInfo msg="Žádné zakázky" />;
  }

  return (
    <>
      <TableBox>
        <thead>
          <tr>
            <th>Název uchazeče</th>
            <th>IČ</th>
            <th>Název zakázky</th>
            <th>Číslo zakázky</th>
            <th className="text-end">Nabídková cena v Kč bez DPH</th>
            <th className="text-end">Smluvní cena v Kč bez DPH</th>
            <th className="text-end">Rozdíl v Kč</th>
            <th className="text-end">Rozdíl v %</th>
          </tr>
        </thead>
        <tbody>
          {winners.map((winner: iWinnerOfferPrice, index: number) => (
            <tr key={index}>
              <td>
                <NavLink to={`${LINK.contractorDetail}/${winner.id_company}`}>
                  {winner.name}
                </NavLink>
              </td>
              <td>{winner.ic}</td>
              <td>
                <NavLink to={`${LINK.contractDetail}/${winner.id_contract}`}>
                  {winner.contract_name}
                </NavLink>
              </td>
              <td>{winner.system_number}</td>
              <td className="text-end">{formatNumInt(winner.offer_price_wovat)}
              </td>
              <td className="text-end">{formatNumInt(winner.contract_wovat_value)}
              </td>
              <td className="text-end">{formatNumInt(winner.diff_offer_price)}
              </td>
              <td className="text-end">{formatPrice(winner.diff_offer_price_perc)}
              </td>
            </tr>
          ))}
        </tbody>
      </TableBox>
    </>
  );
};

export default WinnersListOfferPrice;
