import React from "react";
import Card from "react-bootstrap/Card"
// import { FaAngleRight } from "react-icons/fa";
import "./contexbox.scss"
import rightIcon from "./angle-right-solid.svg"
import FilterSelectCompanyHome from "../Filters/Select/FilterSelectCompanyHome";
import Col from "react-bootstrap/Col";
import FilterBtns from "../Filters/FilterBtns";

interface iContetnBoxGrey {
  title: string,
  children: React.ReactNode
}

const ContentBoxHomeBig = ({ title, children }: iContetnBoxGrey) => {

  return (
    <div className={`pb-4`}>
      <Card className="h-100 px-4 pt-3 pb-4 border-0 shadow-sm filters-box-home" style={{
        backgroundImage: `url(${rightIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right 20px bottom",
        backgroundSize: "auto 150%"
      }}>
        <Col md={6}>

          <div className="box_title_grey">{title}</div>
          {children}
        </Col>
        <Col md={6}>
          <FilterSelectCompanyHome/>
          <FilterBtns/>
        </Col>
        {/*<FaAngleRight className="chevron_after"/>*/}
      </Card>
    </div>
  );
}

export default ContentBoxHomeBig;