import React from "react"

import { useOptionsCompany } from "../../../hooks/useOptionsCompany"
import LoadingErrorWrap from "../../LoadingErrorWrap/LoadingErrorWrap"
import FilterSelect from "./FilterSelect"

const FilterSelectCompanies = () => {
  const { companyOptions, loading, error } = useOptionsCompany()

  return (
    <LoadingErrorWrap loading={loading} error={error}>
      <FilterSelect
        title="Zadavatel"
        name="companyIds"
        options={companyOptions}
        multiple
      />
    </LoadingErrorWrap>
  )
}

export default FilterSelectCompanies
