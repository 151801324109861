import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {selectLimitOffset, selectOrder,} from "../redux/filters/filtersSlice";
import { fetchContractsAudit } from "../redux/contracts/contractsAction";
import {useFilterContext} from "../context/FilterContext";

export const useFilterContractsAuditAll = () => {
  const dispatch = useDispatch();
  const {activeFilters, contextName, error, loading, contractsList} = useFilterContext()

  const { limit, offset } = useSelector(selectLimitOffset);
  const order = useSelector(selectOrder);

  const [init, setInit] = useState(false);
  const setInitFetch = () => setInit(true);

  useEffect(() => {
    if (init) {
      let f = {
        ...activeFilters,
        limit: limit,
        offset: offset,
        order: order,
      };
      dispatch(fetchContractsAudit(f, contextName));
    }
  }, [activeFilters, limit, offset, order, init, dispatch, contextName]);

  return { contractsList, error, loading, setInitFetch };
};
