import React from "react"
import LoadingErrorWrap from "../../LoadingErrorWrap/LoadingErrorWrap"
import FilterSelect from "./FilterSelect"
import { useEnums } from "../../../hooks/useEnum"

interface iFilterSelectEnums {
  enumId: string
  title: string
  name: string
}

const FilterSelectEnums = ({ enumId, title, name }: iFilterSelectEnums) => {
  const { data, loading, error } = useEnums(enumId)

  return (
    <LoadingErrorWrap loading={loading} error={error}>
      <FilterSelect title={title} name={name} options={data} multiple />
    </LoadingErrorWrap>
  )
}

export default FilterSelectEnums
