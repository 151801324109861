import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import EventsListAll from "../../components/EventsListAll/EventsListAll";
import FilterBoxEvents from "../../components/Filters/Box/FilterBoxEvents";
import Paginator from "../../components/Paginator/Paginator";
import {FilterContextProvider} from "../../context/FilterContext";
import {PAGE_TYPES} from "../../context/enumPageTypes";

const EventsListPage = () => {
  return (
    <PageWrap
      title="Události a termíny"
      breadcrumbs_items={[{ label: "Události zakázek" }]}
    >
      <FilterContextProvider name={PAGE_TYPES.eventsList}>
        <FilterBoxEvents />
        <EventsListAll />
        <Paginator />
      </FilterContextProvider>
    </PageWrap>
  );
};

export default EventsListPage;
