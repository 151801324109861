import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iEvent } from "../../api/intrefaces";
import { RootState } from "../store";


interface iEventsData {
  [id: number]: iEvent
}

const slice = createSlice({
  name: "events",
  initialState: {
    error: "",
    loading: false,
    events: {} as iEventsData,
    eventsList: [] as number[]
  },
  reducers: {
    setLoadingStart(state) {
      state.loading = true;
      state.error = ""
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    setData(state, action: PayloadAction<iEvent[]>) {
      const events = action.payload;
      state.eventsList = []
      events.forEach(event => {
        state.eventsList.push(event.id_timeline_action)
        state.events[event.id_timeline_action] = event
      })
      state.loading = false;
    },
    setEvent(state, action: PayloadAction<iEvent>) {
      const event = action.payload
      state.events[event.id_timeline_action] = event
    }
  }
});


export const selectEvents = (state: RootState) => ({
  eventsList: state.events.eventsList.map(id => state.events.events[id]),
  events: state.events.events,
  error: state.events.error,
  loading: state.events.loading
})


export const { setLoadingStart, setData, setError } = slice.actions;
export default slice.reducer;
