import moment from "moment"

export const EPOCH_TIME = new Date(0);

export const DATE_FORMAT_DAY = "D. M. Y"

export const humanFileSize = (size: any) => {
  if (size === "0" || size === 0) {
    return "-"
  }
  if (!size) {
    return "-"
  }
  var i = Math.floor(Math.log(size) / Math.log(1024))
  const num = (size / Math.pow(1024, i)).toFixed(2)
  return num + " " + ["B", "kB", "MB", "GB", "TB"][i]
}

export const slugify = (msg: string) => {
  return msg
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")
}

// moment.relativeTimeRounding((t) => {
//     const DIGITS = 2; // like: 2.56 minutes
//     return Math.round(t * Math.pow(10, DIGITS)) / Math.pow(10, DIGITS);
// });
moment.relativeTimeThreshold("y", 365)
moment.relativeTimeThreshold("M", 12)
moment.relativeTimeThreshold("w", 4)
moment.relativeTimeThreshold("d", 31)
moment.relativeTimeThreshold("h", 24)
moment.relativeTimeThreshold("m", 60)
moment.relativeTimeThreshold("s", 60)
moment.relativeTimeThreshold("ss", 0)

export const durationString = (duration: {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
}) => {
  const ret = []
  const retM = []

  ret.push("dny: " + (duration.days || 0))
  if (duration.days) {
    ret.push("dny: " + duration.days)
    retM.push(duration.days + " d.")
  }

  ret.push("hodiny: " + (duration.hours || 0))
  if (duration.hours) {
    ret.push("hodiny: " + duration.hours)
    retM.push(duration.hours + " h.")
  }

  ret.push("minuty: " + (duration.minutes || 0))
  if (duration.minutes) {
    ret.push("minuty: " + duration.minutes)
    retM.push(duration.minutes + " m.")
  }
  if (duration.seconds || duration.milliseconds) {
    ret.push("sekundy: " + duration.seconds)
    let seconds = duration.seconds || 0
    if (duration.milliseconds) {
      seconds += Math.round(duration.milliseconds / 1000)
    }
    retM.push(seconds + " s.")
  }

  return retM.join(", ")
  // return ret.join(', ')
}

export const formatPrice = (price: string | number) =>
  Number(price).toLocaleString("cs", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const formatNumInt = (price: string | number) => {
  return new Intl.NumberFormat().format(parseInt(price.toString()))
}

export function measureStringWidth(text: string | undefined) {
  const ruler = document.createElement("span")
  ruler.style.fontSize = "14px"
  ruler.style.height = "0px"
  ruler.style.position = "absolute"
  ruler.style.visibility = "hidden"
  ruler.style.whiteSpace = "nowrap"
  document.body.appendChild(ruler)
  ruler.innerHTML = text || ""
  return ruler.offsetWidth
}

export function areObjectsEqual(objectOne: any, objectTwo: any) {
  return !Object.keys(objectOne).some(keyOfOne => {
    return (
      !Object.keys(objectTwo).includes(keyOfOne) ||
      !Object.values(objectTwo).includes(objectOne[keyOfOne])
    )
  })
}
