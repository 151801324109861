export type iOptions = {
  label: string,
  value: string
}[]


export const getOptionLabel = (opts: iOptions, value?: string) => {
  if(!value || !opts) return  value
  const opt = opts.find(opt => opt.value === value)
  return opt ? opt.label : "- " + value + " -"
}

export const getOptionLabelList = (opts: iOptions) => {
  return opts.map(opt => opt.label)
}
export const getOptionValueList = (opts: iOptions) => {
  return opts.map(opt => opt.value)
}