import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {selectEnum, setEnum, setEnumError} from "../redux/enums/enumsSlice";
import ServerApi from "../api/ServerApi";
import {ENUM_ID} from "../utils/OPTIONS";
import {iOptions} from "../utils/optionsUtils";


const loadFnMap = {
  [ENUM_ID.type]: ServerApi.getContractTypes,
  [ENUM_ID.otype]: ServerApi.getContractOtypes,
  [ENUM_ID.procedure]: ServerApi.getContractProcedures,
  [ENUM_ID.state]: ServerApi.getContractStates,
  [ENUM_ID.nutsCodeId]: ServerApi.getContractNutsCodes
}

const loadings: string[] = []

export const useEnums = (id: string) => {

  const dispatch = useDispatch()
  const {data, error} = useSelector(selectEnum(id))

  const [loading] = useState(loadings.includes(id))

  const loadEnum = () => {

    if (id in loadFnMap) {
      loadings.push(id)
      console.log("LOAD enum", id)
      loadFnMap[id]()
        .then(data => {

          const enumData: iOptions = data.map((line: any) =>
            ENUM_ID.nutsCodeId === id ?
              ({
                value: line.id_nuts_code,
                label: `${line.code} ${line.description}`
              })
              :
              ({
                value: line.code,
                label: `${line.name}${line.description ? ' - ' + line.description : ''}`
              })
          )
            .sort((a: any, b: any) => a.label.localeCompare(b.label))

          dispatch(setEnum({
            id: id,
            enumData: enumData
          }))

        })
        .catch(err => {
          dispatch(setEnumError({
            id: id,
            error: `Chyba nacteni enum: ${id}` //err.toString()
          }))
        })
        .finally(() => {
          loadings.splice(loadings.indexOf(id), 1)
        })

    } else {
      dispatch(setEnumError({
        id: id,
        error: `Neznamy enum: ${id}`
      }))
    }
  }

  useEffect(() => {
    const isLoading = loadings.includes(id)
    if (!data && !isLoading && !error) {
      loadEnum()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {data, loading, error}
}

