import React from "react"
import AlertError from "../Alerts/AlertError"
import { useEvent } from "../../hooks/useEvent"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import moment from "moment"
import { DATE_FORMAT_DAY } from "../../utils/utils"
import { NavLink } from "react-router-dom"
import LINK from "../../utils/LINK"
import Loading from "../Loading/Loading"
import ContentBox from "../ContentBox/ContentBox"
import BoxLine from "../BoxLine/BoxLine"

interface iEventBox {
  id: number
}

const EventBox = ({ id }: iEventBox) => {
  const { event, loading, error } = useEvent(id)

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={error} />
  }
  if (!event) {
    return <AlertError msg="Chyba načtení události" />
  }

  return (
    <ContentBox>
      <h4>{event.name}</h4>
      <Row>
        <Col>
          <BoxLine title="Typ události">
            {/*{getOptionLabel(OPTIONS.event_type, event.type)}*/}
            {event.type}
          </BoxLine>

          <BoxLine title="Stav události">
            {event.state === "UNKNOWN" ? "-" : event.state === "FINISHED" ? "Ukončeno" : event.state}
          </BoxLine>
          <BoxLine title="Vznik události">
            {moment(event.date).format(DATE_FORMAT_DAY)}
          </BoxLine>
          <BoxLine title="Termín události">
            {moment(event.term).format(DATE_FORMAT_DAY)}
          </BoxLine>
          <BoxLine title="ID">{event.id_timeline_action}</BoxLine>
        </Col>
        <Col>
          <BoxLine title="Detail VZ">
            <NavLink to={`${LINK.contractDetail}/${event.id_contract}`}>
              {event.contract_name}
            </NavLink>
          </BoxLine>
          <BoxLine title="Zadavatel">{event.company_name}</BoxLine>
          <BoxLine title="Oddělení">{event.department_name}</BoxLine>
        </Col>
      </Row>
    </ContentBox>
  )
}

export default EventBox
