import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import AlertError from "../Alerts/AlertError"
import moment from "moment"
import NumberFormat from "react-number-format"
import Stars from "../Stars/Stars"
import RatingQuestionMark from "../RatingQuestionMark/RatingQuestionMark"
import { DATE_FORMAT_DAY, EPOCH_TIME } from "../../utils/utils"
import { NavLink } from "react-router-dom"
import LINK from "../../utils/LINK"
import { getOptionLabel } from "../../utils/optionsUtils"
import { ENUM_ID } from "../../utils/OPTIONS"
import { useEnums } from "../../hooks/useEnum"
import Loading from "../Loading/Loading"
import ResponsiblePerson from "../Blocks/ResponsiblePerson"
import BoxLine from "../BoxLine/BoxLine"
import ContentBox from "../ContentBox/ContentBox"
import ContractBoxMenu from "./ContractBoxMenu"
import {iContract} from "../../api/intrefaces";
import {useFilterContext} from "../../context/FilterContext";

interface iContractBox {
  id: number | string
  onList?: boolean
  contract?: iContract
}

const codeStatesToClose = [
  "DISABLED", "FINISHED", "ORD_ASSIGN", "MASSIGNED", "ASSIGNED", "ORD_CONF", "MEVALUATED", "MDEALING", "EVALUATED", "ASSIGNING"
]

const ContractBox = ({ id, contract: aContract, onList }: iContractBox) => {
  const { data: enum_type } = useEnums(ENUM_ID.type)
  const { data: enum_state } = useEnums(ENUM_ID.state)
  const { data: enum_procedure } = useEnums(ENUM_ID.procedure)
  const { data: enum_otype } = useEnums(ENUM_ID.otype)

  const { contracts, contractDetail, error, loading} = useFilterContext()
  // @ts-ignore
  const bContract = contracts[id] ? contracts[id] : contractDetail[id]

  const contract = aContract ? aContract : bContract

  if (loading || !bContract) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={error} />
  }

  if (!contract) {
    return <AlertError msg="Chyba načtení zakázky" />
  }
  const contractName = contract.contract_name ? (
    contract.contract_name
  ) : (
    <i>název neuveden</i>
  )

  const findInArray = (array: string[], value?: string) => {
    return array.find((item => item === value))
  }

  return (
    <ContentBox>
      {onList ? (
        <NavLink to={`${LINK.contractDetail}/${contract.id_contract}`}>
          <h4 style={{ lineHeight: 1, paddingRight: 115 }}>{contractName}</h4>
        </NavLink>
      ) : (
        <h4
          style={{ lineHeight: 1, paddingRight: 115 }}
          className="text-primary"
        >
          {contractName}
        </h4>
      )}
      <div style={{ position: "absolute", right: 20, top: 10 }}>
        <Stars num={contract.rating} /> <RatingQuestionMark />
      </div>

      <Row>
        <Col>
          <BoxLine title="Datum zahájení VZ">
            {contract.date_start !== EPOCH_TIME ? moment(contract.date_start).format(DATE_FORMAT_DAY) : "-"}
          </BoxLine>
          <BoxLine title="Druh VZ">
            {getOptionLabel(enum_otype, contract.code_otype)}
          </BoxLine>
          <BoxLine title="Režim VZ">
            {getOptionLabel(enum_type, contract.code_type)}
          </BoxLine>
          <BoxLine title="Předpokládaná hodnota bez DPH">
            <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                value={contract.sup_value}
                suffix=" Kč"
            />
          </BoxLine>
        </Col>
        <Col>
          <BoxLine title="Druh ZŘ">
            {getOptionLabel(enum_procedure, contract.code_procedure)}
          </BoxLine>
          <BoxLine title="Fáze ZŘ">
            {contract.code_state !== "DISABLED" && contract.code_state !== "CLOSED"
              ? getOptionLabel(enum_state, contract.code_state)
            : contract.name_state}
          </BoxLine>
          <BoxLine title="Číslo VZ na profilu zadavatele">{contract.system_number}</BoxLine>
          <BoxLine title="Smluvní cena bez DPH">{
            contract.code_state === findInArray(codeStatesToClose, contract.code_state)
            && contract.wovat_value
            && `${new Intl.NumberFormat().format(parseInt(contract.wovat_value.toString()))} Kč`}</BoxLine>
        </Col>
      </Row>

      <Row className="mt-3" style={{ borderTop: "1px solid grey" }}>
        <Col>
          <BoxLine title="Zadavatel">{contract.company_name}</BoxLine>
          <BoxLine title="Oddělení">{contract.department_name}</BoxLine>
          <BoxLine title="Odpovědná osoba zadavatele">
            <ResponsiblePerson
              title={""}
              resPersons={contract.responsible_person_submitter}
            />
          </BoxLine>
        </Col>

        {onList && (
          <Col sm={2} className="text-end pt-2">
            <ContractBoxMenu id={contract.id_contract} />
          </Col>
        )}
      </Row>
    </ContentBox>
  )
}

export default ContractBox
