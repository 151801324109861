import React from "react";
import {ResponsivePie} from "@nivo/pie"
import AlertInfo from "../Alerts/AlertInfo";
import {iStatsChart} from "../../api/intrefaces";
import {tooltip} from "./tooltip";
import {setFilterCharts} from "../../redux/filters/filtersSlice";
import {useDispatch} from "react-redux";
import {useFilterContext} from "../../context/FilterContext";

interface iPieStats {
  data: iStatsChart[],
  marginLeft?: number,
  translateToLeft?: number,
  translateToTop?: number,
  LegendAnchor?: string,
  percent?: boolean,
  filterName?: string,
  onClickActive?: boolean
}

const MAX_LABEL_LEN = 25;
const PieStats = ({
                    data,
                    marginLeft = 80,
                    translateToLeft = -50,
                    LegendAnchor,
                    translateToTop = 110,
                    percent,
                    filterName,
                    onClickActive}: iPieStats) => {
  const dispatch = useDispatch();
  const {contextStatsName} = useFilterContext()

  function handleClick(node: any) {
    if (onClickActive && filterName) {
      dispatch(setFilterCharts(
          { name: contextStatsName, filters: {name: filterName, value: node.data.label}}
      ))
      && dispatch(setFilterCharts(
          { name: contextStatsName, filters: {name: `companyId`, value: node.data.id} }));
    }
  }

  if (!data || data.length === 0) {
    return <AlertInfo msg="Žádná data"/>
  }

  const truncate = (label: string, max_len: number) => {
    return label.length > max_len + 3 ? label.substr(0, max_len) + '...' : label
  }
  return (
    <ResponsivePie
      data={data}
      colors={{scheme: "accent"}}
      valueFormat={value => percent ? `${Number(value).toLocaleString()} %` : Number(value).toLocaleString()}
      margin={{top: 40, right: 80, bottom: 110, left: marginLeft}}
      innerRadius={0.5} //dira
      padAngle={0.7}// mezera mezi ctvrtkam
      cornerRadius={3}//zaobleni ctvrtek

      activeOuterRadiusOffset={10} // hover
      activeInnerRadiusOffset={10} // hover

      borderWidth={1}
      borderColor={{from: "color", modifiers: [["darker", 0.2]]}}

      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{from: "color", modifiers: [["darker", 2]]}}

      // arcLinkLabel={"label"}
      arcLinkLabel={d => truncate(d.data.label, MAX_LABEL_LEN)}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{from: "color"}}
      onClick={(node, event) => {
        console.log("nodee click", node);
        handleClick(node)
      }}
      tooltip={tooltip}

      legends={[
        {
          anchor: LegendAnchor ? "top-left" : "bottom-left",
          direction: "column",
          justify: false,
          itemWidth: 100,
          itemHeight: 18,
          symbolShape: "circle",
          translateX: translateToLeft,
          translateY: translateToTop,
          itemTextColor: "#999",
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 0.8
              }
            }
          ]
        }
      ]}
    />
  )
}

export default PieStats;