import React from "react";
import Modal from "react-bootstrap/Modal"
import { useDispatch, useSelector } from "react-redux";
import { selectModalGlobalIndicatorsOpen, setModalGlobalIndicatorsOpen } from "../../redux/global/globalSlice";

const ModalInfoCalcGlobalIndicators = () => {

  const dispatch = useDispatch()
  const open = useSelector(selectModalGlobalIndicatorsOpen)
  const handleClose = () => dispatch(setModalGlobalIndicatorsOpen(false))

  return (
    <Modal show={open} onHide={handleClose} size="lg">

      <Modal.Header closeButton>
        <Modal.Title>Metodika výpočtu.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Průměrný počet nabídek je vypočten jako prostý průměr počtu nabídek u ukončených zakázek.
        V úvahu jsou brány pouze zakázky s platnými údaji.
        <br/>
        <br/>

        Počet nabídek VZ ukazuje míru konkurence, což je jedním z protikorupčních ukazatelů,
        a napomáhá efektivitě VZ přímým vlivem na konečnou cenu.
        <br/>
        <br/>

        Počet uchazečů může být ovlivněn chybně definovaným předmětem plnění,
        příliš přísnými či nesrozumitelnými kvalifikačními kritérii. Je také nutné zvážit efektivní dělení zakázek.
        <br/>
        <br/>
      </Modal.Body>
    </Modal>
  );
}

export default ModalInfoCalcGlobalIndicators;