import {useEffect} from 'react';
import { useLocation} from "react-router-dom";
// import {useHistory} from "react-router-dom";



const RouterScrollTop = () => {
    // const history = useHistory()
    // useEffect(() => {
    //   const unlisten = history.listen((location, action) => {
    //     if (action !== 'POP') {
    //       window.scrollTo(0, 0)
    //     }
    //   })
    //   return () => unlisten()
    // }, [])



  // //TODO bez probliknuti ?????
  const {pathname} = useLocation();
  // Scroll to top if path changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  //

    return (null)
}

export default RouterScrollTop;