import React from "react";
import AlertError from "../Alerts/AlertError";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../Loading/Loading";
import ContentBox from "../ContentBox/ContentBox";
import {useCompany} from "../../hooks/useCompany";
import BoxLine from "../BoxLine/BoxLine";

interface iContractorBox {
  id: number | string
}

const ContractorBox = ({id}: iContractorBox) => {
  const {company, loading, error} = useCompany(id)

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }
  if (!company) {
    return <AlertError msg="Chyba načtení dodavatele"/>
  }

  return (
    <Row>
      <Col md={7}>
        <ContentBox title="Detail dodavatele">
          <BoxLine big title="Název / obchodní jméno">
            {company.name}
          </BoxLine>

          <BoxLine big title="Typ osoby">
            {company.person === "NATURAL" ? 'fyzická' : 'právnická'}
          </BoxLine>
          {company.person === "NATURAL" &&
          <BoxLine big title="Jméno fyzické osoby">
            {company.title} {company.firstname} {company.surname}
          </BoxLine>
          }
          <BoxLine big title="IČ">
            {company.ic}
          </BoxLine>
          <BoxLine big title="Doručovací adresa">
            {company.address}
          </BoxLine>
          <BoxLine big title="Popis k dodavateli">
            {company.description}
          </BoxLine>
        </ContentBox>
      </Col>

      <Col md={5}>
        <ContentBox title="Kontaktní údaje z CDD">
          <BoxLine small title="Telefon">
            {company.tel}
          </BoxLine>
          <BoxLine small title="Email">
            {company.email}
          </BoxLine>
        </ContentBox>
      </Col>
    </Row>
  );
}

export default ContractorBox;
