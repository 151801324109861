import React from "react"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { NavLink } from "react-router-dom"
import "./bcrumb.scss"

interface iBcrumb {
  items: { link?: string; label: string }[]
  nohomelink?: boolean
}

const Bcrumb = ({ items, nohomelink }: iBcrumb) => {
  if (items.length === 0 && nohomelink) {
    return null
  }

  const itemsr = !nohomelink
    ? [{ label: "Dashboard", link: items.length ? "/" : undefined }, ...items]
    : items

  return (
    <Breadcrumb listProps={{ style: { background: "none" } }}>
      {itemsr.map((item, index) =>
        item.link ? (
          <Breadcrumb.Item key={index}>
            <NavLink to={item.link}>{item.label}</NavLink>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index} active>
            {item.label}
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  )
}

export default Bcrumb
