import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {RootState} from "../store";
import { iTableLegendQAItem } from "../../api/intrefaces"

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    showOrHideColumnsSetting: {} as iTableLegendQAItem[]
  },
  reducers: {
    setShowOrHideColumnsSetting(state, action: PayloadAction<iTableLegendQAItem[]>) {
      state.showOrHideColumnsSetting = action.payload
    }
  }
});

export const selectShowOrHideColumnsSetting = (state: RootState) => state.settings.showOrHideColumnsSetting
export const {
  setShowOrHideColumnsSetting
} = settingsSlice.actions;

export default settingsSlice.reducer;
