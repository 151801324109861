import React, { useEffect } from "react";
import RatingQuestionMark from "../RatingQuestionMark/RatingQuestionMark";
import Stars from "../Stars/Stars";
import LINK from "../../utils/LINK";
import { useFilterContractsRatings } from "../../hooks/useFilterContractsRatings";
import Loading from "../Loading/Loading";
import AlertError from "../Alerts/AlertError";
import {PAGE_TYPES} from "../../context/enumPageTypes";
import LinkTo from "../Link/LinkTo";

const RatingBoxHome = () => {

  const { ratings, error, loading, setInitFetch } = useFilterContractsRatings()

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])

  const Block = ({ numStar, count, widthRatio }: { numStar: number, count: number, widthRatio: number }) =>
    <div style={{ display: "flex", alignItems: "center" }}>
      <Stars num={numStar}/>
      <span style={{ display: "inline-block", height: 12, width: `${count * widthRatio}%`, background: "grey", margin: "0 10px" }}/>
      {count ?
        <LinkTo to={LINK.contractsList}
                name={PAGE_TYPES.contratsList}
                ratingFrom={numStar.toString()}
                ratingTo={numStar.toString()}>
          {count.toLocaleString()}
        </LinkTo>
        : "0"
      }
    </div>


  const stars = Array(6).fill(0)

  ratings?.forEach((rating: any) => {
    stars[rating.rating] = Number(rating.count)
  })

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }

  const wRatio = 50 / Math.max(...stars)

  return (
    <>
      <div style={{ position: "absolute", right: 20 }}>
        <RatingQuestionMark/>
      </div>
      <div>
        <Block numStar={5} count={stars[5]} widthRatio={wRatio}/>
      </div>
      <div>
        <Block numStar={4} count={stars[4]} widthRatio={wRatio}/>
      </div>
      <div>
        <Block numStar={3} count={stars[3]} widthRatio={wRatio}/>
      </div>
      <div>
        <Block numStar={2} count={stars[2]} widthRatio={wRatio}/>
      </div>
      <div>
        <Block numStar={1} count={stars[1]} widthRatio={wRatio}/>
      </div>
    </>
  );
}

export default RatingBoxHome;