import { useEffect, useState } from 'react'
import ServerApi from '../api/ServerApi'
import { divideStats } from '../utils/prepareStats'
import { iStatsTypes } from '../api/intrefaces'

export const useFilterContractsStatsAll = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState({ count: {} as iStatsTypes, sum_kc: {} as iStatsTypes })

  useEffect(() => {
    setLoading(true)

    ServerApi.getStatsContractsByCriteria({})
      .then(response => {
        const data = divideStats(response)
        setData(data)
      })
      .catch(err => {
        console.error('err fetch all: ', err, err.response)
        const errmsg = err.response?.data?.error?.description || err.toString()
        setError(errmsg)
      })
      .finally(() => setLoading(false))

  }, [])

  return { loading, error, data }
}

