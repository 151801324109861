import React from "react";
import { useDispatch, /*useSelector*/ } from "react-redux";
import {/*selectFiltersChart,*/ setFilterCharts, setFilter} from "../../redux/filters/filtersSlice";
import Button from "react-bootstrap/Button";
import { FaTimes } from "react-icons/fa";
import "./filterstat.scss"
import {useFilterContext} from "../../context/FilterContext";

export const STAT_FILTER_TYPE = {
  otype: "otypeCode",
  itype: "itypeCode",
  type: "typeCode",
  procedure: "procedureCode",
  state: "stateCode",
  organization: "organization",
  nuts: "nutsCode",
  contractor: "contractor"
}

const titles = {
  [STAT_FILTER_TYPE.otype]: "Druh zakázky",
  [STAT_FILTER_TYPE.itype]: "Způsob zahájení zadávacího řízení",
  [STAT_FILTER_TYPE.type]: "Typ zakázky",
  [STAT_FILTER_TYPE.procedure]: "Druh zadávacího řízení",
  [STAT_FILTER_TYPE.state]: "Fáze zadávacího řízení",
  [STAT_FILTER_TYPE.organization]: "Organizace",
  [STAT_FILTER_TYPE.nuts]: "Místo realizace",
  [STAT_FILTER_TYPE.contractor]: "Dodavatel"
}
const FilterStat = () => {
  const dispatch = useDispatch()
  const {contextStatsName, activeFiltersChart} = useFilterContext()
  const { nutsCode, companyId, ...activeFiltersChartCut} = activeFiltersChart
  const filterChartName = Object.keys(activeFiltersChart)[0]

  return (
    <div className="stat_filter">
      {Object.entries(activeFiltersChartCut).map(([key, val]) =>
        <div key={key}>
          {key === "nutsCodeDescription" ? titles[STAT_FILTER_TYPE.nuts] : titles[key]}: {val}

          <Button variant="link" onClick={() => {
            dispatch(setFilterCharts({name: contextStatsName, filters: {name: key, value: ""}}))
            key === "nutsCodeDescription" &&
            dispatch(setFilterCharts({name: contextStatsName, filters: {name: filterChartName, value: ""}}))
            key === "organization" &&
            dispatch(setFilterCharts({name: contextStatsName, filters: {name: "companyId", value: ""}}))
            key === "contractor" &&
            dispatch(setFilter({name: contextStatsName, filters: null}))
          }}
          >
            <FaTimes style={{marginTop: -5}}/>
          </Button>
        </div>
      )}
    </div>
  );
}

export default FilterStat;