import { useDispatch, useSelector } from "react-redux";
// import { selectFiltersChart } from "../redux/filters/filtersSlice";
import { selectStats } from "../redux/stats/statsSlice";
import { useEffect, useState } from "react";
import { fetchStats } from "../redux/stats/statsAction";
import {useFilterContext} from "../context/FilterContext";

export const useFilterContractsStats = () => {
  const dispatch = useDispatch()
  const {activeFilters, activeFiltersChart} = useFilterContext()
  // const filterChart = useSelector(selectFiltersChart)

  const { stats, error, loading } = useSelector(selectStats)

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)

  useEffect(() => {
    if (init) {
      const f = {
        ...activeFilters,
        // ...filterChart,
        ...activeFiltersChart
      }
      dispatch(fetchStats(f))
    }
  }, [activeFilters, init, dispatch, /*filterChart,*/ activeFiltersChart])

  return { stats, error, loading, setInitFetch }
}

