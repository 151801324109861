import React, { useEffect } from "react";
import { useFilterContractsStats } from "../../hooks/useFilterContractsStats";
import Loading from "../Loading/Loading";
import AlertError from "../Alerts/AlertError";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContentBox from "../ContentBox/ContentBox";
import PieContracts from "../ChartsNivo/PieContracts";
import BarContracts from "../ChartsNivo/BarContracts";
import { prepareStats } from "../../utils/prepareStats";
import FilterStat, { STAT_FILTER_TYPE } from "./FilterStat";
import { useFilterContractsStatsAll } from "../../hooks/useFilterContractsStatsAll";
import ExportPDFButton from "../ExportPDFButton/ExportPDFButton";

const StatContractsCount = () => {
  const { stats, error, loading, setInitFetch } = useFilterContractsStats();

  const { data: allStatsData } = useFilterContractsStatsAll();

  useEffect(() => {
    setInitFetch();
  }, [setInitFetch]);

  // if (loading) {
  //   return <Loading/>
  // }
  if (error) {
    return <AlertError msg={error} />;
  }

  const {
    data,
    sum: count,
    percent,
  } = prepareStats(stats.count, allStatsData.count);

  return (
    <>
      <div className="sticky-top">
        <FilterStat />
        {loading && <Loading />}
      </div>
      <Row>
        <Col>
          <ContentBox title="Zakázky dle typu zakázky">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("perType")}
                graphName="Zakázky dle typu zakázky"
                filter="type"
              />
              <div id="perType" style={{ height: 450 }}>
                <PieContracts
                  data={data.type}
                  filterName={STAT_FILTER_TYPE.type}
                />
              </div>
            </div>

            <div className={"text-end"}>
              Počet zakázek {count.type} /{percent.type}%
            </div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Zakázky dle druhu zakázky">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("perKind")}
                graphName="Zakázky dle druhu zakázky"
                filter="otype"
              />
              <div id="perKind" style={{ height: 450 }}>
                <PieContracts
                  data={data.otype}
                  filterName={STAT_FILTER_TYPE.otype}
                />
              </div>
            </div>

            <div className={"text-end"}>
              Počet zakázek {count.otype} /{percent.otype}%
            </div>
          </ContentBox>
        </Col>
      </Row>

      <Row>
        <Col>
          <ContentBox title="Zakázky dle druhu zadávacího řízení (Top 10)">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("procedureType")}
                graphName="Zakázky dle druhu zadávacího řízení (Top 10)"
                filter="procedure"
              />
              <div id="procedureType" style={{ height: 450 }}>
                <BarContracts
                  data={data.procedure}
                  filterName={STAT_FILTER_TYPE.procedure}
                />
              </div>
            </div>

            <div className={"text-end"}>
              Počet zakázek {count.procedure} /{percent.procedure}%
            </div>
          </ContentBox>
        </Col>

        <Col>
          <ContentBox title="Zakázky dle způsobu zahájení zadávacího řízení">
            <div style={{ height: 450 }}>
              <ExportPDFButton
                exportedGraph={document.getElementById("initiationType")}
                graphName="Zakázky dle způsobu zahájení zadávacího řízení"
                filter="itype"
              />
              <div id="initiationType" style={{ height: 450 }}>
                <PieContracts
                  data={data.itype}
                  filterName={STAT_FILTER_TYPE.itype}
                />
              </div>
            </div>

            <div className={"text-end"}>
              Počet zakázek {count.itype} /{percent.itype}%
            </div>
          </ContentBox>
        </Col>
      </Row>
    </>
  );
};

export default StatContractsCount;
