import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaCalendar } from "react-icons/fa";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import cs from "date-fns/locale/cs";
import "../filter.scss";
import moment from "moment";
import {useFilterContextInput} from "../../../context/FilterContext";

registerLocale("cs", cs);
setDefaultLocale("cs");

interface iFilterDate {
  title?: string;
  nameFrom: string;
  nameTo: string;
}

const FilterDate = ({ title, nameFrom, nameTo }: iFilterDate) => {
  const { value, setVal } = useFilterContextInput(nameFrom)
  const { value: valueTo, setVal: setValueTo } = useFilterContextInput(nameTo)

  const fromFilter = value;
  const toFilter = valueTo;

  const selectedFrom = fromFilter ? moment(fromFilter).toDate() : null;
  const selectedTo = toFilter ? moment(toFilter).subtract(1, "day").toDate() : null;

  const setDateFrom = (dateFrom: any) => {
    const from = dateFrom
        ? moment(dateFrom).startOf("day").format("YYYY-MM-DDTHH:mm")
        : "";
    setVal(from)
  };

  const setDateTo = (dateTo: any) => {
    const to = dateTo
        ? moment(dateTo).add(1, "day").endOf("day").format("YYYY-MM-DDTHH:mm")
        : "";
    setValueTo(to)
  };

  return (
    <Form.Group
      className="mb-1"
      as={Row}
      controlId={`filterDate${nameFrom}${nameTo}`}
    >
      {title && (
        <Form.Label column sm="4" className="text-end">
          {title}
        </Form.Label>
      )}
      <Col>
        <Row>
          <Col lg={6}>
            <label>
              <DatePicker
                // open={open}
                // onCalendarClose={()=>setOpen(false)}
                // onCalendarOpen={()=>setOpen(true)}
                className="form-control "
                dateFormat="dd.MM.yyyy"
                // isClearable
                placeholderText="Od"
                selected={selectedFrom}
                onChange={(date) => setDateFrom(date)}
              />
              <FaCalendar className="text-primary calendar_icon" />
            </label>
          </Col>
          {/*<Col sm={1}>-</Col>*/}
          <Col lg={6}>
            <label>
              <DatePicker
                className="form-control"
                dateFormat="dd.MM.yyyy"
                // isClearable
                placeholderText="Do"
                selected={selectedTo}
                onChange={(date) => setDateTo(date)}
              />
              <FaCalendar className="text-primary calendar_icon" />
            </label>
          </Col>
        </Row>
      </Col>
    </Form.Group>
  );
};

export default FilterDate;
