import React from "react";

export const tooltip = ({datum: {label, color, formattedValue}}:any) => (
  <div style={{
    padding: '5px 9px',
    borderRadius: 2,
    boxShadow: "rgb(0 0 0 / 25%) 0px 1px 2px",
    background: 'white',
  }}>
    <span style={{
      display: 'inline-block',
      width: 12,
      height: 12,
      background: color,
      marginRight: 7
    }}></span>

    {label}: <strong>{formattedValue}</strong>
  </div>
)