import React, { useEffect } from "react"
import TableBoxResponsive from "../ContentBox/TableBoxResponsive";
import Stars from "../Stars/Stars";
import Row from "react-bootstrap/Row";
import ContentBox from "../ContentBox/ContentBox";
import Loading from "../Loading/Loading";
import AlertError from "../Alerts/AlertError";
import moment from "moment";
import {DATE_FORMAT_DAY} from "../../utils/utils";
import {useFilterContractsAuditAll} from "../../hooks/useFilterContractsAuditAll";
import AlertInfo from "../Alerts/AlertInfo";
import { preparedContractsList } from "../../utils/prepareContractsList";
import TableCellContractsAuditList from "./TableCellContractsAuditList";
import LINK from "../../utils/LINK";
import {NavLink} from "react-router-dom";
import { ENV } from "../../ENV";
import { iTableLegendQAItem } from "../../api/intrefaces"

interface ContractsAuditListProps {
  tableLegend: iTableLegendQAItem[];
}

const ContractsAuditList = ({tableLegend}: ContractsAuditListProps) => {
  const { contractsList, error, loading, setInitFetch } =
      useFilterContractsAuditAll()

  const contractorsOfferPriceWovat: any = (contract: any) => {
    return contract && contract.map((item: any) => (
        <span>{item.name} {(item.price_wovat ? "- " + parseFloat(item.price_wovat).toLocaleString() + " Kč" : "")}<br/></span>
      )
    )
  }

  const contractorsOfferPriceVat: any = (contract: any) => {
    return contract && contract.map((item: any) => (
        <span>{item.name} {(item.price_vat ? "- " + parseFloat(item.price_vat).toLocaleString() + " Kč" : "")}<br/></span>
      )
    )
  }

  const dateCanceled = (contract: string) => {
    return contract === "N/A" ? contract : moment(contract).format(DATE_FORMAT_DAY)
  }

  const formattedPerson: any = (person: string) => {
    const lines = person?.split(", ")
    return lines?.map((line: string) => (
      <span>{line}<br /></span>
    ))
  }

  useEffect(() => {
    setInitFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={error} />
  }

  return (
    <Row>
      <ContentBox partHeight title="Veřejné zakázky">
        {contractsList.length > 0 ?
          <TableBoxResponsive>
            <thead>
              <tr>
                {tableLegend.map((contract: any, index: number) => (
                  contract.checked === true && <th key={index}>{contract.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
            {preparedContractsList(contractsList).map((contract: any, index: number) => (
                <tr key={index}>
                  <TableCellContractsAuditList data={contract.system_number} tableColumnsLegendId={"contractsSystemNumber"}>
                    <NavLink to={`${LINK.contractDetail}/${contract.id_contract}`}>
                      {contract.system_number}
                    </NavLink>
                  </TableCellContractsAuditList>
                  <TableCellContractsAuditList data={contract.sum_empty_value || 0} tableColumnsLegendId={"sum_value_empty"}/>
                  <TableCellContractsAuditList data={contract.rating} tableColumnsLegendId={"rating"}>
                    <div className="stars-wrapper"><Stars num={contract.rating}/></div>
                  </TableCellContractsAuditList>
                  <TableCellContractsAuditList data={contract.contract_name} width={"table-col-large-width"} tableColumnsLegendId={"contractName"}/>
                  <TableCellContractsAuditList data={contract.contract_mark} tableColumnsLegendId={"contractMark"}/>
                  <TableCellContractsAuditList data={contract.company_vvz_profile_id} tableColumnsLegendId={"companyVvzProfileId"}/>
                  <TableCellContractsAuditList data={contract.company_name} width={"table-col-large-width"} tableColumnsLegendId={"companyName"}/>
                  <TableCellContractsAuditList data={contract.company_ic} tableColumnsLegendId={"companyIc"}/>
                  <TableCellContractsAuditList data={"ANO"} tableColumnsLegendId={"contractPublic"}/>
                  <TableCellContractsAuditList data={contract.name_procedure} tableColumnsLegendId={"procedureName"}/>
                  <TableCellContractsAuditList data={contract.name_type} tableColumnsLegendId={"contractTypeName"}/>
                  <TableCellContractsAuditList data={contract.name_otype} tableColumnsLegendId={"contractOTypeName"}/>
                  <TableCellContractsAuditList data={contract.name_state} tableColumnsLegendId={"stateName"}/>
                  <TableCellContractsAuditList data={contract.sup_value} alignRight={"text-align-right"} tableColumnsLegendId={"supposedValue"}/>
                  <TableCellContractsAuditList data={contract.contract_cpv_codes} width={"table-col-small-width"} tableColumnsLegendId={"companyCpvCodes"}/>
                  <TableCellContractsAuditList data={contract.contract_place} tableColumnsLegendId={"contractPlace"}/>
                  <TableCellContractsAuditList data={contract.id_contract} width={"table-col-large-width"} tableColumnsLegendId={"contractUrl"}>
                    <a href={ENV.NEN ?
                        `${ENV.IEN_URL}NPSUMAVA/zahajene-zakazky/detail-zakazky/${contract.id_contract}.html`
                        :
                        `${ENV.IEN_URL}contract_display_${contract.id_contract}.html`}
                       target="_blank"
                       rel="noreferrer">
                      Odkaz do zdrojového systému
                    </a>
                  </TableCellContractsAuditList>
                  <TableCellContractsAuditList data={contract.responsible_procurement_type} check={true} tableColumnsLegendId={"responsibleProcurementTypes"}/>
                  <TableCellContractsAuditList data={contractorsOfferPriceWovat(contract.contractors_offer_price)}
                                               width={"table-col-large-width"} tableColumnsLegendId={"contractContractorsWovat"}/>
                  <TableCellContractsAuditList data={contractorsOfferPriceVat(contract.contractors_offer_price)}
                                               width={"table-col-large-width"} tableColumnsLegendId={"contractContractorsVat"}/>
                  <TableCellContractsAuditList data={contract.winner_name} width={"table-col-large-width"} tableColumnsLegendId={"contractWinnerName"}/>
                  <TableCellContractsAuditList data={moment(contract.date_agreement).format(DATE_FORMAT_DAY)}
                                               width={"table-col-small-width"} tableColumnsLegendId={"contractAgreementDate"}/>
                  <TableCellContractsAuditList data={contract.wovat_value} alignRight={"text-align-right"} tableColumnsLegendId={"contractWovatValue"}/>
                  <TableCellContractsAuditList data={contract.vat_value} alignRight={"text-align-right"} tableColumnsLegendId={"contractVatValue"}/>
                  <TableCellContractsAuditList data={contract.sum_wovat_value} alignRight={"text-align-right"} tableColumnsLegendId={"contractSumPerformanceWovatValue"}/>
                  <TableCellContractsAuditList data={contract.sum_vat_value} alignRight={"text-align-right"} tableColumnsLegendId={"contractSumPerformanceVatValue"}/>
                  <TableCellContractsAuditList data={dateCanceled(contract.date_canceled)}
                                               width={"table-col-small-width"} tableColumnsLegendId={"contractCanceledDate"}/>
                  <TableCellContractsAuditList data={formattedPerson(contract.responsible_person_submitter)}
                                               width={"table-col-large-width"} tableColumnsLegendId={"contactResponsiblePerson"}/>
                </tr>
            ))}
            </tbody>
          </TableBoxResponsive>
          :
          <AlertInfo msg={"Nejsou dostupná žádná data."}/>
        }
      </ContentBox>
    </Row>
  );
}

export default ContractsAuditList;