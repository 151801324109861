import React from "react"
import { ResponsiveBar } from "@nivo/bar"
import { line } from "d3-shape"
import AlertInfo from "../Alerts/AlertInfo"
import { iGlobalStatsByCriteria } from "../../api/intrefaces"

interface iBarContracts {
  data: iGlobalStatsByCriteria[]
  legend: string
  priceChart?: boolean
  colorByLabel?: boolean
  horizontal?: boolean
}

const averageContracts: string = "Prumer_nabidek"
const globalAverageContracts: string = "Globalni_prumer_nabidek"

const LineLayer = ({ bars, xScale, yScale }: any) => {
  const filteredBars = bars.filter((val: any) => val.data.id === averageContracts)
  const lineGenerator = line()
      // @ts-ignore
      .x(d => xScale(d.data.indexValue) + d.width * 1.5)
      // @ts-ignore
      .y(d => yScale(d.data.value));
  return (
      // @ts-ignore
      <path d={lineGenerator(filteredBars)} fill="none" stroke="rgb(51, 51, 51)" />
  );
};

const MAX_BOTTOM_LABEL_LEN = 25
const BarGlobalStats = ({
                    data,
                    legend,
                    priceChart,
                    horizontal,
                  }: iBarContracts) => {
  if (!data || data.length === 0) {
    return <AlertInfo msg="Žádná data" />
  }

  // const labels = data
  //     .map(d => d.offer_year)
  //     .sort()
  //     .reverse()
  // console.log(labels, "labels")

  const getColor = (id:string | number, data:any) => {
    const percentageOffers = data.Prumer_nabidek / data.Globalni_prumer_nabidek * 100
    return id === averageContracts && percentageOffers <= 33 ? "rgb(231, 138, 195)"
        : id === averageContracts && percentageOffers <= 66 ? "rgb(252, 141, 98)"
        : id === averageContracts && percentageOffers < 100 ? "rgb(255, 217, 47)"
        : id === averageContracts && percentageOffers >= 100 ? "rgb(166, 216, 84)" : "rgb(141, 160, 203)"
  }

  return (
      <ResponsiveBar
          data={data as any}
          keys={[globalAverageContracts, averageContracts]}
          indexBy="offer_year"
          layout={horizontal ? "horizontal" : "vertical"}
          groupMode={"grouped"}
          margin={{
            top: 20,
            right: 20,
            bottom: 150,
            left: horizontal ? 120 : 70,
          }}
          padding={0.3} //between group bars
          colors={({ id, data }) => getColor(id, data)}
          // colors={[ "rgb(141, 160, 203)", "rgb(255, 217, 47)", "rgb(166, 216, 84)" ]}
          borderColor="#000000"
          axisTop={null}
          axisRight={null}
          axisBottom={
             {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -30,
                  // format: label =>
                  //     label.length > MAX_BOTTOM_LABEL_LEN + 3
                  //         ? label.substr(0, MAX_BOTTOM_LABEL_LEN) + "..."
                  //         : label,
                  legend: "Rok",
                  legendPosition: "middle",
                  legendOffset: 50
                }
          }
          axisLeft={
            horizontal
                ? {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  format: label =>
                      label.length > MAX_BOTTOM_LABEL_LEN + 3
                          ? label.substr(0, MAX_BOTTOM_LABEL_LEN) + "..."
                          : label,
                  // legend: "Druh ZŘ",
                  // legendPosition: "end",
                  // legendOffset: 40
                }
                : {
                  tickSize: priceChart ? 2 : 5,
                  tickPadding: priceChart ? -2 : 5,
                  tickRotation: 0,
                  legend: legend,
                  legendPosition: "middle",
                  legendOffset: -60,
                }
          }
          enableLabel={false}
          legends={[
            {
              dataFrom: "keys",
              anchor: 'bottom-left',
              direction: 'column',
              justify: false,
              translateX: -25,
              translateY: 130,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              symbolShape: ({ x, y, size, fill, borderWidth, borderColor }: any) => (
                  fill !== "rgb(141, 160, 203)" ?
                  <>
                    <rect
                        x={x}
                        y={y}
                        fill={"rgb(231, 138, 195)"}
                        strokeWidth={borderWidth}
                        stroke={borderColor}
                        width={size/4}
                        height={size}
                        style={{ pointerEvents: 'none' }}
                    />
                    <rect
                        x={x+size/4}
                        y={y}
                        fill={"rgb(252, 141, 98)"}
                        strokeWidth={borderWidth}
                        stroke={borderColor}
                        width={size/4}
                        height={size}
                        style={{ pointerEvents: 'none' }}
                    />
                    <rect
                        x={x+size/4 *2}
                        y={y}
                        fill={"rgb(255, 217, 47)"}
                        strokeWidth={borderWidth}
                        stroke={borderColor}
                        width={size/4}
                        height={size}
                        style={{ pointerEvents: 'none' }}
                    />
                    <rect
                        x={x+size/4 *3}
                        y={y}
                        fill={"rgb(166, 216, 84)"}
                        strokeWidth={borderWidth}
                        stroke={borderColor}
                        width={size/4}
                        height={size}
                        style={{ pointerEvents: 'none' }}
                    />
                  </>
                  :
                  <rect
                      x={x}
                      y={y}
                      fill={fill}
                      strokeWidth={borderWidth}
                      stroke={borderColor}
                      width={size}
                      height={size}
                      style={{ pointerEvents: 'none' }}
                  />
              ),
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          layers={["grid", "axes", "bars", "markers", "legends", LineLayer]}
          role="application"
          tooltip={({ id, value, color, index, indexValue, data }) => (
            <span>
              <svg width={20} height={20}>
                <circle fill={color} r={5} cx={10} cy={10} />
              </svg>
              <strong>{indexValue}</strong> - {Number(value).toLocaleString()}&nbsp;
                    {priceChart ? " Kč" : ""}
            </span>
          )}
          animate={true}
          // motionStiffness={90}
          // motionDamping={15}
      />
  )
}

export default BarGlobalStats
