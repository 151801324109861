import React from "react";
import "./pagewrap.scss"
import PageContainerContent from "./PageContainerContent";

interface iPageWrapContent {
  children: React.ReactNode,
  noContainer?: boolean
}

const PageWrapContent = ({ children, noContainer }: iPageWrapContent) => {

  return (
    <div className="content_wrap">
      {noContainer ?
        children
        :
        <PageContainerContent>
          {children}
        </PageContainerContent>
      }
    </div>
  );
}

export default PageWrapContent;