import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { iOptions } from "../../utils/optionsUtils";


const slice = createSlice({
  name: "enums",
  initialState: {
    items: {} as { [id: string]: iOptions },
    errors: {} as { [id: string]: string }
  },
  reducers: {
    setEnum(state, action: PayloadAction<{ id: string, enumData: iOptions }>) {
      const { id, enumData } = action.payload
      state.items[id] = enumData
    },
    setEnumError(state, action: PayloadAction<{ id: string, error: string }>) {
      const { id, error } = action.payload
      state.errors[id] = error
    }
  }
});

export const selectEnum = (id: string) => (state: RootState) => ({
  data: state.enums.items[id],
  error: state.enums.errors[id],
})

export const { setEnum, setEnumError } = slice.actions;
export default slice.reducer;
