import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import LINK from "../../utils/LINK"
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats"
import StatContractsDocs from "../../components/Stats/StatContractsDocs"
import StatContracts from "../../components/Stats/StatContracts"
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { FilterContextProvider } from "../../context/FilterContext"

const StatsContractsDocsPage = () => {

  return (
    <PageWrap
      title="Struktura dokumentace u zakázek"
      breadcrumbs_items={[
        { label: "Analýzy", link: LINK.stats },
        { label: "Struktura dokumentace u zakázek" },
      ]}
    >
      <FilterContextProvider name={PAGE_TYPES.statsDocs}>
        <FilterBoxStats selectCompanies/>
        <StatContractsDocs/>
        <StatContracts/>
      </FilterContextProvider>
    </PageWrap>
  )
}

export default StatsContractsDocsPage
