import {useState} from "react";

const useFetch = <T extends any>(fetchFce: () => Promise<T>) => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState<T>([] as T)

  const fetch = () => {
    setLoading(true)
    setData([] as T)
    setError('')
    fetchFce()
      .then(data => {
        console.log('fetch response data: ', data)
        setData(data)
      })
      .catch(err => {
        console.error('err fetch: ', err, err.response)
        const errmsg = err.response?.data || err.toString()
        setError(errmsg)
      })
      .finally(() => setLoading(false))
  }

  return {loading, error, data, fetch}
}

export default useFetch;