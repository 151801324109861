import { iStat, iStatsChart, iStatsTypes } from "../api/intrefaces"

export const divideStats = (response: any[]) => {
  const data = {
    sum_kc: {
      type: [],
      itype: [],
      otype: [],
      procedure: [],
    } as iStatsTypes,
    count: {
      type: [],
      itype: [],
      otype: [],
      procedure: [],
    } as iStatsTypes,
    count_sup_value: {
      type: [],
      itype: [],
      otype: [],
      procedure: [],
    } as iStatsTypes,
  }
  response.forEach((stat: iStat) => {
    data.sum_kc[stat.graph_type].push({
      id: stat.code,
      label: stat.name,
      value: parseInt(stat.sum_kc),
    })
    data.count[stat.graph_type].push({
      id: stat.code,
      label: stat.name,
      value: parseInt(stat.count),
    })
    data.count_sup_value[stat.graph_type].push({
      id: stat.code,
      label: stat.name,
      value: parseInt(stat.count_sup_value),
    })
  })
  return data
}

export const prepareStats = (stats: iStatsTypes, allStats: iStatsTypes) => {
  const { type, itype, otype, procedure } = stats

  const sum = {
    type: prepareStatsSum(type),
    itype: prepareStatsSum(itype),
    otype: prepareStatsSum(otype),
    procedure: prepareStatsSum(procedure),
  }

  const percent = {
    type: preparePercent(type, allStats?.type),
    itype: preparePercent(itype, allStats?.itype),
    otype: preparePercent(otype, allStats?.otype),
    procedure: preparePercent(procedure, allStats?.procedure),
  }

  const proctop = prepareStatsSort(procedure, 10)

  const data = {
    type: type,
    otype: otype,
    procedure: proctop,
    itype: itype,
  }
  return { data, sum, percent }
}

export const prepareStatsSort = (dataRaw: iStatsChart[], topN?: number) => {
  const dataSorted = dataRaw
    ? [...dataRaw].sort((a, b) => b.value - a.value)
    : []
  if (!topN) {
    return dataSorted
  }
  const data = dataSorted?.slice(0, topN)
  if (dataSorted.length > topN) {
    data.push({
      id: "Ostatni",
      value: dataSorted?.slice(topN).reduce((acc, cur) => acc + cur.value, 0),
      label: "Ostatní",
    })
  }
  return data
}

export const prepareStatsSortDesc = (dataRaw: iStatsChart[]) => {
  return dataRaw ? [...dataRaw].sort((a, b) => a.value - b.value) : []
}

export const prepareStatsSum = (dataRaw: iStatsChart[]) =>
  dataRaw?.reduce((acc, cur) => acc + cur.value, 0).toLocaleString()

export const prepareStatsSumNum = (dataRaw: iStatsChart[]) =>
    dataRaw?.reduce((acc, cur) => acc + cur.value, 0)

export const prepareStatsSumNumAndNumSeries = (dataRaw: iStatsChart[]) => {
  const sumValue = dataRaw?.reduce((acc, cur) => acc + cur.value, 0)
  if (sumValue >= 1000000000000) {
    return (sumValue / 1000000000000)
  } else if (sumValue >= 1000000000) {
    return (sumValue / 1000000000)
  } else if (sumValue >= 1000000) {
    return (sumValue / 1000000)
  } else if (sumValue >= 1000) {
    return (sumValue / 1000)
  } else {
    return (sumValue)
  }
}

export const prepareStatsSumNumSeries = (dataRaw: iStatsChart[]) => {
  const sumValue = dataRaw?.reduce((acc, cur) => acc + cur.value, 0)
  if (sumValue >= 1000000000000) {
    return "Bil. korun"
  } else if (sumValue >= 1000000000) {
    return "Mld. korun"
  } else if (sumValue >= 1000000) {
    return "Mil. korun"
  } else if (sumValue >= 1000) {
    return "Tis. korun"
  } else {
    return "Korun"
  }
}

export const calcHighestValue = (data: iStatsChart[]) => {
  const objValues = {
    legend: "",
    format: 0
  }
  const allValue = data.map((item) => {
    return item.value
  })
  if (Math.max(...allValue) > 1000000000000) {
    objValues.legend = "cena zakázek v bil. Kč"
    objValues.format = 1000000000000
  } else if (Math.max(...allValue) > 1000000000) {
    objValues.legend = "cena zakázek v mld. Kč"
    objValues.format = 1000000000
  } else if (Math.max(...allValue) > 1000000) {
    objValues.legend = "cena zakázek v mil. Kč"
    objValues.format = 1000000
  } else {
    objValues.legend = "cena zakázek v tis. Kč"
    objValues.format = 1000
  }
  return objValues
}

const preparePercent = (dataRaw: iStatsChart[], allDataRaw: iStatsChart[]) => {
  const count = dataRaw?.reduce((acc, cur) => acc + cur.value, 0)
  const allCount = allDataRaw?.reduce((acc, cur) => acc + cur.value, 0)
  return ((count / allCount) * 100).toFixed(1)
}

// funkce pro přejmenování klíčů v objektech, přidání ID a zaokrouhlení value na jedno desetinné místo
interface OriginalObject {
  id_company: number,
  company_name: string;
  department_name: string;
  contract_count: string;
  contract_percentage: string;
}

interface TransformedObject {
  id: number;
  label: string;
  value: number;
}

export const transformData = (data: OriginalObject[]): TransformedObject[] => {
  const compareCompanyName = (data: OriginalObject[])=> {
    if (data && data.length < 2) {
      return false;
    }
    const firstCompanyName = data[0].company_name;
    for (let i = 1; i < data.length; i++) {
      if (data[i].company_name !== firstCompanyName) {
        return false;
      }
    }
    return true;
  }
  const sameCompanyName = compareCompanyName(data)

  return sameCompanyName ? data.map((item, index) => ({
        id: item.id_company,
        label: item.company_name + " - " + item.department_name,
        value: Math.round(Number(item.contract_percentage) * 10) / 10
      }))
      :
      data.map((item, index) => ({
        id: item.id_company,
        label: item.company_name,
        value: Math.round(Number(item.contract_percentage) * 10) / 10
      }))
      ;
}

// funkce pro sečtení všech hodnot v poli
export const sumValues = (data: OriginalObject[]): number => {
  return data && data.reduce((sum, item) => sum + parseInt(item.contract_count, 10), 0);
}

// funkce pro sečtení všech hodnot v poli a zaokrouhlení výsledku na jedno desetinné místo
export const sumContractPercentages = (data: OriginalObject[]): number => {
  const total =  data && data.reduce((sum, item) => sum + parseFloat(item.contract_percentage), 0);
  // return Math.round(total * 10) / 10;
  return parseFloat(total.toFixed(1));
}