import React, {useState} from "react"
import PageWrapContent from "../../components/PageWrap/PageWrapContent";
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import ContentBox from "../../components/ContentBox/ContentBox"
import LINK from "../../utils/LINK"
import ContractsListLast from "../../components/ContractsListLast/ContractsListLast"
import RatingBoxHome from "../../components/RatingBoxHome/RatingBoxHome"
import CollapseBox from "../../components/CollapseBox/CollapseBox"
import "./homepage.scss"
import PageContainerContent from "../../components/PageWrap/PageContainerContent"
import TimelineHorizontal from "../../components/Timeline/TimelineHorizontal";
import ContentBoxHomeBig from "../../components/ContentBox/ContentBoxHomeBig";
import {FilterContextProvider} from "../../context/FilterContext";
import {PAGE_TYPES} from "../../context/enumPageTypes";
import HomeStatsGroup from "../../components/HomeStat/HomeStatsGroup";
import LinkTo from "../../components/Link/LinkTo";
import { ENV } from '../../ENV'
import Container from "react-bootstrap/Container";
import CloseButton from 'react-bootstrap/CloseButton';
import {Link} from "react-router-dom";

const FILTER_STATE_CODES = {
  stateCodes:
      "EXPORTED, MEVAL, EVALUATION, EVAL_QUAL, MDEALING, POFF_ADV, ORD_CONF, LOCKED, MLOCKED, INTEREST, QUALIFY, OFFERS, MOFFERS, DESIGNS, INITOFFERS, POFFERS, PREP, DEALING, EVALUATED, MEVALUATED, INIT_QUAL, MASSIGNED, ASSIGNED, ASSIGNING, MREADY, READY",
}

const HomePageNew = () => {
  const localStateInfoBar = localStorage.getItem("infoBar") !== "false";
  const [openInfoBar, setOpenInfoBar] = useState(true)

  const handleClick = () => {
    setOpenInfoBar(false)
    localStorage.setItem("infoBar", "false")
  }

  return (
    <>
      {localStateInfoBar && openInfoBar && <Container fluid style={{backgroundColor: "#454545", marginTop: 20}}>
        <Container fluid="md" style={{display: "flex", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5}}>
          <Link to={LINK.newsInfo} style={{color: "#fff"}}>
            Byla vydána nová verze 2.7.0. - Více informací o novinkách zde
          </Link>
          <CloseButton variant="white" onClick={handleClick} />
        </Container>
      </Container>}

      <PageWrapContent>
        <FilterContextProvider name={PAGE_TYPES.home}>
          <CollapseBox>
            <PageContainerContent>
              <ContentBoxHomeBig title="ZADAVATEL">
                Vyberte zadavatele, který vás zajímá, a podívejte se na
                přehledy.
              </ContentBoxHomeBig>

              <Row className="contracts-stats">
                <Col xxl={7} xl={6}>
                  <ContentBox partHeight title="Nejnovější zakázky">
                    <ContractsListLast />

                    <div className="text-end">
                      <LinkTo to={LINK.contractsListRunning}
                              name={PAGE_TYPES.home}
                              stateCodes={FILTER_STATE_CODES.stateCodes}>
                        Přejít do přehledu zakázek
                      </LinkTo>
                    </div>
                  </ContentBox>

                  {!ENV.NEN && <ContentBox partHeight title="QIndex">
                    <RatingBoxHome/>

                    <div className="text-end py-2">
                      <LinkTo to={LINK.contractsList}
                              name={PAGE_TYPES.contratsList}
                              ratingFrom={"0"}
                              ratingTo={"0"}>
                        Zobrazit zakázky s nedostatky
                      </LinkTo>
                    </div>
                  </ContentBox>}
                </Col>

                <Col xxl={5} xl={6}>
                  <HomeStatsGroup/>
                </Col>
              </Row>
            </PageContainerContent>
          </CollapseBox>

          {!ENV.NEN && <CollapseBox>
            <PageContainerContent>
              <Row>
                <TimelineHorizontal/>
              </Row>
            </PageContainerContent>
          </CollapseBox>}
        </FilterContextProvider>
      </PageWrapContent>
    </>
  )
}

export default HomePageNew
