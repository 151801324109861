import React from "react";

import { ResponsiveBar } from '@nivo/bar'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


const data = [
  {
    "country": "AD",
    "hot dog": 30,
    "hot dogColor": "hsl(151, 70%, 50%)",
    "burger": 139,
    "burgerColor": "hsl(83, 70%, 50%)",
    "sandwich": 6,
    "sandwichColor": "hsl(93, 70%, 50%)",
    "kebab": 132,
    "kebabColor": "hsl(270, 70%, 50%)",
    "fries": 71,
    "friesColor": "hsl(108, 70%, 50%)",
    "donut": 26,
    "donutColor": "hsl(143, 70%, 50%)"
  },
  {
    "country": "AE",
    "hot dog": 50,
    "hot dogColor": "hsl(357, 70%, 50%)",
    "burger": 185,
    "burgerColor": "hsl(120, 70%, 50%)",
    "sandwich": 44,
    "sandwichColor": "hsl(68, 70%, 50%)",
    "kebab": 99,
    "kebabColor": "hsl(273, 70%, 50%)",
    "fries": 158,
    "friesColor": "hsl(247, 70%, 50%)",
    "donut": 159,
    "donutColor": "hsl(82, 70%, 50%)"
  },
  {
    "country": "AF",
    "hot dog": 158,
    "hot dogColor": "hsl(156, 70%, 50%)",
    "burger": 175,
    "burgerColor": "hsl(13, 70%, 50%)",
    "sandwich": 76,
    "sandwichColor": "hsl(158, 70%, 50%)",
    "kebab": 51,
    "kebabColor": "hsl(9, 70%, 50%)",
    "fries": 19,
    "friesColor": "hsl(129, 70%, 50%)",
    "donut": 74,
    "donutColor": "hsl(53, 70%, 50%)"
  },
  {
    "country": "AG",
    "hot dog": 9,
    "hot dogColor": "hsl(26, 70%, 50%)",
    "burger": 176,
    "burgerColor": "hsl(183, 70%, 50%)",
    "sandwich": 178,
    "sandwichColor": "hsl(174, 70%, 50%)",
    "kebab": 58,
    "kebabColor": "hsl(244, 70%, 50%)",
    "fries": 115,
    "friesColor": "hsl(246, 70%, 50%)",
    "donut": 70,
    "donutColor": "hsl(359, 70%, 50%)"
  },
  {
    "country": "AI",
    "hot dog": 131,
    "hot dogColor": "hsl(12, 70%, 50%)",
    "burger": 4,
    "burgerColor": "hsl(262, 70%, 50%)",
    "sandwich": 73,
    "sandwichColor": "hsl(278, 70%, 50%)",
    "kebab": 162,
    "kebabColor": "hsl(190, 70%, 50%)",
    "fries": 171,
    "friesColor": "hsl(132, 70%, 50%)",
    "donut": 26,
    "donutColor": "hsl(188, 70%, 50%)"
  },
  {
    "country": "AL",
    "hot dog": 134,
    "hot dogColor": "hsl(213, 70%, 50%)",
    "burger": 156,
    "burgerColor": "hsl(278, 70%, 50%)",
    "sandwich": 95,
    "sandwichColor": "hsl(352, 70%, 50%)",
    "kebab": 115,
    "kebabColor": "hsl(75, 70%, 50%)",
    "fries": 170,
    "friesColor": "hsl(34, 70%, 50%)",
    "donut": 38,
    "donutColor": "hsl(322, 70%, 50%)"
  },
  {
    "country": "AM",
    "hot dog": 35,
    "hot dogColor": "hsl(73, 70%, 50%)",
    "burger": 155,
    "burgerColor": "hsl(345, 70%, 50%)",
    "sandwich": 33,
    "sandwichColor": "hsl(78, 70%, 50%)",
    "kebab": 145,
    "kebabColor": "hsl(55, 70%, 50%)",
    "fries": 170,
    "friesColor": "hsl(65, 70%, 50%)",
    "donut": 112,
    "donutColor": "hsl(140, 70%, 50%)"
  }
]

const NivoBar = () => (
  <ResponsiveBar
    data={data}
    keys={[ 'hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut' ]}
    indexBy="country"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={{ scheme: 'nivo' }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: '#38bcb2',
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#eed312',
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }
    ]}
    fill={[
      {
        match: {
          id: 'fries'
        },
        id: 'dots'
      },
      {
        match: {
          id: 'sandwich'
        },
        id: 'lines'
      }
    ]}
    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'country',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'food',
      legendPosition: 'middle',
      legendOffset: -40
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    animate={true}
    // motionStiffness={90}
    // motionDamping={15}
  />
)

export default NivoBar;