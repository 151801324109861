import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import ContractorsListAll from "../../components/ContractorsListAll/ContractorsListAll";
import Paginator from "../../components/Paginator/Paginator";
import FilterBoxContractors from "../../components/Filters/Box/FilterBoxContractors";
import FilterOrder from "../../components/Filters/FilterOrder";
import LINK from "../../utils/LINK";
import {FilterContextProvider} from "../../context/FilterContext";
import {PAGE_TYPES} from "../../context/enumPageTypes";

const ContractorsPage = () => {
  return (
    <PageWrap title="Dodavatelé" breadcrumbs_items={[
      {label: "Audit", link: LINK.audit},
      {label: "Dodavatelé"}
    ]}>
      <FilterContextProvider name={PAGE_TYPES.contractors}>
        <FilterBoxContractors />
        <div className="mb-2 ">
          <FilterOrder maximWidth={280}
            orderOptions={[
              { label: "Název uchazeče (A-Z)", value: "name ASC" },
              { label: "Název uchazeče (Z-A)", value: "name DESC" },
              { label: "IČO - vzestupně", value: "ic ASC" },
              { label: "IČO - sestupně", value: "ic DESC" },
              { label: "Adresa (A-Z)", value: "address ASC" },
              { label: "Adresa (Z-A)", value: "address DESC" },
              { label: "Oddělení (A-Z)", value: "department_name ASC" },
              { label: "Oddělení (Z-A)", value: "department_name DESC" },
              { label: "Země (A-Z)", value: "country_name ASC" },
              { label: "Země (Z-A)", value: "country_name DESC" },
              { label: "Počet účastí - vzestupně", value: "count_total ASC" },
              { label: "Počet účastí - sestupně", value: "count_total DESC" },
              { label: "Počet vítězství - vzestupně", value: "count_winner ASC" },
              { label: "Počet vítězství - sestupně", value: "count_winner DESC" },
              { label: "Vysoutěžených VZ - vzestupně", value: "count_percent ASC" },
              { label: "Vysoutěžených VZ - sestupně", value: "count_percent DESC" },
            ]}
          />
        </div>
        <ContractorsListAll />
        <Paginator />
      </FilterContextProvider>
    </PageWrap>
  );
};

export default ContractorsPage;
