import React, { useState } from "react"
import ContractsList from "../ContractsList/ContractsList"
import Paginator from "../Paginator/Paginator"
import { Button } from "react-bootstrap"
import Row from "react-bootstrap/Row";
import FilterOrder from "../Filters/FilterOrder";
import {useFilterContext} from "../../context/FilterContext";

interface StatContractsProps {
  isContractPlaces?: boolean
}

const StatContracts = ({isContractPlaces}: StatContractsProps) => {
  const [showContracts, setShowContracts] = useState(false)
  const {activeFiltersChart} = useFilterContext()

  //   const btnRef: React.RefObject<HTMLButtonElement> = useRef(null)

  //   useEffect(() => {
  //     if (btnRef.current && showContracts) {
  //       setTimeout(() => {
  //         btnRef?.current?.scrollIntoView({
  //           behavior: "smooth",
  //           block: "start",
  //         })
  //       }, 500)
  //     }
  //   }, [showContracts])

  return (
    <div>
      <Button
        // ref={btnRef}
        onClick={() => {
          const nextShowContracts = !showContracts
          setShowContracts(nextShowContracts)
        }}
        style={{ marginBottom: "2rem" }}
      >
        {`${showContracts ? "Skrýt" : "Zobrazit"} zakázky`}
      </Button>

      {showContracts && (
        <>
          <Row className="mb-2 justify-content-end">
            <FilterOrder
                orderOptions={[
                  { label: "Datum zahájení - vzestupně", value: "date_start ASC" },
                  { label: "Datum zahájení - sestupně", value: "date_start DESC" },
                  { label: "Hodnota - vzestupně", value: "sup_value ASC" },
                  { label: "Hodnota - sestupně", value: "sup_value DESC" },
                  { label: "Číslo VZ - vzestupně", value: "system_number ASC" },
                  { label: "Číslo VZ - sestupně", value: "system_number DESC" },
                ]}
            />
          </Row>
          <ContractsList isContractPlaces={isContractPlaces} filter={activeFiltersChart} charts={true} />
          <Paginator />
        </>
      )}
    </div>
  )
}
export default StatContracts
