import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import LINK from "../../utils/LINK"
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats"
import StatContractsCount from "../../components/Stats/StatContractsCount"
import StatContracts from "../../components/Stats/StatContracts"
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { FilterContextProvider } from "../../context/FilterContext"

const StatsContractsCountPage = () => {

  return (
    <PageWrap
      title="Zakázky za dané období"
      breadcrumbs_items={[
        { label: "Analýzy", link: LINK.stats },
        { label: "Zakázky za dané období" },
      ]}
    >
      <FilterContextProvider name={PAGE_TYPES.statsCount}>
        <FilterBoxStats selectCompanies/>
        <StatContractsCount/>
        <StatContracts/>
      </FilterContextProvider>
    </PageWrap>
  )
}

export default StatsContractsCountPage
