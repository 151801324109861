import React, {useEffect} from "react";
import { ResponsiveBar } from "@nivo/bar"
import ContentBox from "../ContentBox/ContentBox"
import AlertInfo from "../Alerts/AlertInfo";
import {setFilterCharts, setFilter} from "../../redux/filters/filtersSlice";
import { useDispatch } from "react-redux";
import {useFilterContext} from "../../context/FilterContext"
import {useGraphFilterContractors} from "../../hooks/useFilterContractors";
import Loading from "../Loading/Loading";
import AlertError from "../Alerts/AlertError";
import FilterStat from "../Stats/FilterStat";
import {convertToNumAndRound} from "../../utils/prepareContractorsStats"

interface iBarContracts {
  priceChart?: boolean,
  filterName: string
}

const MAX_BOTTOM_LABEL_LEN = 20;

const BarContractors = ({filterName, priceChart}: iBarContracts) => {
  const dispatch = useDispatch()
  const {contextStatsName} = useFilterContext()
  const { contractors, error, loading, setInitFetch } = useGraphFilterContractors()

  function handleClick(node: any) {
    if (filterName) {
      dispatch(setFilter({
        name: contextStatsName, filters: {name: filterName, companyId: node.data.id_company.toString()}
      }))
      && dispatch(setFilterCharts({
        name: contextStatsName, filters: {name: filterName, value: node.data.name}
      }));
    }
  }

  useEffect(() => {
    setInitFetch();
  }, [setInitFetch]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <AlertError msg={error} />;
  }
  if (!contractors || contractors.length === 0) {
    return <AlertInfo msg="Žádní dodavatelé"/>
  }

  const preparedData = convertToNumAndRound(contractors)

  return (
    <>
      <div className="sticky-top">
        <FilterStat/>
        {loading && <Loading/>}
      </div>

      {preparedData.length !== 0 ? <ContentBox title="TOP 10 dodavatelů s nejvyšší hodnotou v uhrazených cenách">
        <div style={{width: "100%", height: 600}}>
          <ResponsiveBar
              data={preparedData}
              keys={["Součet uhrazené ceny"]}
              indexBy="name"

              groupMode={"grouped"}
              margin={{top: 20, right: 20, bottom: 150, left: 110}}
              padding={contractors.length < 4 ? 0.8 : 0.2} //between group bars
              indexScale={{type: 'band', round: true}}

              colors={{scheme: 'accent'}}
              borderColor="#ffffff"
              borderWidth={1}

              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -30,
                format: label => label.length > MAX_BOTTOM_LABEL_LEN + 3 ? label.substr(0, MAX_BOTTOM_LABEL_LEN) + '...' : label,
                legend: "dodavatelé",
                legendPosition: "end",
                legendOffset: 40
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "hodnota v Kč",
                legendPosition: "middle",
                legendOffset: -100,
                format: val => Number(val).toLocaleString()
              }}

              enableLabel={false}
              labelFormat={value => priceChart ? `${Number(value).toLocaleString()} Kč` : Number(value).toLocaleString()}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{from: "color", modifiers: [["darker", 1.6]]}}

              onClick={(node, event) => {
                console.log("nodee click", node)
                handleClick(node)
              }}

              tooltip={({id, value, color, index, indexValue}) => (
                  <span>
            <svg width={20} height={20}>
              <circle fill={color} r={5} cx={10} cy={10}/>
            </svg>
            <strong>{indexValue}</strong> - {Number(value).toLocaleString()}&nbsp;{priceChart ? " Kč" : ""}
          </span>
              )}

              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-left",
                  direction: "column",
                  justify: false,
                  translateX: -80,
                  translateY: 150,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              animate={true}
          />
        </div>
      </ContentBox>
      :
      <ContentBox>
        <AlertInfo msg={`Nejsou dostupná žádná data pro graf`} />
      </ContentBox>}
    </>
  )
}

export default BarContractors;