import {useDispatch} from "react-redux";
import {useFilterContext} from "../../context/FilterContext";
import {setFilter} from "../../redux/filters/filtersSlice";
import {Link} from "react-router-dom";
import React from "react";

interface iLinkTo {
  to: string,
  name: string,
  ratingFrom?: string,
  ratingTo?: string,
  stateCodes?: string,
  children: React.ReactNode
}
const LinkTo = ({ to, name, ratingFrom, ratingTo, stateCodes, children }: iLinkTo) => {
  const dispatch = useDispatch();
  const {activeFilters} = useFilterContext()

  const filters = {
    ...activeFilters,
    stateCodes: stateCodes,
    ratingFrom: ratingFrom,
    ratingTo: ratingTo
  }

  const doFilter = () => {
    dispatch(setFilter({name: name, filters: filters}))
  }

  return (
      <Link to={to} onClick={doFilter}>
        {children}
      </Link>
  )
}

export default LinkTo