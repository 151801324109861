import { useEffect } from "react"
import {useDispatch, useSelector} from "react-redux";
import {selectDepartments} from "../redux/filters/filtersSlice";
import {fetchDepartments} from "../redux/filters/departmentsAction";

export const useOptionDepartment = (companyId: number | string) => {
  const dispatch = useDispatch()
  const { departments, error, loading } = useSelector(selectDepartments)

  useEffect(() => {
    if (companyId) {
      dispatch(fetchDepartments(companyId));
    }
  }, [companyId, dispatch])

  const departmentOptions = departments
    .map(c => ({
      value: c.id_department.toString(),
      label: c.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return { departmentOptions, loading, error }
}
