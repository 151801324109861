import React, {useEffect} from "react"
import PageWrap from "../../components/PageWrap/PageWrap";
import LINK from "../../utils/LINK";
import FilterBoxAudit from "../../components/Filters/Box/FilterBoxAudit";
import Paginator from "../../components/Paginator/Paginator";
import ContractsAuditList from "../../components/ContractsList/ContractsAuditList";
import {FilterContextProvider} from "../../context/FilterContext";
import {PAGE_TYPES} from "../../context/enumPageTypes";
import FilterOrder from "../../components/Filters/FilterOrder";
import CheckboxDropdown from "../../components/Filters/Select/DropdownWithCheckboxs";
import tableLegendQualitativeAnalyse from "./contractsQualitativeAnalysis.json";
import {useDispatch, useSelector} from "react-redux";
import {selectShowOrHideColumnsSetting, setShowOrHideColumnsSetting} from "../../redux/settings/settingsSlice";

const AuditQualitativeAnalysisPage = () => {
  const dispatch = useDispatch()
  const tableColumnsLegend = useSelector(selectShowOrHideColumnsSetting)
  const localStorageTableLegendQA = localStorage.getItem("tableLegendQualitativeAnalyse")

  useEffect(() => {
    localStorageTableLegendQA ?
        dispatch(setShowOrHideColumnsSetting(JSON.parse(localStorageTableLegendQA)))
        :
        !Object.keys(tableColumnsLegend).length && dispatch(setShowOrHideColumnsSetting(tableLegendQualitativeAnalyse))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
      <PageWrap title="QIndex - kvalitativní analýza dat VZ"
                breadcrumbs_items={[
                  {label: "Audit", link: LINK.audit},
                  {label: "QIndex - kvalitativní analýza dat VZ"},
                ]}>
        <FilterContextProvider name={PAGE_TYPES.qindex}>
          <FilterBoxAudit/>
          <div className="container-full-width">
            <div className="mb-2 d-flex justify-content-end flex-order flex-wrap" style={{paddingLeft: 12}}>
              <FilterOrder maximWidth={410}
                 style={{paddingRight: 0, paddingBottom: 4}}
                 orderOptions={[
                   {label: "Evidenční číslo VZ na Profilu zadavatele - vzestupně", value: "system_number ASC"},
                   {label: "Evidenční číslo VZ na Profilu zadavatele - sestupně", value: "system_number DESC"},
                   {label: "QIndex - vzestupně", value: "rating ASC"},
                   {label: "QIndex - sestupně", value: "rating DESC"},
                   {label: "Název veřejné zakázky (A-Z)", value: "contract_name ASC"},
                   {label: "Název veřejné zakázky (Z-A)", value: "contract_name DESC"},
                   {label: "Evidenční číslo VZ ve VVZ - vzestupně", value: "contract_mark ASC"},
                   {label: "Evidenční číslo VZ ve VVZ - sestupně", value: "contract_mark DESC"},
                   {label: "Identifikátor profilu zadavatele ve VVZ - vzestupně", value: "company_vvz_profile_id ASC"},
                   {label: "Identifikátor profilu zadavatele ve VVZ - sestupně", value: "company_vvz_profile_id DESC"},
                   {label: "Název zadavatele (A-Z)", value: "company_name ASC"},
                   {label: "Název zadavatele (Z-A)", value: "company_name DESC"},
                   {label: "IČO zadavatele - vzestupně", value: "company_ic ASC"},
                   {label: "IČO zadavatele - sestupně", value: "company_ic DESC"},
                   {label: "Druh ZŘ (A-Z)", value: "name_procedure ASC"},
                   {label: "Druh ZŘ (Z-A)", value: "name_procedure DESC"},
                   {label: "Stav ZŘ (A-Z)", value: "name_state ASC"},
                   {label: "Stav ZŘ (Z-A)", value: "name_state DESC"},
                   {label: "Předpokládaná hodnota - vzestupně", value: "sup_value ASC"},
                   {label: "Předpokládaná hodnota - sestupně", value: "sup_value DESC"},
                   {label: "Název vybraného uchazeče (A-Z)", value: "winner_name ASC"},
                   {label: "Název vybraného uchazeče (Z-A)", value: "winner_name DESC"},
                   {label: "Datum uzavření smlouvy - vzestupně", value: "date_agreement ASC"},
                   {label: "Datum uzavření smlouvy - sestupně", value: "date_agreement DESC"},
                   {label: "Celková smluvní cena bez DPH - vzestupně", value: "wovat_value ASC"},
                   {label: "Celková smluvní cena bez DPH - sestupně", value: "wovat_value DESC"},
                   {label: "Celková smluvní cena vč. DPH - vzestupně", value: "vat_value ASC"},
                   {label: "Celková smluvní cena vč. DPH - sestupně", value: "vat_value DESC"},
                   {label: "Skutečně uhrazená cena bez DPH - vzestupně", value: "sum_wovat_value ASC"},
                   {label: "Skutečně uhrazená cena bez DPH - sestupně", value: "sum_wovat_value DESC"},
                   {label: "Skutečně uhrazená cena vč. DPH - vzestupně", value: "sum_vat_value ASC"},
                   {label: "Skutečně uhrazená cena vč. DPH - sestupně", value: "sum_vat_value DESC"},
                   {label: "Datum zrušení u zrušené VZ - vzestupně", value: "date_canceled ASC"},
                   {label: "Datum zrušení u zrušené VZ - sestupně", value: "date_canceled DESC"},
                 ]}
              />
              {Object.keys(tableColumnsLegend).length > 0
                && <CheckboxDropdown items={tableColumnsLegend} />
              }
            </div>
            <ContractsAuditList tableLegend={tableColumnsLegend} />
            <Paginator style={{marginLeft: "10px", marginRight: "10px"}}/>
          </div>
        </FilterContextProvider>
      </PageWrap>
  );
}

export default AuditQualitativeAnalysisPage;