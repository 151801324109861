import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import ContentBox from "../../components/ContentBox/ContentBox";
import Accordion from 'react-bootstrap/Accordion'
import imgOdhlasit from './imgs/odhlasit.png'

const HelpPage = () => {

  return (
    <PageWrap title="Nápověda"
              breadcrumbs_items={[
                {label: "Nápověda"},
              ]}>

      <ContentBox>
        <h2 className="text-primary">
          Uživatelská příručka:
        </h2>

        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              1. Úvod
            </Accordion.Header>
            <Accordion.Body>
              <p>
                MVZ – Manažer veřejných zakázek - poskytuje vedoucím pracovníkům úřadů (tajemníkům, starostům, ředitelům
                atp.) nástroj na získávání manažerských informací o probíhajících i ukončených veřejných zakázkách
                organizace. Modul přehledně nabízí cenné informace pro rozhodování a kontrolování veřejného zadávání v
                organizaci. Rozhodovacím faktorem veřejných zakázek je hodnota kvality určená na základě bodového
                ohodnocení stanovené výpočetním algoritmem.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              2. Požadavky na provoz systému
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Manažer veřejných zakázek je vícevrstvou webovou aplikací a pro provozování na straně uživatele stačí
                běžný internetový prohlížeč.
              </p>

              Seznam používaných prohlížečů:
              <ul>
                <li>Google Chrome</li>
                <li>Microsoft Edge</li>
                <li>Mozilla Firefox</li>
                <li>Safari</li>
                <li>Není vyloučeno použití i jiných prohlížečů. Aplikace byla v uvedených prohlížečích testována.
                  Ostatní prohlížeče nejsou ze strany provozovatele podporovány.
                </li>
              </ul>
              <p>
                Provoz aplikace podléhá bezpečnostnímu standardu na úrovni zabezpečeného komunikačního protokolu https
                zajištěným digitálním certifikátem. Dále jsou ke zvýšení bezpečnosti použity hashovací, kódovací a
                kryptografické funkce.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          {/*<Accordion.Item eventKey="3">*/}
          {/*  <Accordion.Header>*/}
          {/*    3. Registrace uživatele*/}
          {/*  </Accordion.Header>*/}
          {/*  <Accordion.Body>*/}
          {/*    <p>*/}
          {/*      Uživatel je veden procesem k vytvoření registrace včetně ověření prostřednictvím e-mailu. Vytvořený*/}
          {/*      základní profil je vhodné v části „Profil uživatele“ nastavit dle požadavků uživatele.*/}
          {/*    </p>*/}
          {/*  </Accordion.Body>*/}
          {/*</Accordion.Item>*/}

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              3. Přihlášení a odhlášení
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Na úvodní obrazovce se zobrazí přihlašovací okno, kde je nutné vložit login a heslo. Po ověření správně
                zadaných údajů na serverové straně je uživateli zobrazena stránka dashboard.
              </p>
              <p>
                Odhlášení se provede kliknutím/tapnutím na tlačítko Odhlásit nacházející se v pravém horním rohu záhlaví
                aplikace.
                <img src={imgOdhlasit} alt="Odhlasit"/>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              4. Členění aplikace
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Záhlaví s menu obsahuje odkazy na hlavní části nabídky umístěné pod logem aplikace. V pravé části jsou
                zobrazeny informace o organizaci, napojených nástrojích, informace o uživateli a volba odhlášení.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>
              5. Dashboard
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Přehledový dashboard nabízí rychlý přehled o zadávacích řízeních a veřejných zakázkách organizace a
                nejbližších událostech v oblasti VZ.
              </p>
              <p>
                Tabulka "Nejnovější zakázky" zobrazuje nejnovější zakázky zveřejněné organizací.
                Dlaždice a grafy ukazují přehled probíhajících ZŘ a VZ. V grafu lze zvolit, zda je zobrazen počet
                zakázek nebo jejich hodnota v Kč.
              </p>
              <p>
                Ukazatel Přehled kvality dat zakázek dle zadané metriky hodnotí kvalitu zadávaných dat, která usnadňují
                vyhledávání veřejné zakázky pro dodavatele.
              </p>
              <p>
                Časová osa událostí zobrazuje aktuální a nejbližší události VZ.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>
              6. Přehled zakázek
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Obsahem stránky je celkový přehled zakázek.
              </p>
              <p>
                V úvodní části je filtr, kde je možné vybrat zakázky dle zadaných parametrů.
              </p>
              <p>
                U jednotlivých zakázek je možné po kliknutí na tlačítko "Detail" zobrazit podrobnosti zakázky
                a položka "Zdrojový systém" umožňuje přímý přístup na zakázku v E-ZAKu, pokud má uživatel do E-ZAKu přístup.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>
              7. Události zakázek
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Tato informační část je koncipována pro zobrazení událostí a termínů veřejných zakázek. Forma zobrazení
                je zvolena tabulkou s filtrováním v úvodní části stránky.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Accordion.Header>
              8. Analýzy
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Stránka obsahuje analýzy VZ společnosti s odkazy ve formě dlaždic / widgets s popisem definujícím
                příslušné analytické zaměření sestavy či grafu. Grafy jsou interaktivní reagující na kliknutí myši /
                tapnutí k aktivování filtru. Následně dojde ke změně ostatních grafů dané stránky analýzy dle
                aktivovaného filtru. Odstranění filtru lze za použití křížku u názvu filtrované položky.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Accordion.Header>
              9. Globální ukazatele
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Stránka obsahuje analýzy dat VZ organizace a jejich porovnání s globálními parametry. Globální parametry
                používané ke srovnání jsou vypočítány dle stejných vzorců jako parametry organizace z veřejně dostupných
                dat.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10">
            <Accordion.Header>
              10. Audit
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Stránka obsahuje soubor analýz ve formě dlaždic / widgets s popisem definující příslušné auditní sestavy
                či grafy. Tyto analýzy napomáhají identifikovat slabé stránky či potenciální rizika spojená se zadáváním
                VZ.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="11">
            <Accordion.Header>
              11. Profil uživatele
            </Accordion.Header>
            <Accordion.Body>
              <p>
                V Profilu uživatele jsou údaje o přihlášeném uživateli včetně seznamu dostupných organizací/zadavatelů.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="12">
            <Accordion.Header>
              12. Servisní podpora
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Kontakty pro nahlášení závady či nefunkčnosti:
              </p>
              <p>
                Email: podpora@qcm.cz
                <br/>
                Telefon: 538 702 719
              </p>
              <p>
                Servisní podpora je dostupná od 8:00 do 17:00 v pracovních dnech mimo státní svátky.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="13">
            <Accordion.Header>
              13. Seznam použitých zkratek
            </Accordion.Header>
            <Accordion.Body>
              <p>
                CDD – centrální databáze dodavatelů
                <br/>
                CPV – Common Procurement Vocabulary - unikátní klasifikační systém, jehož účelem je přesný popis
                předmětu veřejné zakázky
                <br/>
                E-ZAK – certifikovaný nástroj pro zadávání veřejných zakázek
                <br/>
                IČO – identifikační číslo osoby nebo organizace
                <br/>
                ID – identifikace
                <br/>
                IEN – Individiální elektronický nástroj
                <br/>
                DPH – Daň z přidané hodnoty
                <br/>
                NUTS – Nomenclature of Units for Territorial Statistics - klasifikace územních statistických jednotek
                <br/>
                URL VZ – Uniform Resource Locator – webová adresa VZ
                <br/>
                VVZ – Věstník veřejných zakázek
                <br/>
                VZ – Veřejná zakázka
                <br/>
                ZŘ – Zadávací řízení
                <br/>
                ZZVZ – Zákon o zadávání veřejných zakázek (134/2016 Sb.)
              </p>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion>

      </ContentBox>
    </PageWrap>
  );
}

export default HelpPage;