import React, { useEffect } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import LINK from "../../utils/LINK"
import { DATE_FORMAT_DAY } from "../../utils/utils"
import { iContract } from "../../api/intrefaces"
import { useFilterContractsLast } from "../../hooks/useFilterContractsLast"
import { getOptionLabel } from "../../utils/optionsUtils"
import { ENUM_ID } from "../../utils/OPTIONS"
import { useEnums } from "../../hooks/useEnum"
import AlertError from "../Alerts/AlertError"
import Loading from "../Loading/Loading"
import AlertInfo from "../Alerts/AlertInfo"
import TableBox from "../ContentBox/TableBox"

const ContractsListLast = () => {
  const { data: enum_state } = useEnums(ENUM_ID.state)

  const { contractsList, error, loading, setInitFetch } =
    useFilterContractsLast()

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])
  if (error) {
    return <AlertError msg={error} />
  }
  if (loading) {
    return <Loading />
  }
  if (!contractsList || contractsList.length === 0) {
    return <AlertInfo msg="Žádné aktivní zakázky" />
  }
  return (
    <>
      <TableBox>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Název</th>
            <th className="easy-tooltip">
              Hodnota
              <span className="tooltip-text" style={{width: "120px"}}>{"Předpokládaná hodnota"}</span>
            </th>
            <th>Fáze</th>
          </tr>
        </thead>
        <tbody>
          {contractsList.map((contract: iContract, index: number) => (
            <tr key={index}>
              <td>{moment(contract.date_start).format(DATE_FORMAT_DAY)}</td>
              <td>
                <Link to={`${LINK.contractDetail}/${contract.id_contract}`}>
                  {contract.contract_name}
                </Link>
              </td>
              <td>{Number(contract.sup_value).toLocaleString()}</td>
              <td>{getOptionLabel(enum_state, contract.code_state)}</td>
            </tr>
          ))}
        </tbody>
      </TableBox>
    </>
  )
}

export default ContractsListLast
