import axios from "axios"
import {
  iCriteriaContractActions,
  iCriteriaContracts,
  iCriteriaContractsPeriod,
  iCriteriaContractsRatings,
  iCriteriaGlobalContracts,
  iCriteriaStatsDash,
  iTimeline,
} from "./intrefaces"
import {interceptorRequestAuthToken} from "./interceptorRequestAuthToken";
import {interceptorResponseAuthToken} from "./interceptorResponseAuthToken";
import { ENV } from "../ENV";

class ServerApi {
  api = axios.create({
    baseURL: ENV.API_URL,
  })

  constructor() {
    interceptorRequestAuthToken(this.api)
    interceptorResponseAuthToken(this.api)
  }

  getContractOtypes = () =>
    this.api.get(`/enums/contract_otypes`).then(res => res.data)
  getContractProcedures = () =>
    this.api.get(`/enums/contract_procedures`).then(res => res.data)
  getContractStates = () =>
    this.api.get(`/enums/contract_states`).then(res => res.data)
  getContractTypes = () =>
    this.api.get(`/enums/contract_types`).then(res => res.data)
  getContractCompanies = () =>
    this.api.get(`/enums/companies`).then(res => res.data)

  getContractNutsCodes = () =>
    this.api.get(`/enums/nuts_codes`).then(res => res.data)

  getDepartmentsByCompanyId = (id: number | string) =>
    this.api.get(`/enums/departments_by_company_id/${id}`).then(res => res.data)

  getContractById = (id: number | string) =>
    this.api.get(`/contract/by_id/${id}`).then(res => res.data)

  getContractActionById = (id: number | string) =>
    this.api.get(`/contract_actions/by_id/${id}`).then(res => res.data)

  getContractActionsByContractId = (id: number | string) =>
    this.api.get(`/contract_actions/by_contract_id/${id}`).then(res => res.data)

  getContractActionsByCriteria = (criteria: iCriteriaContractActions) =>
    this.api
      .post(`/contract_actions/by_criteria/`, criteria)
      .then(res => ({
        data: res.data,
        totalCount: parseInt(res.headers['x-total-count']),
      }))

  getContractContendersByContractId = (id: number | string) =>
    this.api
      .get(`/contract_contenders/by_contract_id/${id}`)
      .then(res => res.data)

  getContractDocumentsByContractId = (id: number | string) =>
    this.api
      .get(`/contract_documents/by_contract_id/${id}`)
      .then(res => res.data)

  getContractHistoryStatesById = (id: number | string) =>
    this.api
      .get(`/contract_history_states/by_contract_id/${id}`)
      .then(res => res.data)

  getContractsByCriteria = (
    criteria: iCriteriaContracts,
  ) =>
    this.api
      .post(`/contracts/by_criteria`, criteria)
      .then((res) => ({
        data: res.data,
        totalCount: parseInt(res.headers['x-total-count']),
      }))

  getStatsContractsByCriteria = (criteria: iCriteriaContractsPeriod) =>
    this.api
      .post(`/stats_contracts/by_criteria`, criteria)
      .then(res => res.data)

  getStatsContendersByCriteria = (criteria: iCriteriaContractsPeriod) =>
    this.api
      .post(`/stats_contenders/by_criteria`, criteria)
      .then(res => res.data)

  getStatsContractorsByCriteria = (criteria: iCriteriaContractsPeriod) =>
    this.api
      .post(`/stats_contractors/by_criteria`, criteria)
        .then((res) => ({
          data: res.data,
          totalCount: parseInt(res.headers['x-total-count']),
        }))

  getStatsDocsByCriteria = (criteria: iCriteriaContractsPeriod) =>
    this.api.post(`/stats_docs/by_criteria`, criteria).then(res => res.data)

  getStatsPlacesByCriteria = (criteria: iCriteriaContractsPeriod) =>
    this.api.post(`/stats_places/by_criteria`, criteria).then(res => res.data)

  getStatsStatesByCriteria = (criteria: iCriteriaContractsPeriod) =>
    this.api.post(`/stats_states/by_criteria`, criteria).then(res => res.data)

  getStatsStartedStatesByCriteria = (criteria: iCriteriaContractsPeriod) =>
      this.api.post(`/stats_started_states/by_criteria`, criteria).then(res => res.data)

  getStatsWinnersOfferPriceByCriteria = (criteria: iCriteriaContractsPeriod) =>
    this.api
        .post(`/stats_winners_offer_price/by_criteria`, criteria)
        .then(res => ({
          data: res.data,
          totalCount: parseInt(res.headers['x-total-count']),
        }))

  getStatsWinnersSupValueByCriteria = (criteria: iCriteriaContractsPeriod) =>
      this.api
          .post(`/stats_winners_sup_value/by_criteria`, criteria)
          .then(res => ({
            data: res.data,
            totalCount: parseInt(res.headers['x-total-count']),
          }))

  getContractsRatingsByCriteria = (criteria: iCriteriaContractsRatings) =>
    this.api
      .post(`/contracts_ratings/by_criteria`, criteria)
      .then(res => res.data)

  getContractContractorsByCriteria = (criteria: iCriteriaContractActions) =>
    this.api
      .post(`/contract_contractors/by_criteria`, criteria)
      .then(res => ({
        data: res.data,
        totalCount: parseInt(res.headers['x-total-count']),
      }))

  getCompanyByCompanyId = (id: number | string) =>
    this.api.get(`/company/by_id/${id}`).then(res => res.data)

  getCpvCodesByCompanyId = (id: number | string) =>
    this.api.get(`/enums/cpv_codes_by_company_id/${id}`).then(res => res.data)

  getGlobalStatsByCriteria = (criteria: iCriteriaGlobalContracts) =>
    this.api.post(`/global_stats/by_criteria`, criteria).then(res => res.data)

  getContractActionsTimeline = (criteria: iTimeline) =>
    this.api
      .post(`/contract_actions/timeline`, criteria)
      .then(res => res.data)

  getPreContractsDashboard = (criteria: iCriteriaStatsDash) =>
      this.api
          .post(`/stats_dashboard/pre_contracts`, criteria)
          .then(res => res.data)

  getActiveContractsDashboard = (criteria: iCriteriaStatsDash) =>
      this.api
          .post(`/stats_dashboard/active_contracts`, criteria)
          .then(res => res.data)

  getContractsAuditByCriteria = (
      criteria: iCriteriaContracts,
  ) =>
      this.api
          .post(`/contracts_audit/by_criteria`, criteria)
          .then(res => ({
            data: res.data,
            totalCount: parseInt(res.headers['x-total-count']),
          }))

  getTimestampImportDB = () =>
      this.api
          .get(`/timestamp_import_db`)
          .then(res => res.data)
}

const ServerApiInstance = new ServerApi()
export default ServerApiInstance
