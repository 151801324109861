import React, { useEffect } from "react"
import Loading from "../Loading/Loading"
import AlertError from "../Alerts/AlertError"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ContentBox from "../ContentBox/ContentBox"
import ServerApi from "../../api/ServerApi"
import BarGlobalStats from "../ChartsNivo/BarGlobalStats";
import useFetch from "../../hooks/useFetch";
import { iGlobalStatsByCriteria } from "../../api/intrefaces";
import { preparedData } from "../../utils/prepareGlobalStats"
import dataGlobalIndicators from "../../pages/GlobalIndicatorsPage/dataGlobalIndicators.json"
import AlertInfo from "../Alerts/AlertInfo";
import {useFilterContext} from "../../context/FilterContext";

const total: string = "Total"

const StatContractsDates = () => {
  const {activeFilters} = useFilterContext()
  const  { data: contracts, error, loading, fetch } = useFetch<iGlobalStatsByCriteria[]>(() =>
      ServerApi.getGlobalStatsByCriteria({
        ...activeFilters,
        supValueFrom: 1,
        offerCountFrom: 1
      })
  )

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters])

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error} />
  }

  const dodDataPrepared = preparedData(contracts, dataGlobalIndicators, activeFilters, "DOD")
  const sluDataPrepared = preparedData(contracts, dataGlobalIndicators, activeFilters, "SLU")
  const staDataPrepared = preparedData(contracts, dataGlobalIndicators, activeFilters, "STA")

  function renderGraph(title: string, data: any) {
    return (
      <Col>
        {data.length !== 0 ?
          <ContentBox title={`Průměrný počet nabídek ${data[0].procedureCode === total ? "celkem " : ""}- ${title}`}>
            <div className="wrapper-graphs" style={{height: 450}}>
              <div id="expectedPriceAnalysis" style={{height: 450}}>
                <BarGlobalStats
                    data={data}
                    legend={"Počet nabídek zadavatele"}
                    priceChart={false}
                    colorByLabel
                />
              </div>
            </div>
          </ContentBox>
          : <ContentBox>
            <AlertInfo msg={`Nejsou dostupná žádná data pro graf ${title}`} />
          </ContentBox>}
      </Col>
    )
  }

  return (
    <>
      {/*<div className="sticky-top">{loading && <Loading />}</div>*/}
      <Row>
          { renderGraph("DODÁVKY", dodDataPrepared) }
          { renderGraph("SLUŽBY", sluDataPrepared) }
          { renderGraph("STAVEBNÍ PRÁCE", staDataPrepared) }
      </Row>
    </>
  )
}

export default StatContractsDates
