import { setLoading, setData, setError } from "./statsSlice"
import { AppThunk } from "../store";
import ServerApi from "../../api/ServerApi";
import { iCriteriaContractsPeriod } from '../../api/intrefaces'
import { divideStats } from '../../utils/prepareStats'

export const fetchStats = (filters: iCriteriaContractsPeriod): AppThunk => async dispatch => {
  dispatch(setLoading());
  try {
    const response = await ServerApi.getStatsContractsByCriteria(filters);
    console.log("LOAD STATS", response)
    const data = divideStats(response)

    dispatch(setData(data));
  } catch (err) {
    console.error("ERR FETCH zakazky", err);
    const errMsg = err.response?.data?.error?.description || err.toString()
    dispatch(setError(errMsg));
  }
};