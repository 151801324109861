import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import ContentBox from "../../components/ContentBox/ContentBox"
import "../../pages/HomePage/homepage.scss"
import PieContracts from "../../components/ChartsNivo/PieContracts";
import {STAT_FILTER_TYPE} from "../../components/Stats/FilterStat";
import AlertError from "../../components/Alerts/AlertError";
import {prepareStats, prepareStatsSumNum, prepareStatsSumNumAndNumSeries, prepareStatsSumNumSeries}
  from "../../utils/prepareStats";
import Loading from "../../components/Loading/Loading";
import {usePreContractsDashboard} from "../../hooks/usePreContractsDashboard";
import {useActiveContractsDashboard} from "../../hooks/useActiveContractsDashboard";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import HomeStatNew from "../../components/HomeStat/HomeStatNew";
import { ENV } from "../../ENV";

const pocet: string = "1"
const hodnota: string = "2"

const HomeStatsGroup = () => {

  const [radioValueZR, setRadioValueZR] = useState('1');
  const radiosGraphZR = [
    {name: 'Počet', value: pocet},
    {name: 'Hodnota', value: hodnota}
  ];
  const [radioValueVZ, setRadioValueVZ] = useState('1');
  const radiosGraphVZ = [
    {name: 'Počet', value: pocet},
    {name: 'Sml. cena', value: hodnota}
  ];

  const {
    data: preContractsData,
    error,
    loading
  } = usePreContractsDashboard();

  const {
    data: activeContractsData,
    error: errorActive,
    loading: loadingActive
  } = useActiveContractsDashboard();

  if (loading || loadingActive) {
    return <Loading/>
  }
  if (error || !preContractsData.count) {
    return <AlertError msg={error}/>;
  }
  if (errorActive || !activeContractsData.count) {
    return <AlertError msg={errorActive}/>;
  }

  const {
    data: preData,
    sum: preCount,
    percent: prePercent,
  } = prepareStats(preContractsData.count, preContractsData.count);

  const {
    data: activeData,
    sum: activeCount,
    percent: activePercent,
  } = prepareStats(activeContractsData.count, activeContractsData.count);

  const {
    sum: preSumPrice
  } = prepareStats(preContractsData.sum_kc, preContractsData.sum_kc)

  const {
    sum: activeSumPrice
  } = prepareStats(activeContractsData.sum_kc, activeContractsData.sum_kc)

  return (
      <>
        <ContentBox partHeight title={radioValueZR === `1` ? "Počet probíhajících ZŘ" : "Hodnota probíhajících ZŘ"}>
          <div className="card-body-inner">
            <Col sm={4} className="col-semi-round-stats">
              <div>
                <HomeStatNew num={prepareStatsSumNum(preContractsData.count.otype) || 0}
                             text="Počet ZŘ"
                             color={ENV.COLOR}
                             tooltipText="Probíhající ZŘ od fáze Přípravy do podpisu kontraktu."
                />
              </div>
              <div>
                <HomeStatNew num={prepareStatsSumNumAndNumSeries(preContractsData.sum_kc.otype) || 0}
                             numSecondary={prepareStatsSumNum(preContractsData.count_sup_value.otype) || 0}
                             text={prepareStatsSumNumSeries(preContractsData.sum_kc.otype)}
                             color={ENV.COLOR}
                             round={1}
                             tooltipText="Předpokládaná hodnota z počtu zakázek."
                />
              </div>
            </Col>

            <Col sm={8}>
              <div>
                <Row style={{justifyContent: "flex-end"}}>
                  <ButtonGroup className="mb-2" style={{width: "auto"}}>
                    {radiosGraphZR.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant="primary"
                            name="radio"
                            value={radio.value}
                            checked={radioValueZR === radio.value}
                            onChange={(e) => setRadioValueZR(e.currentTarget.value)}
                        >
                          {radio.name}
                        </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Row>

                {radioValueZR === `1` ?
                    <>
                      <div style={{height: 340}}>
                        <div id="perKind" style={{height: 340}}>
                          <PieContracts
                              data={preData.otype}
                              filterName={STAT_FILTER_TYPE.otype}
                          />
                        </div>
                      </div>
                      <div className="text-end">
                        Počet zakázek {preCount.otype} /{prePercent.otype}%
                      </div>
                    </>
                    :
                    <>
                      <div style={{height: 340}}>
                        <div id="contractsPricePerKind" style={{height: 340}}>
                          <PieContracts
                              data={preContractsData.sum_kc.otype}
                              filterName={STAT_FILTER_TYPE.otype}
                              priceChart
                          />
                        </div>
                      </div>
                      <div className="text-end">
                        Hodnota zakázek: {preSumPrice.otype} Kč /{prePercent.otype}%
                      </div>
                    </>
                }
              </div>
            </Col>
          </div>
        </ContentBox>

        <ContentBox partHeight title={radioValueVZ === `1` ? "Počet probíhajících VZ" : "Hodnota probíhajících VZ"}>
          <div className="card-body-inner">
            <Col sm={4} className={"col-semi-round-stats"}>
              <div>
                <HomeStatNew num={prepareStatsSumNum(activeContractsData.count.otype) || 0}
                             text="Počet VZ"
                             color={ENV.COLOR}
                             tooltipText="Probíhající VZ od podpisu smlouvy po uzavření VZ."
                />
              </div>
              <div>
                <HomeStatNew num={prepareStatsSumNumAndNumSeries(activeContractsData.sum_kc.otype) || 0}
                             text={prepareStatsSumNumSeries(activeContractsData.sum_kc.otype)}
                             color={ENV.COLOR}
                             round={1}
                             tooltipText="Předpokládaná hodnota z počtu zakázek."
                />
              </div>
            </Col>

            <Col sm={8}>
              <div>
                <Row style={{justifyContent: "flex-end"}}>
                  <ButtonGroup className="mb-2" style={{width: "auto"}}>
                    {radiosGraphVZ.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`radio2-${idx}`}
                            type="radio"
                            variant="primary"
                            name="radio2"
                            value={radio.value}
                            checked={radioValueVZ === radio.value}
                            onChange={(e) => setRadioValueVZ(e.currentTarget.value)}
                        >
                          {radio.name}
                        </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Row>

                {radioValueVZ === `1` ?
                    <>
                      <div style={{height: 340}}>
                        <div id="perKind" style={{height: 340}}>
                          <PieContracts
                              data={activeData.otype}
                              filterName={STAT_FILTER_TYPE.otype}
                          />
                        </div>
                      </div>
                      <div className="text-end">
                        Počet zakázek {activeCount.otype} /{activePercent.otype}%
                      </div>
                    </>
                    :
                    <>
                      <div style={{height: 340}}>
                        <div id="contractsPricePerKind" style={{height: 340}}>
                          <PieContracts
                              data={activeContractsData.sum_kc.otype}
                              filterName={STAT_FILTER_TYPE.otype}
                              priceChart
                          />
                        </div>
                      </div>
                      <div className="text-end">
                        Hodnota zakázek: {activeSumPrice.otype} Kč /{activePercent.otype}%
                      </div>
                    </>
                }
              </div>
            </Col>
          </div>
        </ContentBox>
      </>
  )
}

export default HomeStatsGroup