import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {selectLimitOffset, selectOrder} from "../redux/filters/filtersSlice";
import { fetchContracts } from "../redux/contracts/contractsAction";
import {useFilterContext} from "../context/FilterContext";

export const useFilterContractsAll = (filter?: any, ordered?: string, isContractPlaces?: boolean) => {
  const dispatch = useDispatch();
  const {activeFilters, contextName, error, loading, contractsList} = useFilterContext()

  const { limit, offset } = useSelector(selectLimitOffset);
  const order = useSelector(selectOrder);

  const [init, setInit] = useState(false);
  const setInitFetch = () => setInit(true);

  useEffect(() => {
    if (init) {
      let f = {
        ...activeFilters,
        ...filter,
        limit: limit,
        offset: offset,
        isContractPlaces: isContractPlaces,
        order: ordered ? ordered : order
      };
      dispatch(fetchContracts(f, contextName));
    }
  }, [activeFilters, limit, offset, order, init, dispatch, filter, isContractPlaces, ordered, contextName]);

  return { contractsList, error, loading, setInitFetch };
};
