import React, { useEffect } from "react"
import AlertError from "../Alerts/AlertError"
import Loading from "../Loading/Loading"
import AlertInfo from "../Alerts/AlertInfo"
import { iContractors } from "../../api/intrefaces"
import TableBox from "../ContentBox/TableBox"
import { useFilterContractorsAll } from "../../hooks/useFilterContractorsAll"
import LINK from "../../utils/LINK"
import { NavLink } from "react-router-dom"

const ContractorsListAll = () => {
  const { contractors, error, loading, setInitFetch } =
    useFilterContractorsAll()

  useEffect(() => {
    setInitFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={error} />
  }
  if (contractors.data && !contractors.data.length) {
    return <AlertInfo msg="Žádní dodavatelé" />
  }

  return (
    <>
      <TableBox>
        <thead>
          <tr>
            <th>Název uchazeče</th>
            <th>IČO</th>
            <th>Adresa</th>
            <th>Oddělení</th>
            {contractors.data && contractors.data[0].client_name && <th>Zadavatel</th>}
            <th>Země</th>
            <th className="text-end">Počet účastí ve VZ</th>
            <th className="text-end">Počet vítězství ve VZ</th>
            <th className="text-end">Vysoutěžených VZ</th>
          </tr>
        </thead>
        <tbody id="table-body-contractors-list-all">
          {contractors.data && contractors.data.map((contractor: iContractors, index: number) => (
            <tr key={index}>
              <td>
                <NavLink
                  to={`${LINK.contractorDetail}/${contractor.id_company}`}
                >
                  {contractor.name}
                </NavLink>
              </td>
              <td>{contractor.ic}</td>
              <td>{contractor.address}</td>
              <td>{contractor.department_name}</td>
              {contractor.client_name && <td>{contractor.client_name}</td>}
              <td>{contractor.country_name}</td>
              <td className="text-end">{contractor.count_total}</td>
              <td className="text-end">{contractor.count_winner}</td>
              <td className="text-end">{contractor.count_percent + " %"}</td>
            </tr>
          ))}
        </tbody>
      </TableBox>
    </>
  )
}

export default ContractorsListAll
