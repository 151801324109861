import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOptions, selectError, selectLoading } from "../redux/company/companySlice";
import { fetchCompany } from "../redux/company/companyAction";


export const useOptionsCompany = () => {

  const dispatch = useDispatch()

  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)
  const { companyOptions } = useSelector(selectOptions)

  useEffect(() => {
    if (!loading && !error && companyOptions.length === 0) {
      dispatch(fetchCompany())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { companyOptions, loading, error }
}

