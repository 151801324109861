import { setUserData, setUserInit, removeUser, setLoading, setError } from "./userSlice"
import {toast} from "react-toastify";
import {AppThunk} from "../store";
import KeycloakApi from "../../api/KeycloakApi";
import { parseJwt } from '../../utils/parseJwt'
import { tokensSave, tokensClear, tokensGetToken } from '../../utils/tokenStore'

const errMsgInvalid: string = "Invalid user credentials"

export const loginUser = (name: string, pass: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    KeycloakApi.getToken(name, pass)
        .then((data) => {
          tokensSave(data)
          const parsedToken = parseJwt(data.access_token)
          dispatch(setUserData(parsedToken));
        })
        .catch((err) => {
          toast.error('Chyba přihlášení')
          const errMsg = err.response?.data?.error_description === errMsgInvalid ?
              "Chybné údaje"
              : err.response?.data?.error_description || err.toString()
          dispatch(setError("Chyba přihlášení: " + errMsg));
          dispatch(setLoading(false))
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
  }
}

export const loginFromStore = (): AppThunk => {
  return async (dispatch) => {
    if (tokensGetToken()) {
      const parsedToken = parseJwt(tokensGetToken())
      dispatch(setUserData(parsedToken))
      dispatch(setUserInit())
    } else {
      dispatch(setUserInit())
    }
  }
}

export const logoutUser = (msg: string): AppThunk => async dispatch => {
  //TODO logout refresh token

  tokensClear()
  dispatch(removeUser());
  if (msg) {
    toast.info(msg)
  }
  window.location.replace('/')
}