import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import LINK from "../../utils/LINK"
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats"
import StatContractsStates from "../../components/Stats/StatContractsStates"
import StatContracts from "../../components/Stats/StatContracts"
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { FilterContextProvider } from "../../context/FilterContext"

const StatsContractsStatesPage = () => {

  return (
    <PageWrap
      title="Analýza stavů zakázek"
      breadcrumbs_items={[
        { label: "Analýzy", link: LINK.stats },
        { label: "Analýza stavů zakázek" },
      ]}
    >
      <FilterContextProvider name={PAGE_TYPES.statsStates}>
        <FilterBoxStats selectCompanies/>
        <StatContractsStates/>
        <StatContracts/>
      </FilterContextProvider>
    </PageWrap>
  )
}

export default StatsContractsStatesPage
