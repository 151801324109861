import { useRef, useEffect } from "react"

// Types
import { ReactNode } from "react"

// Styles
import styles from "./TableModal.module.scss"

// Hooks
import useClickOutside from "../../hooks/useClickOutside"

type TableModalProps = {
  children: ReactNode | string
  handleClose: () => void
}

const TableModal = ({ children, handleClose }: TableModalProps) => {
  const ref = useRef(null)

  useEffect(() => {
    const closeOnEscapeKey = (e: any) =>
      e.key === "Escape" ? handleClose() : null
    document.body.addEventListener("keydown", closeOnEscapeKey)
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey)
    }
  }, [handleClose])

  useClickOutside(ref, () => {
    handleClose()
  })

  return (
    <div className={styles.modal}>
      <div ref={ref} className={styles["modal-content-wrapper"]}>
        <div className={styles["close-btn-wrapper"]}>
          <button onClick={handleClose} className={styles["close-btn"]}>
            X
          </button>
        </div>
        <div className={styles["modal-content"]}>{children}</div>
      </div>
    </div>
  )
}
export default TableModal
