import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { fetchContracts } from "../redux/contracts/contractsAction";
import {useFilterContext} from "../context/FilterContext";

export const useFilterContractsLast = () => {
  const dispatch = useDispatch()
  const { limit, activeFilters, contextName, contractsList, error, loading } = useFilterContext()

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)

  useEffect(() => {
    if (init) {
      const f = {
        contractStartDateTo: moment().toISOString(), // preplacnuti z filteru
        ...activeFilters,
        offset: 0,
        limit: limit,
        order: "date_start DESC",
        stateCodes: "EXPORTED, MEVAL, EVALUATION, EVAL_QUAL, MDEALING, POFF_ADV, ORD_CONF, LOCKED, MLOCKED, INTEREST, QUALIFY, OFFERS, MOFFERS, DESIGNS, INITOFFERS, POFFERS, PREP, DEALING, EVALUATED, MEVALUATED, INIT_QUAL, MASSIGNED, ASSIGNED, ASSIGNING, MREADY, READY"
        // @todo stateCodes nevkládat natvrdo, ale přes props komponenty, jako je v ContractsListRunningPage a ContractsListRunning.
      }
      dispatch(fetchContracts(f, contextName))
    }
  }, [activeFilters, init, limit, dispatch, contextName])

  return { contractsList, error, loading, setInitFetch }
}
