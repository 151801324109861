import React, { useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './treelist.scss';
import {selectCpvCodes, setCpvCodes} from "../../redux/filters/filtersSlice";
import {useDispatch, useSelector} from "react-redux";
import { IoClose } from "react-icons/io5";


const Treelist = ({ nodes }: any) => {
  const dispatch = useDispatch()
  const selectCpv = useSelector(selectCpvCodes)
  const [expanded, setExpanded] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // const reset = useSelector(selectUncheckCpvCodes)

  const handleCheck = (checked: any) => {
    dispatch(setCpvCodes(checked))
  };

  const handleExpand = (expanded: any) => {
    setExpanded(expanded);
  };

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filterNodes = (nodes: any) => {
    return nodes.filter((node: any) => {
      if (node.label.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
      if (node.children) {
        node.children = filterNodes(node.children);
        return node.children.length > 0;
      }
      return false;
    });
  };

  const filteredNodes = filterNodes(JSON.parse(JSON.stringify(nodes)));

  return (
      <div className="treelist-container">
        <input
            type="text"
            placeholder="Vyhledávání..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
            checked
        />
        <button className={"btn-clear-input-search"}
                onClick={() => setSearchTerm("")}
        >
          <IoClose color={"#dc3545"}/>
        </button>
        <CheckboxTree
            nodes={filteredNodes}
            checked={selectCpv}
            // checked={reset ? [] : checked}
            expanded={expanded}
            onCheck={handleCheck}
            onExpand={handleExpand}
            iconsClass="fa5"
            noCascade={true}
            showExpandAll={true}
            lang={{
              expandAll: 'Rozbalit vše',
              collapseAll: 'Sbalit vše',
              toggle: '',
            }}
            // checkModel={"all"}
            // onlyLeafCheckboxes={true}
        />
      </div>
  );
};

export default Treelist;
