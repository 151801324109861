import React from "react";
import FilterSelect from "./FilterSelect";
import { useOptionDepartment } from "../../../hooks/useOptionDepartment";
import LoadingErrorWrap from "../../LoadingErrorWrap/LoadingErrorWrap";
import {useFilterContext} from "../../../context/FilterContext";

const FilterSelectDepartments = () => {
  const { filters } = useFilterContext()
  const companyIds = (filters["companyIds"] || filters["companyId"]) ?? "";

  const { departmentOptions, loading, error } = useOptionDepartment(companyIds);
  const nmrOfCompaniesSelected = companyIds.split(",")?.length;
  return (
    <LoadingErrorWrap loading={loading} error={error}>
      <FilterSelect
        disabled={
          !companyIds ||
          departmentOptions.length === 0 ||
          nmrOfCompaniesSelected > 1
        }
        placeholder={
          nmrOfCompaniesSelected > 1
            ? "Vyberte jen jednu organizaci"
            : !companyIds
            ? "Vyberte oddělení"
            : departmentOptions.length === 0
            ? "Žádné oddělení v organizaci"
            : "Vyberte"
        }
        title="Oddělení"
        name="departmentIds"
        options={departmentOptions}
        multiple
      />
    </LoadingErrorWrap>
  );
};

export default FilterSelectDepartments;
