import React from 'react'
import Container from 'react-bootstrap/Container'
import ContentBox from '../../components/ContentBox/ContentBox'
import PageWrap from '../../components/PageWrap/PageWrap'

const NewsInfoPage = () => {

  return (
    <PageWrap title="Informace o novinkách"
              breadcrumbs_items={[
                { label: 'Informace o novinkách' },
              ]}>

      <ContentBox>
        <h2 className="text-primary">
          Přehled změn:
        </h2>

        <Container>
          <h5>Verze 2.8.0</h5>
          <p>
            Vážení uživatelé,<br/>
            dnes byla nasazena nová verze Manažera veřejných zakázek s úpravami zapracovanými na základě podnětů
            uživatelů:
            <br/>
            <ul>
              <li>V detailu Přehledu zakázek byla upravena sekce Uchazeči. Přehled uchazečů je uveřejněn až v okamžiku,
                kdy je uzavřen příjem nabídek.
              </li>
              <li>V detailu Přehledu zakázek je nyní viditelná pouze veřejně dostupná dokumentace.</li>
              <li>V Přehledu zakázek se nyní řadí zakázky od nejnovější, přičemž pro zahrnutí VZ bez data zahájení je
                nutné zatrhnout příslušné pole.
              </li>
              <li>Do filtrů analýz bylo přidáno vyhledávací pole „Dodavatel“</li>
              <li>Analýzy byly rozšířeny o „Dodavatelé – součty uhrazených cen“. Analýza zobrazuje, kolik organizace
                uhradila za veřejné zakázky jednotlivým dodavatelům z pohledu různých cen užívaných ve VZ.
              </li>
            </ul>
            Tým MVZ
          </p>
        </Container>


        <Container>
          <h5>Verze 2.7.0</h5>
          <p>
            Vážení uživatelé,<br/>
            dnes byla nasazena nová verze Manažera veřejných zakázek s následujícími úpravami:<br/>
            <ul>
              <li>Byla vytvořena nová stránka s informacemi o novinkách. Na Dashboardu přibyl informační pruh, z kterého
                se
                uživatel proklikne na stránku s informacemi. Zároveň je vytvořena další záložka v Menu.
              </li>
              <li>V grafu Místa realizace zakázek byl vytvořen aktivní proklik na seznam konkrétních zakázek v daném
                místě
                podle zvoleného místa.
              </li>
              <li>V rámci analýz byla přidána nová analýza, která zobrazuje graf s procentuálním počtem zahájených
                zadávacích řízení po jednotlivých příspěvkových organizací zahrnutých v multiprofilu a dle jednotlivých
                oddělení.
              </li>
              <li>V tabulce Qindexu si nyní uživatel může vybírat sloupce, které může zobrazit.</li>
              <li>V rámci rebrandingu QCM má Manažer veřejných zakázek nové logo a s tím i favicon, tedy ikonu, která se
                objevuje při zobrazení stránky nebo pokud si přidáte stránku mezi oblíbené.
              </li>
              <li>V Přehledu zakázek došlo k úpravě spočítaných údajů o trvání jednotlivých fází, tak aby byl přehled
                uživatelsky přívětivější.
              </li>
            </ul>
            Tým MVZ
          </p>
        </Container>

      </ContentBox>
    </PageWrap>
  )
}

export default NewsInfoPage
