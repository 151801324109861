import React from "react";
import Spinner from "react-bootstrap/Spinner";

interface iLoading {
  height?: number;
}

const LoadingInline = ({ height }: iLoading) => {
  let style = {};
  if (height) {
    style = { height: `${height}px` };
  }
  return (
    <div style={style} className="text-start">
      <Spinner animation="grow" variant="primary" className="mx-2" size="sm" />
      <Spinner animation="grow" variant="primary" className="mx-2" size="sm" />
      <Spinner animation="grow" variant="primary" className="mx-2" size="sm" />
    </div>
  );
};

export default LoadingInline;
