import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom"
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoged, selectUserFetchState } from "../../redux/user/selectors";
import { loginUser } from "../../redux/user/userAction";
import Loading from "../../components/Loading/Loading";
import AlertError from "../../components/Alerts/AlertError";
import "./loginpage.scss"
import PageWrapContent from "../../components/PageWrap/PageWrapContent";
import {ENV} from "../../ENV";

const LoginPage = () => {

  const [login, setLogin] = useState("")
  const [pass, setPass] = useState("")

  const isLoged = useSelector(selectIsLoged)
  const { error, loading } = useSelector(selectUserFetchState)
  const dispatch = useDispatch()

  const onLoginClick = (e: any) => {
    e.preventDefault()
    if (!login) {
      toast.error("Zadejte login")
      return
    }
    if (!pass) {
      toast.error("Zadejte heslo")
      return;
    }

    dispatch(loginUser(login, pass))
  }

  const location = useLocation<{ [key: string]: unknown }>()
  const currentLocationState = location.state || {
    from: { pathname: "/" }
  }
  console.log("CURRENT LOCATION STATE ", currentLocationState)


  if (isLoged){
    return <Redirect to={currentLocationState?.from as string}/>
  }

  return (
    <PageWrapContent>
      <div className="login_wrap w-100 align-items-center justify-content-center d-flex mb-5">
        <div style={{ maxWidth: 400, width: "100%", fontSize: 14, flex: "1 0 auto" }}>
          <Card className={"p-3 shadow-sm"}>
            <Card.Body>

              <h4 className="mb-4 text-center">Přihlášení do MVZ</h4>

              {loading ?
                <Loading/>
                :
                <>
                  {error && <AlertError msg={error}/>}
                  <Form
                    // noValidate
                    // validated={true}
                    // validated={validated}
                    onSubmit={onLoginClick}>
                    <Form.Group controlId="formBasicEmail" className="mb-3">
                      <Form.Label>Přihlašovací jméno</Form.Label>
                      <Form.Control
                        autoFocus
                        required type="text"
                        value={login}
                        onChange={e => setLogin(e.target.value)}
                        // isInvalid={true}
                        style={{ borderWidth: 2.3 }}
                      />
                      {/*<Form.Control.Feedback type='invalid'>*/}
                      {/*  chyba*/}
                      {/*</Form.Control.Feedback>*/}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className="mb-3">
                      <Form.Label>Heslo</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        value={pass}
                        onChange={e => setPass(e.target.value)}
                        style={{ borderWidth: 2.3 }}
                        // isValid={true}
                      />

                      {/*<Form.Control.Feedback type="valid">*/}
                      {/*  OK*/}
                      {/*</Form.Control.Feedback>*/}
                    </Form.Group>

                    <Form.Group className="mt-4">
                      <Button type="submit" className={"w-100"} style={{ padding: "12px 10px" }}>
                        Přihlásit
                      </Button>
                    </Form.Group>
                  </Form>

                  <div className="mt-4 text-center">
                    <Button href={
                      `${ENV.KEYCLOACK_API_URL}/realms/${ENV.REALM}/login-actions/reset-credentials?client_id=${ENV.CLIENT_ID}`
                      }
                      variant="link"
                      // target="_blank"
                      title="Zapomenuté heslo"
                    >
                      Zapomněli jste heslo?
                    </Button>
                  </div>
                </>
              }
            </Card.Body>
          </Card>

        </div>
      </div>
    </PageWrapContent>
  )
}

export default LoginPage;