// React components
import { Button } from "react-bootstrap"
import TableModal from "../../../components/TableModal"

type RemoveUserModalProps = {
  handleClose: () => void
}

const RemoveUserModal = ({ handleClose }: RemoveUserModalProps) => {
  return (
    <TableModal handleClose={handleClose}>
      <h3 className="mb-3">Opravdu chcete uživatele odstranit?</h3>
      <div className="d-flex gap-2">
        <Button>Ano</Button>
        <Button onClick={handleClose} variant="secondary">
          Ne
        </Button>
      </div>
    </TableModal>
  )
}

export default RemoveUserModal
