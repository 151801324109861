import axios from "axios";

/**
 * API instance pro public složku
 */
class PublicApi {

  api = axios.create({
    baseURL: "/"
  });

  getCpvStruct = () =>
      this.api.get(`resources/cpvStruct.json`)
          .then(res => res.data);

}

const publicApiInstance = new PublicApi();
export default publicApiInstance;