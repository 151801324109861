import React from "react"
import Bcrumb from "../Bcrumb/Bcrumb"
import Container from "react-bootstrap/Container"
import "./pagewrap.scss"

interface iPageWrapHeadline {
  title?: string
  breadcrumbs_items: { link?: string; label: string }[]
}

const PageWrapHeadline = ({ title, breadcrumbs_items }: iPageWrapHeadline) => {
  return (
    <div className="top_headline">
      <Container fluid="md">
        <Bcrumb items={breadcrumbs_items} />
        {title && <h1 className="title">{title}</h1>}
      </Container>
    </div>
  )
}

export default PageWrapHeadline
