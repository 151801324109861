import React from 'react';
import Alert from "react-bootstrap/Alert";

interface iAlertInfo {
  msg: string
}

const AlertInfo = ({ msg }: iAlertInfo) => {
  return (
    <Alert variant="info">
      {msg}
    </Alert>
  );
}

export default AlertInfo;