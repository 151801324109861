import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaSearch } from "react-icons/fa";
import "../filter.scss";
import { FormControl } from "react-bootstrap";
import {useFilterContext, useFilterContextInput} from "../../../context/FilterContext";
import React from "react";

interface iFilterSearch {
  name: string;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
}

const FilterSearch = ({ name, title, placeholder = "Vyhledávání", disabled }: iFilterSearch) => {
  const { filters } = useFilterContext()
  const filterVal = filters[name] ?? "";

  const { setVal } = useFilterContextInput(name)

  return (
    <Form.Group as={Row} controlId={`filterSelect${name}`} className="mb-1">
      {title && (
          <Form.Label column sm="4" className="text-end">
            {title}
          </Form.Label>
      )}
      <Col>
        <div className="relative">
          <div className="search_icon text-primary">
            <FaSearch color={"#adb5bd"}/>
          </div>
          <FormControl
            className="ellipsis"
            type="text"
            placeholder={placeholder}
            name={name}
            value={filterVal}
            onChange={(e) => setVal(e.target.value)}
            disabled={disabled}
          ></FormControl>
        </div>
      </Col>
    </Form.Group>
  );
};

export default FilterSearch;
