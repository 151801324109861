import React, { useEffect } from "react"
import Loading from "../Loading/Loading"
import AlertError from "../Alerts/AlertError"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ContentBox from "../ContentBox/ContentBox"
import { useFilterStats } from "../../hooks/useFilterStats"
import ServerApi from "../../api/ServerApi"
import BarStats from "../ChartsNivo/BarStats"
import { iStat, iStatsChart } from "../../api/intrefaces"
import { prepareStatsSortDesc, prepareStatsSum } from "../../utils/prepareStats"
import ExportPDFButton from "../ExportPDFButton/ExportPDFButton"
import FilterStat, {STAT_FILTER_TYPE} from "./FilterStat";

const StatContractsPlaces = () => {
  const { stats, error, loading, setInitFetch } = useFilterStats(
    ServerApi.getStatsPlacesByCriteria
  )

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])

  // if (loading) {
  //   return <Loading/>
  // }
  if (error) {
    return <AlertError msg={error} />
  }

  const dataRaw: iStatsChart[] = stats.map((stat: iStat) => ({
    id: stat.code,
    label: stat.name,
    value: parseInt(stat.count),
  }))

  const data = {
    data: prepareStatsSortDesc(dataRaw),
    sum: prepareStatsSum(dataRaw),
  }

  const calcHeightWrapperGraph = data.data.length < 10 ? { height: data.data.length * 20 + 250 } : { height: 650 }
  const calcHeightGraph = data.data.length < 10 ? { height: data.data.length * 20 + 250 } : { height: 450 }

  return (
      <>
        <div className="sticky-top">
          <FilterStat/>
          {loading && <Loading/>}
        </div>
        <Row>
          <Col>
            <ContentBox title="Místa realizace zakázek TOP 25">
              <div style={calcHeightWrapperGraph}>
                <ExportPDFButton
                    exportedGraph={document.getElementById("placesOfRealization")}
                    graphName="Místa realizace zakázek TOP 25"
                    filter=""
                    orientation="landscape"
                    numberOfContracts={`Počet zakázek: ${data.sum}`}
                />
                <div id="placesOfRealization" style={calcHeightGraph}>
                  <BarStats
                      horizontal
                      data={data.data}
                      filterName={STAT_FILTER_TYPE.nuts}
                  />
                </div>
              </div>

              <div className={"text-end"}>Počet zakázek: {data.sum}</div>
            </ContentBox>
          </Col>
        </Row>
      </>
  )
}

export default StatContractsPlaces
