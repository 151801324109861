import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import ContentBox from "../../components/ContentBox/ContentBox";
import Container from "react-bootstrap/Container";

const TermsAndConditionsPage = () => {

  return (
    <PageWrap title="Všeobecné obchodní podmínky"
              breadcrumbs_items={[
                {label: "Všeobecné obchodní podmínky"},
              ]}>

      <ContentBox>
        <Container className="p-lg-5">
          <h5 className="mt-4 mb-3">1. Základní ustanovení</h5>
          <h6>1.1. Předmět úpravy</h6>
          <p className="pl-1">Tyto Všeobecné obchodní podmínky (dále jen „VOP“) stanovují vzájemná práva
            a&nbsp;povinnosti Uživatele a&nbsp;Provozovatele související s&nbsp;užíváním Aplikace Manažer veřejných
            zakázek.</p>

          <h6>1.2. Definice</h6>
          <p><strong>„Aplikace Manažer veřejných zakázek“</strong> (dále jen MVZ) znamená počítačový program poskytovaný
            uživatelům z&nbsp;internetové stránky dostupný na internetové adrese *organizace*.manazer-vz.cz, včetně
            všech jeho funkcí, zdrojových kódů, databází, uživatelského rozhraní a&nbsp;funkcionalit užívaných pro
            zobrazení přehledů a&nbsp;analýz veřejných zakázek a&nbsp;zadávacích řízení.</p>
          <p><strong>„Provozovatel nebo Zpracovatel“</strong> znamená obchodní společnost <strong>QCM, s.r.o.</strong>,
            IČO: 26262525, se sídlem Heršpická 813/5, 639 00 Brno - Štýřice, spisová značka C 40722, vedená
            u&nbsp;Krajského soudu v&nbsp;Brně.</p>
          <p><strong>„Uživatel nebo Správce“</strong> znamená osobu, která uzavřela Smlouvu o&nbsp;užívání Aplikace
            Manažer veřejných zakázek (dále jen Smlouva o&nbsp;MVZ);</p>
          <p><strong>„ZZVZ“</strong> znamená zákon č. 134/2016 Sb., o&nbsp;zadávání veřejných zakázek, ve znění
            pozdějších předpisů;</p>
          <p><strong>„E-ZAK“</strong> znamená počítačový program, včetně všech jeho funkcí, zdrojových kódů, databází,
            uživatelského rozhraní a&nbsp;funkcionalit pro podporu elektronických procesů zadávání veřejných zakázek
            podporované typy zadávacích řízení. Jedná se o&nbsp;certifikovaný elektronický nástroj pro zadávání
            veřejných zakázek dle ZZVZ.</p>
          <p><strong>„VOP“ </strong>znamená tyto Všeobecné obchodní podmínky.</p>
          <p><strong>„Uživatelský účet“ </strong>znamená neveřejnou část Aplikace, která je uživateli přístupná po
            zadání Přihlašovacích údajů a&nbsp;umožňuje Uživateli užívat celou funkcionalitu Aplikace dle nastavených
            uživatelských práv.</p>
          <p><strong>„Přihlašovací údaje“ </strong>znamenají unikátní kombinaci přihlašovacího jména Uživatele
            a&nbsp;hesla automaticky vygenerovaného Aplikací.</p>
          <p><strong>„Smlouva o&nbsp;užívání Aplikace Manažer veřejných zakázek“</strong> znamená smlouvu, kterou se
            Provozovatel zavazuje zřídit Uživateli přístup prostřednictvím uživatelského rozhraní do Aplikace Manažera
            veřejných zakázek (dále jen „MVZ), a&nbsp;to vše za podmínek uvedených v&nbsp;těchto VOP;</p>
          <p><strong>„GDPR“</strong> Nařízení Evropského parlamentu a&nbsp;Rady (EU) č.2016/679 ze dne 27.dubna 2016
            o&nbsp;ochraně fyzických osob v&nbsp;souvislosti se zpracováním osobních údajů a&nbsp;o volném pohybu těchto
            údajů a&nbsp;o zrušení směrnice 65/46/ES (obecné nařízení o&nbsp;ochraně osobních údajů).</p>

          <h5 className="mt-4 mb-3">2. Proces uzavření smlouvy</h5>
          <p className="pl-1">2.1. Provozovatel nabízí Uživatelům uzavření Smlouvy o&nbsp;MVZ. Podmínkou pro uvedený
            postup je platná a&nbsp;účinná servisní smlouva uzavřená mezi Uživatelem a&nbsp;Provozovatelem
            o&nbsp;provozu E-ZAKu.</p>
          <p className="pl-1">2.2. Uživatel může poptat službu z&nbsp;internetové adresy https://manazer-vz.cz, přímo
            kontaktovat obchodní oddělení Provozovatele nebo jej zástupce Provozovatele osloví s&nbsp;nabídkou
            služby. </p>
          <p className="pl-1">2.3. Smlouva bude uzavřena na základě akceptace objednávky Provozovatelem. Objednávku
            zašle Uživatel Provozovateli, který její příjem potvrdí. Následně proběhne zprovoznění MVZ.</p>

          <h5 className="mt-4 mb-3">3. Užívání Aplikace Manažer veřejných zakázek</h5>
          <p className="pl-1">3.1. k&nbsp;užívání Aplikace MVZ je třeba přístup k&nbsp;síti Internet a&nbsp;internetový
            prohlížeč.</p>
          <p className="pl-1">3.2. Provozovatel uděluje Licenci za podmínek uvedených u&nbsp;daného typu Uživatelského
            účtu.</p>
          <p className="pl-1">3.3. Uživatel se za udělení Licence zavazuje Provozovateli zaplatit odměnu, jejíž výše je
            uvedena v&nbsp;potvrzené objednávce. Platební podmínky se řídí čl. 4 VOP.</p>
          <p className="pl-1">3.4. Provozovatel má právo poskytnout plnění Smlouvy až v&nbsp;momentě, kdy dojde
            k&nbsp;zaplacení odměny za poskytnutí Licence, a&nbsp;nezaplatí-li Uživatel za Licenci odměnu v&nbsp;plné
            výši v&nbsp;Provozovatelem stanovené lhůtě, má Provozovatel právo bez dalšího od smlouvy odstoupit. </p>
          <p className="pl-1">3.5. Uživatel uzavřením Smlouvy o&nbsp;MVZ souhlasí s&nbsp;těmito VOP a&nbsp;prohlašuje,
            že se s&nbsp;nimi detailně seznámil.</p>
          <p className="pl-1">3.6. Smlouva o&nbsp;MVZ se uzavírá na dobu určitou, která bude uvedena v&nbsp;objednávce
            s&nbsp;možností automatické obnovy.</p>
          <p className="pl-1">3.7. v&nbsp;případě, že dojde k&nbsp;ukončení servisní smlouvy k&nbsp;provozu E-ZAKu, bude
            Aplikace znepřístupněna. Zaplacenou odměnu ani její poměrnou část Provozovatel nevrací.</p>
          <p className="pl-1">3.8. Uživatelé souhlasí se zobrazením svých uživatelských dat z&nbsp;E-ZAKu
            v&nbsp;Aplikaci MVZ.</p>
          <p className="pl-1">3.9. Přístup k&nbsp;Uživatelskému účtu je zabezpečen přihlašovacími údaji ve formě
            uživatelského jména a&nbsp;hesla. Uživatel se zavazuje uchovávat mlčenlivost ohledně svých přihlašovacích
            údajů a&nbsp;nesdělí ani je jinak nezpřístupní třetím osobám.</p>
          <p className="pl-1">3.10. Uživatel má právo zřídit více než jeden Uživatelský účet.</p>
          <p className="pl-1">3.11. Uživatel bere na vědomí, že může dojít k&nbsp;dočasnému omezení či přerušení
            dostupnosti Aplikace, a&nbsp;to zejména z&nbsp;důvodů spojených s&nbsp;aktualizací a&nbsp;údržbou
            Aplikace.</p>

          <h5 className="mt-4 mb-3">4. Platební a&nbsp;fakturační podmínky</h5>
          <p className="pl-1">4.1. Výše fakturované částky bude uvedena v&nbsp;Objednávce, kterou Uživatel vystavil na
            základě nabídky, a&nbsp;která byla akceptována Provozovatelem.</p>
          <p className="pl-1">4.2. Splatnost faktur je 14 kalendářních dnů od vystavení faktury Provozovatelem, pokud
            nebude v&nbsp;objednávce uvedeno jinak.</p>
          <p className="pl-1">4.3. Provozovatel vystaví fakturu v&nbsp;nejbližším možném termínu po vzniku nároku na
            poplatek dle provedených služeb.</p>
          <p className="pl-1">4.4. Neuhrazení faktury v&nbsp;daném termínu je porušení povinností Uživatele
            vyplývajících z&nbsp;těchto VOP a&nbsp;Provozovatel má v&nbsp;takovém případě právo zablokovat přístup
            Uživatele do Aplikace.</p>

          <h5 className="mt-4 mb-3">5. Informace o&nbsp;zpracovaní osobních údajů –&nbsp;GDPR</h5>
          <p className="pl-1">5.1. Zpracování dat a&nbsp;veškerých osobních údajů Uživatelů v&nbsp;rámci Aplikace je
            realizováno v&nbsp;souladu s&nbsp;GDPR a&nbsp;v souladu se zpracovatelskou smlouvou, která je součástí
            těchto VOP.</p>
          <p className="pl-1">5.2. Uživatel Aplikace je Správcem osobních údajů (dále jen „Správce osobních údajů“
            subjektů registrovaných na Aplikace a&nbsp;Provozovatel je zpracovatelem osobních údajů (dále jen
            „Zpracovatelem osobních údajů ve smyslu nařízení Evropského parlamentu a&nbsp;Rady (EU) č. 2016/679
            o&nbsp;ochraně fyzických osob v&nbsp;souvislosti se zpracováním osobních údajů a&nbsp;o volném pohybu těchto
            údajů (dále též jen „Obecné nařízení o&nbsp;ochraně osobních údajů“ nebo „GDPR“).</p>
          <p className="pl-1">5.3. Uživatel souhlasí s&nbsp;využitím jednotného zabezpečeného přihlašování pro přístup
            k&nbsp;Aplikaci a&nbsp;s využitím svých osobních údajů, které jsou uloženy v&nbsp;profilu Uživatele pro
            účely používání Aplikace. </p>
          <p className="pl-1">5.4. Správce prohlašuje, že osobní údaje používané na Aplikaci jsou Správcem získávány
            a&nbsp;zpracovávány v&nbsp;souladu s&nbsp;GDPR, jsou přesné, odpovídají stanovenému účelu a&nbsp;jsou
            v&nbsp;rozsahu nezbytném pro naplnění stanoveného účelu.</p>
          <p className="pl-1">5.5. v&nbsp;rámci Aplikace nejsou zpracovávány žádné osobní údaje ve smyslu nařízení čl. 9
            GDPR, ani nedochází k&nbsp;automatizovanému rozhodování, včetně profilování ve smyslu nařízení GDPR čl.
            22.</p>
          <p className="pl-1">5.6. Osobní údaje jsou v&nbsp;Aplikaci uchovávány po dobu trvání podmínky existence
            zákonnosti zpracování osobních údajů, tedy podmínek dle čl. 6, odst. 1, písm. B) až f) GDPR.</p>
          <p className="pl-1">5.7. Osobní údaje mohou být na základě zákonné žádosti předány také třetím subjektům,
            které disponují zákonnou pravomocí vyžadovat předání předmětných osobních údajů. Osobní údaje nejsou
            Provozovatele předávány do třetích zemí nebo mezinárodních organizací.</p>
          <p className="pl-1">5.8. Zpracovatelská smlouva (dále jen Smlouva“) je uzavřená v&nbsp;souladu
            s&nbsp;nařízením Evropského parlamentu a&nbsp;Rady (EU) 2016/679 ze dne 27. dubna 2016 o&nbsp;ochraně
            fyzických osob v&nbsp;souvislosti se zpracováním osobních údajů a&nbsp;o volném pohybu těchto údajů a&nbsp;o
            zrušení směrnice 95/46/ES (obecné nařízení o&nbsp;ochraně osobních údajů) a&nbsp;dle zákona č. 110/2019 Sb.,
            o&nbsp;zpracování osobních údajů, ve znění pozdějších předpisů mezi Správcem a&nbsp;Zpracovatelem ode dne
            uzavření Smlouva o&nbsp;užívání Aplikace Manažer veřejných zakázek, na jejímž základě mu poskytuje služby
            spočívající v&nbsp;poskytnutí Aplikace, na jejímž základě Zpracovatel zpracovává osobní údaje Správce.
            Zpracovatelská smlouva je součástí příloh VOP.</p>

          <h5 className="mt-4 mb-3">6. Změna VOP</h5>
          <p className="pl-1">6.1. Uživatel bere na vědomí, že Provozovatel uzavírá smlouvy, jejichž předmětem je
            užívání Aplikace v&nbsp;běžném obchodním styku s&nbsp;větším počtem osob a&nbsp;tyto smlouvy jsou podle
            svého charakteru zavazující dlouhodobě k&nbsp;opětovným plněním stejného druhu s&nbsp;odkazem na tyto VOP.
            Smluvní strany činí nesporným, že z&nbsp;povahy závazků Provozovatele podle těchto VOP vyplývá a&nbsp;již
            před uzavřením smlouvy vyplývala rozumná potřeba pozdější změny těchto VOP.</p>
          <p className="pl-1">6.2. Smluvní strany tímto ujednávají, že Provozovatel může tyto VOP v&nbsp;přiměřeném
            rozsahu změnit a&nbsp;zároveň ujednávají, že změna VOP bude Uživateli, jako druhé straně, oznámena
            prostřednictvím e-mailové zprávy odeslané na e-mailovou adresu zadanou Uživatelem v&nbsp;Aplikaci. Uživatel
            má v&nbsp;takovém případě právo změny VOP odmítnout a&nbsp;předmětný závazek mezi ním a&nbsp;Provozovatelem
            z&nbsp;tohoto důvodu vypovědět ve výpovědní době jeden (1) kalendářní měsíc.</p>

          <h5 className="mt-4 mb-3">7. Spory</h5>
          <p className="pl-1">7.1. Veškeré spory vznikající ze smluv, jejichž součástí jsou tyto VOP a/nebo
            v&nbsp;souvislosti s&nbsp;nimi, které se nepodaří vyřešit dohodou smluvních stran do jednoho (1) měsíce ode
            dne vzniku sporu, budou rozhodovány s&nbsp;konečnou platností u&nbsp;Rozhodčího soudu při Hospodářské komoře
            České republiky a&nbsp;Agrární komoře České republiky podle jeho Řádu a&nbsp;Pravidel. Spor bude rozhodován
            třemi rozhodci jmenovanými v&nbsp;souladu s&nbsp;Řádem Rozhodčího soudu. Místem rozhodčího řízení bude
            Praha. </p>

          <h5 className="mt-4 mb-3">8. Platnost a&nbsp;účinnost</h5>
          <p className="pl-1">8.1. v&nbsp;případě, že je Uživatel povinný subjekt podle zákona č. 340/2015 Sb.,
            o&nbsp;registru smluv a&nbsp;cena přesahuje 50.000 Kč bez DPH, je Uživatel povinen uveřejnit objednávku
            v&nbsp;registru smluv.</p>
          <p className="pl-1 mb-5">8.2. Tyto VOP nabývají platnosti a&nbsp;účinnosti dne 10. 1. 2024.</p>
          <hr/>

          <h4 className="big-mt-5">Příloha: Zpracovatelská smlouva</h4>
          <p className="body-text mt-4">Tato zpracovatelská smlouva (dále jen Smlouva“) je uzavřená v&nbsp;souladu
            s&nbsp;nařízením Evropského parlamentu a&nbsp;Rady (EU) 2016/679 ze dne 27. dubna 2016 o&nbsp;ochraně
            fyzických osob v&nbsp;souvislosti se zpracováním osobních údajů a&nbsp;o volném pohybu těchto údajů a&nbsp;o
            zrušení směrnice 95/46/ES (obecné nařízení o&nbsp;ochraně osobních údajů) a&nbsp;dle zákona č. 110/2019 Sb.,
            o&nbsp;zpracování osobních údajů, ve znění pozdějších předpisů mezi Správcem a&nbsp;Zpracovatelem ode dne
            uzavření Smlouvy o&nbsp;užívání Aplikace Manažer veřejných zakázek, na jejímž základě mu poskytuje služby
            spočívající v&nbsp;poskytnutí Aplikace (dále jen „Smlouva hlavní“), na jejímž základě Zpracovatel zpracovává
            osobní údaje Správce.</p>

          <p className="text-center mb-0">I.</p>
          <p className="text-center">Předmět smlouvy</p>
          <p>1. Předmětem této Smlouvy je závazek Zpracovatele zpracovávat pro Správce osobní údaje uvedené v&nbsp;čl.
            III. této Smlouvy, k&nbsp;nimž získá Zpracovatel přístup na základě uzavřené Smlouvy hlavní a&nbsp;dále
            závazek Smluvních stran zachovávat mlčenlivost o&nbsp;těchto vzájemně poskytnutých informacích, a&nbsp;to
            v&nbsp;rozsahu a&nbsp;za podmínek stanovených touto Smlouvou.</p>

          <p className="text-center mb-0">II.</p>
          <p className="text-center">Prohlášení Správce</p>
          <p>1. Správce je správcem osobních údajů dle nařízení Evropského parlamentu a&nbsp;Rady (EU) č. 2016/679
            o&nbsp;ochraně fyzických osob v&nbsp;souvislosti se zpracováním osobních údajů a&nbsp;o volném pohybu těchto
            údajů (dále též jen „Obecné nařízení o&nbsp;ochraně osobních údajů“ nebo „GDPR“). Správce prohlašuje, že
            osobní údaje uvedené v&nbsp;odst. 1. článku III. této Smlouvy jsou Správcem získávány a&nbsp;zpracovávány
            v&nbsp;souladu s&nbsp;GDPR, jsou přesné, odpovídají stanovenému účelu a&nbsp;jsou v&nbsp;rozsahu nezbytném
            pro naplnění stanoveného účelu.</p>
          <p>2. Správce je oprávněn pověřit Zpracovatele ke zpracovávání osobních údajů ve smluvně stanoveném rozsahu,
            za smluvně stanoveným účelem a&nbsp;na dobu ve Smlouvě stanovenou.</p>
          <p>3. Za plnění z&nbsp;této Smlouvy nenáleží Zpracovateli žádná odměna.</p>

          <p className="text-center mb-0">III.</p>
          <p className="text-center">Zpracování údajů</p>
          <p>1. Správce pověřuje Zpracovatele po dobu účinnosti Smlouvy ke zpracování osobních údajů v&nbsp;souvislosti
            s&nbsp;plněním povinností dle Smlouvy hlavní v&nbsp;následujícím rozsahu:</p>

          <ul>
            <li>kontaktní údaje příslušné osoby,</li>
            <li>cookies,</li>
            <li>IP adresa subjektu údajů.</li>
          </ul>

          <p>2. Osobní údaje specifikované v&nbsp;odstavci 1. tohoto článku je Zpracovatel oprávněn zpracovávat za
            účelem plnění Smlouvy hlavní v&nbsp;následujícím rozsahu – poskytnutí Aplikace Uživatelům Správce.</p>
          <p>3. Zpracovatel bude zpracovávat osobní údaje pouze způsoby nutnými ke splnění účelu uvedeného výše
            v&nbsp;odstavci 2. tohoto článku a&nbsp;pouze v&nbsp;nezbytně nutném rozsahu. </p>
          <p>Explicitně zakázáno je provádění následujících operací zpracování:</p>

          <ul>
            <li>změna či jiné pozměnění poskytnutých dat Správce,</li>
            <li>kombinování, seřazení nebo jakékoliv jiné zkombinování dat Správce,</li>
            <li>uspořádání dat Správce,</li>
            <li>strukturování dat Správce,</li>
            <li>nahlížení nebo jiné vyhledávání v&nbsp;datech Správce,</li>
            <li>použití dat Správce,</li>
            <li>zpřístupnění dat Správce; zpřístupnění dat Správce třetím osobám; zpřístupnění dat Správce přenosem,
            </li>
            <li>šíření nebo jakékoliv jiné zpřístupnění dat Správce,</li>
            <li>omezení, výmaz nebo zničení dat Správce.</li>
          </ul>

          <p className="text-center mb-0">IV.</p>
          <p className="text-center">Společná práva a&nbsp;povinnosti smluvních stran</p>
          <p>1. Smluvní strany se zavazují vzájemně si neprodleně ohlašovat všechny jim známé skutečnosti, které by
            mohly nepříznivě ovlivnit řádné a&nbsp;včasné plnění závazků vyplývajících z&nbsp;této Smlouvy.</p>
          <p>2. Informační povinnost dle čl. 13 a&nbsp;čl. 14 GDPR, ve vztahu k&nbsp;subjektům údajů, jejichž osobní
            údaje jsou zpracovávány dle této Smlouvy, bude plněna Správcem, nedohodnou-li se Smluvní strany jinak.</p>

          <p className="text-center mb-0">V.</p>
          <p className="text-center">Práva a&nbsp;povinnosti správce</p>
          <p>1. Správce je povinen v&nbsp;případě, že pro plnění povinností Zpracovatele dle této Smlouvy jsou nutné
            jakékoli písemné podklady, předat tyto podklady Zpracovateli bez zbytečného odkladu poté, co o&nbsp;to bude
            Zpracovatelem požádán.</p>
          <p>2. Dojde-li z&nbsp;jakéhokoli důvodu (např. z&nbsp;důvodu legislativních změn, rozhodnutí státního orgánu
            atd.) k&nbsp;nutnosti změny dohodnutých pravidel při plnění předmětu této Smlouvy, zavazuje se Správce
            neprodleně o&nbsp;této skutečnosti Zpracovatele informovat. Smluvní strany jsou povinny v&nbsp;takovém
            případě zahájit jednání o&nbsp;změně této Smlouvy.</p>

          <p className="text-center mb-0">VI.</p>
          <p className="text-center">Práva a&nbsp;povinnosti zpracovatele</p>
          <p>1. Zpracovatel se zavazuje poskytnout Správci součinnost nezbytnou pro plnění této Smlouvy, zejména je
            Zpracovatel Správci nápomocen při zajišťování souladu s&nbsp;povinnostmi dle čl. 32 až čl. 36 GDPR,
            a&nbsp;to při zohlednění povahy zpracování a&nbsp;informací, jež má Zpracovatel k&nbsp;dispozici.</p>
          <p>2. Zpracovatel je povinen s&nbsp;přihlédnutím „ke stavu techniky, nákladům na provedení, povaze, rozsahu,
            kontextu a&nbsp;účelům zpracování i&nbsp;k&nbsp;různě pravděpodobným a&nbsp;různě závažným rizikům pro práva
            a&nbsp;svobody fyzických osob, jež s&nbsp;sebou zpracování nese, zavést vhodná technická a&nbsp;organizační
            opatření, aby zajistil úroveň zabezpečení odpovídající danému riziku, případně učinil opatření dle čl. 32
            odst .1 písm. a) až d) GDPR.“</p>
          <p>3. Zpracovatel je povinen zohlednit zejména rizika a&nbsp;spolu s&nbsp;vnitřními postupy vedoucí
            k&nbsp;odhalování a&nbsp;zvládání případů porušení zabezpečení přijmout taková opatření, která představuje
            zpracování přenášených, uložených nebo jinak zpracovávaných osobních údajů, zejména jejich náhodné nebo
            protiprávní zničení, ztrátu, změnu nebo neoprávněné poskytnutí nebo zpřístupnění. Mezi taková opatření může
            patřit mimo jiné: </p>

          <ul>
            <li>osobní údaje uchovávané v&nbsp;elektronické podobě chránit před neoprávněným přístupem pomoc vytvoření
              přístupových práv a&nbsp;kontroly přístupu do sítě včetně technologie firewallů, jak hardwarových, tak
              i&nbsp;softwarových komponent, technologie detekce síťových průniků, pseudonymizace a&nbsp;šifrovací
              technologie, vybrané podle jejich vhodnosti,
            </li>
            <li>zajistit ochranu, udržování a&nbsp;monitorování zabezpečení a&nbsp;integrity sítě Zpracovatele,</li>
            <li>pravidelné zálohování dat včetně zajištění potřebného software a&nbsp;hardware pro provádění
              bezpečnostních záloh,
            </li>
            <li>určit pověřené fyzické osoby ke zpracování Osobních údajů, přičemž pouze tyto osoby budou oprávněny
              k&nbsp;přístupu a&nbsp;zpracování Osobních údajů v&nbsp;souladu s&nbsp;ustanoveními této Smlouvy,
            </li>
            <li>pořizovat elektronické záznamy, které umožní určit a&nbsp;ověřit, kdy, kým a&nbsp;z jakého důvodu byly
              Osobní údaje zobrazeny, zaznamenány nebo jinak zpracovány,
            </li>
            <li>přijmout případně další technická opatření, která jsou obecně uznávána jako vhodná bezpečnostní opatření
              pro užívaný způsob zpracování Osobních údajů.
            </li>
          </ul>
          <p>4. Zpracovatel se zavazuje zpracovat a&nbsp;dokumentovat přijatá a&nbsp;provedená technická
            a&nbsp;organizační opatření k&nbsp;zajištění ochrany Osobních údajů v&nbsp;souladu se Zákonem o&nbsp;ochraně
            osobních údajů a&nbsp;jinými právními předpisy, zejména GDPR, a&nbsp;udržovat takovou dokumentaci
            aktuální. </p>
          <p>5. Zpracovatel je povinen v&nbsp;souladu s&nbsp;čl. 33 odst. 2 GDPR bez zbytečného odkladu, jakmile zjistí
            porušení zabezpečení osobních údajů ohlásit toto porušení Správci. Ohlášení bude provedeno s&nbsp;použitím
            kontaktních informací uvedených v&nbsp;záhlaví této smlouvy.</p>
          <p>6. Zpracovatel se zavazuje, že nezapojí do zpracování žádného dalšího zpracovatele bez předchozího
            konkrétního nebo obecného písemného povolení Správce. v&nbsp;případě obecného písemného povolení Zpracovatel
            Správce informuje o&nbsp;veškerých zamýšlených změnách týkajících se přijetí dalších zpracovatelů nebo
            jejich nahrazení, a&nbsp;poskytne tak Správci příležitost vyslovit vůči těmto změnám námitky.</p>
          <p>7. Pokud Zpracovatel zapojí dalšího zpracovatele, který by jménem Správce provedl určité činnosti
            zpracování, musí být tomuto dalšímu zpracovateli uloženy stejné povinnosti na ochranu údajů, jaké jsou
            uvedeny v&nbsp;této smlouvě mezi Správcem a&nbsp;Zpracovatelem, a&nbsp;to zejména poskytnutí dostatečných
            záruk, pokud jde o&nbsp;zavedení vhodných technických a&nbsp;organizačních opatření tak, aby zpracování
            splňovalo požadavky GDPR. Neplní-li uvedený další Zpracovatel své povinnosti v&nbsp;oblasti ochrany údajů,
            odpovídá Správci za plnění povinností dotčeného dalšího zpracovatele i&nbsp;nadále Zpracovatel dle této
            smlouvy.</p>
          <p>8. Zpracovatel je povinen postupovat při poskytování plnění dle této Smlouvy v&nbsp;souladu s&nbsp;GDPR
            a&nbsp;dalšími národními předpisy, s&nbsp;odbornou péčí, řídit se pokyny Správce a&nbsp;jednat
            v&nbsp;souladu se zájmy Správce. </p>
          <p>9. Zpracovatel se dále zavazuje:</p>
          <p className="pl-1">a) zpracovávat osobní údaje pouze na základě doložených pokynů Správce, včetně předání
            osobních údajů do třetí země nebo mezinárodní organizaci, pokud mu toto zpracování již neukládá právo Unie
            nebo členského státu, které se na Správce vztahuje; v&nbsp;takovém případě Zpracovatel Správce informuje
            o&nbsp;tomto právním požadavku před zpracováním, ledaže by tyto právní předpisy toto informování zakazovaly
            z&nbsp;důležitých důvodů veřejného zájmu,</p>
          <p className="pl-1">b) zajišťovat, aby se osoby oprávněné zpracovávat osobní údaje zavázaly
            k&nbsp;mlčenlivosti nebo aby se na ně vztahovala zákonná povinnost mlčenlivosti,</p>
          <p className="pl-1">c) zohledňovat povahu zpracování, být Správci nápomocen prostřednictvím vhodných
            technických a&nbsp;organizačních opatření, pokud je to možné, pro splnění Správcovy povinnosti reagovat na
            žádosti o&nbsp;výkon práv subjektu údajů,</p>
          <p className="pl-1">d) být Správci nápomocen při zajišťování souladu s&nbsp;povinnostmi podle odstavců 2 až 6
            tohoto článku, a&nbsp;to při zohlednění povahy zpracování a&nbsp;informací, jež má Zpracovatel
            k&nbsp;dispozici,</p>
          <p className="pl-1">e) v&nbsp;souladu s&nbsp;rozhodnutím Správce všechny osobní údaje buď vymazat, nebo je
            vrátit Správci po ukončení poskytování služeb spojených se zpracováním, a&nbsp;vymazat existující kopie,
            pokud není zákonný důvod k&nbsp;uložení daných osobních údajů,</p>
          <p className="pl-1">f) poskytnout Správci veškeré informace potřebné k&nbsp;doložení toho, že byly splněny
            povinnosti stanovené v&nbsp;tomto článku, a&nbsp;umožnit audity, včetně inspekcí, prováděné Správcem nebo
            jiným auditorem, kterého Správce pověřil, a&nbsp;k těmto auditům přispět.</p>
          <p>10. Pokud Zpracovatel poruší tuto smlouvu tím, že určí účely a&nbsp;prostředky zpracování, považuje se ve
            vztahu k&nbsp;takovému zpracování za správce.</p>
          <p>11. Zpracovatel odpovídá za své zaměstnance, kteří v&nbsp;rámci plnění stanovených oprávnění
            a&nbsp;povinností přicházejí do styku s&nbsp;osobními údaji u&nbsp;Zpracovatele a&nbsp;jsou povinni
            v&nbsp;souladu s&nbsp;touto smlouvou a&nbsp;GDPR zachovávat mlčenlivost o&nbsp;osobních údajích a&nbsp;o
            bezpečnostních opatřeních, jejichž zveřejnění by ohrozilo zabezpečení osobních údajů. Povinnost mlčenlivosti
            trvá i&nbsp;po skončení zaměstnání nebo příslušných prací. Zpracovatel je povinen dohlížet na plnění
            uvedených povinností ze strany jeho zaměstnanců.</p>
          <p>12. Zpracovatel je povinen včas oznámit Správci všechny problémy, které by mohly bránit řádnému
            a&nbsp;včasnému poskytování předmětu plnění dle této Smlouvy.</p>
          <p>13. Jakmile pomine účel zpracování osobních údajů ve smyslu této Smlouvy o&nbsp;zpracování osobních údajů
            a&nbsp;Smlouvy hlavní, nebude Zpracovatel osobní údaje dále zpracovávat. v&nbsp;tomto případě budou údaje
            nenávratně zlikvidovány dle pokynů Správce a&nbsp;v souladu s&nbsp;GDPR a&nbsp;ostatními relevantními
            předpisy.</p>

        </Container>
      </ContentBox>
    </PageWrap>
  );
}

export default TermsAndConditionsPage;