import React, { useEffect } from "react"
import moment from "moment"
import { DATE_FORMAT_DAY } from "../../utils/utils"
import AlertError from "../Alerts/AlertError"
import { NavLink } from "react-router-dom"
import LINK from "../../utils/LINK"
import { useFilterEventsAll } from "../../hooks/useFilterEventsAll"
import Loading from "../Loading/Loading"
import AlertInfo from "../Alerts/AlertInfo"
import { iEvent } from "../../api/intrefaces"
import TableBox from "../ContentBox/TableBox"

const EventsListAll = () => {
  const { eventsList, error, loading, setInitFetch } = useFilterEventsAll()

  useEffect(() => {
    setInitFetch()
  }, [setInitFetch])

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={error} />
  }
  if (!eventsList.length) {
    return <AlertInfo msg="Žádné události" />
  }

  return (
    <>
      <TableBox>
        <thead>
          <tr>
            <th>Typ události</th>
            <th>Název události</th>
            <th>Zadavatel</th>
            <th>Oddělení</th>
            <th>Název VZ</th>
            <th>Vznik události</th>
            <th>Termín události</th>
          </tr>
        </thead>
        <tbody>
          {eventsList.map((event: iEvent, index: number) => (
            <tr key={index}>
              <td>
                {/*{getOptionLabel(OPTIONS.event_type, event.type)}*/}
                {event.type}
              </td>
              <td>
                <NavLink to={`${LINK.eventDetail}/${event.id_timeline_action}`}>
                  {event.name}
                </NavLink>
              </td>
              <td>{event.company_name}</td>
              <td>{event.department_name}</td>
              <td>
                <NavLink to={LINK.contractDetail + "/" + event.id_contract}>
                  {event.contract_name}
                </NavLink>
              </td>
              <td>{moment(event.date).format(DATE_FORMAT_DAY)}</td>
              <td>{moment(event.term).format(DATE_FORMAT_DAY)}</td>
            </tr>
          ))}
        </tbody>
      </TableBox>
    </>
  )
}

export default EventsListAll
