import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iCompanyEnum } from "../../api/intrefaces";
import { RootState } from "../store";


type iCompanyData = iCompanyEnum[]
const slice = createSlice({
  name: "company",
  initialState: {
    error: "",
    loading: false,
    company: [] as iCompanyData,
  },
  reducers: {
    setLoading(state) {
      state.loading = true
      state.error = "";
    },
    setData(state, action: PayloadAction<iCompanyData>) {
      state.company = action.payload;
      state.loading = false
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload;
    }
  }
});

export const selectLoading = (state: RootState) => state.company.loading
export const selectError = (state: RootState) => state.company.error

export const selectOptions = (state: RootState) => ({
  companyOptions: state.company.company
    .filter(c => c.is_submitter) // jen zadavatel
    .map(c => ({
    value: c.id_company.toString(),
    label: c.name
  }))
    .sort((a, b) => a.label.localeCompare(b.label))
})


export const { setLoading, setData, setError } = slice.actions;
export default slice.reducer;
