import React from "react"

interface iResPerson {
  title: string
  resPersons: string
}

const ResponsiblePerson = ({ title, resPersons }: iResPerson) => {
  if (!resPersons) {
    return null
  }

  const persons = resPersons?.split(",").map((name: string) => {
    const [label, email] = name.split(":")
    return {
      label: label?.trim() ? label.trim() : email?.trim(),
      email: email?.trim(),
    }
  })

  return (
    <div>
      {title && <small style={{ marginRight: 5 }}>{title}:</small>}
      {persons?.map(({ email, label }, index: number) => (
        <span key={index}>
          {label}
          {index < persons.length - 1 ? ", " : " "}
        </span>
      ))}
    </div>
  )
}

export default ResponsiblePerson
