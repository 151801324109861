import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import FilterBoxContractsList from "../../components/Filters/Box/FilterBoxContractsList";
import ContractsList from "../../components/ContractsList/ContractsList";
import Paginator from "../../components/Paginator/Paginator";
import {FilterContextProvider} from "../../context/FilterContext";
import {PAGE_TYPES} from "../../context/enumPageTypes";
import FilterBoxShowAndOrder from "../../components/Filters/Box/FilterBoxShowAndOrder";

const ContractsListPage = () => {

  return (
    <PageWrap
      title="Přehled zakázek"
      breadcrumbs_items={[{ label: "Přehled zakázek" }]}
    >
      <FilterContextProvider name={PAGE_TYPES.contratsList}>
        <FilterBoxContractsList />
        <FilterBoxShowAndOrder/>
        <ContractsList />
        <Paginator />
      </FilterContextProvider>
    </PageWrap>
  );
};

export default ContractsListPage;
