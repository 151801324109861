import React from "react"

interface iBoxLine {
  title: string
  children: React.ReactNode
  small?: boolean
  big?: boolean
}

const BoxLine = ({ title, children, small, big }: iBoxLine) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          width: big ? 220 : small ? 100 : 170,
          textAlign: "right",
          fontWeight: 900,
          marginRight: 10,
          flexShrink: 0,
        }}
      >
        {title}:
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default BoxLine
