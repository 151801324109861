import moment from "moment";
import LINK from "../utils/LINK";
import {iTimeline} from "../api/intrefaces";

export const preparedTimelineEvents = (data: any) => {

  return data.map((item: iTimeline)=>({
    title: moment(item.term).format("DD.MM.YYYY"),
    cardTitle: item.contract_name,
    cardSubtitle: item.company_name,
    cardDetailedText: item.name,
    localeDate: moment(item.date).format("DD.MM.YYYY"),
    url: `${LINK.eventDetail}/${item.id_timeline_action}`
  }))
}