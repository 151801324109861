import React, { ReactNode } from "react";
import Loading from "../Loading/Loading";
import AlertError from "../Alerts/AlertError";

interface iLoadingErrorWrap {
  loading?: boolean,
  error?: string,
  children: ReactNode
}

const LoadingErrorWrap = ({ loading, error, children }: iLoadingErrorWrap) => {

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }
  return <>{children}</>;
}

export default LoadingErrorWrap;