import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import useFetch from "./useFetch";
import {iCriteriaContractsPeriod} from "../api/intrefaces";
import {useFilterContext} from "../context/FilterContext";
import {setTotalCounts} from "../redux/filters/filtersSlice";
// import {selectLimitOffset, selectOrder} from "../redux/filters/filtersSlice";

export const useFilterStats = <T extends any>(fetchStatsFce: (filters: iCriteriaContractsPeriod) => Promise<{ data: T, totalCount: number }>) => {
  const dispatch = useDispatch()
  const {activeFilters, activeFiltersChart, contextName} = useFilterContext()
  // const {limit, offset} = useSelector(selectLimitOffset)
  // const order = useSelector(selectOrder)

  const f = {
    ...activeFilters,
    ...activeFiltersChart,
    // limit: limit,
    // offset: offset,
    // order: order
  }
  const {data: stats, error, loading, fetch} = useFetch<any>(() =>
    fetchStatsFce(f)
        .then(res => {
          if (res.totalCount) {
            dispatch(setTotalCounts({name: contextName || "", value: res.totalCount }))
          }
          return res.data ? res.data : res
        })
  )

  const [init, setInit] = useState(false)
  const setInitFetch = () => setInit(true)

  useEffect(() => {
    if (init) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, init, dispatch, activeFiltersChart])

  return {stats, error, loading, setInitFetch}
}

