import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import FilterBoxContractsList from "../../components/Filters/Box/FilterBoxContractsList"
import Paginator from "../../components/Paginator/Paginator"
import ContractsListRunning from "../../components/ContractsListRunning.tsx/ContractsListRunning"
import {FilterContextProvider} from "../../context/FilterContext"
import {PAGE_TYPES} from "../../context/enumPageTypes";
import FilterBoxShowAndOrder from "../../components/Filters/Box/FilterBoxShowAndOrder";

const ContractsListRunningPage = () => {

  return (
    <PageWrap
      title="Přehled probíhajících zakázek"
      breadcrumbs_items={[{ label: "Přehled probíhajících zakázek" }]}
    >
      <FilterContextProvider name={PAGE_TYPES.home}>
        <FilterBoxContractsList />
        <FilterBoxShowAndOrder/>
        <ContractsListRunning />
        <Paginator />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default ContractsListRunningPage
